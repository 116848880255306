/* eslint-disable prettier/prettier */
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { ToastFullContext } from 'src/component/base-component/base-snackbar';
import { ModalFullContext } from 'src/component/modal/modal-provider';
import { ITableModalType } from 'src/component/table/filterModal/table-filter-modal';
import { BankAccountTransferMethodEnum } from 'src/service/service-bank';
import {
  IAccountingEntries,
  IListAccountingEntriesBalanceResponseDTO,
  IListAccountingEntriesBankBalanceResponseDTO,
  IListAccountingEntriesCashflowResponseDTO,
  IListAccountingEntriesResponseDTO
} from 'src/service/service-company';
import { formatMoney } from 'src/shared/util/formatter/formatter-utils';
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts';
import { ListAccountingEntriesBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-balance-list';
import { ListAccountingEntriesBankBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-bank-balance-list';
import { ListAccountingEntriesCashflowUseCase } from 'src/usecase/classifier/usecase-accounting-entries-cashflow-list';
import { ClassifyAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-classify';
import { AccountingEntryDismemberUseCase } from 'src/usecase/classifier/usecase-accounting-entries-dismember';
import { ListAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-list';
import { UpdateAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-update';
import { ListCompanyVendorsUseCase } from 'src/usecase/classifier/usecase-company-vendors-list';

export type IFilterModalField = 'date' | 'description' | 'client' | 'value' | 'credit'  | 'debit' | 'financialCategory'
export type IFilterModalType = {
  modalType: ITableModalType,
  fieldType: IFilterModalField,
  open: boolean
}

export function useClassifier(
  listAccoutingEntriesUseCase: ListAccountingEntriesUseCase,
  updateAccountingEntriesUseCase: UpdateAccountingEntriesUseCase,
  listCompanyVendorsUseCase: ListCompanyVendorsUseCase,
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase,
  listAccountingEntriesBankBalanceUseCase: ListAccountingEntriesBankBalanceUseCase,
  listAccountingEntriesBalanceUseCase: ListAccountingEntriesBalanceUseCase,
  listAccountingEntriesCashflowUseCase: ListAccountingEntriesCashflowUseCase,
  classifyAccountingEntriesUseCase: ClassifyAccountingEntriesUseCase,
  accountingEntryDismemberUseCase: AccountingEntryDismemberUseCase,
  companyId: string,
) {
  const { ShowToast } = ToastFullContext();
  
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBigNumbersBalance, setIsLoadingBigNumbersBalance] = useState(false);
  const [isLoadingBigNumbersCashflow, setIsLoadingBigNumbersCashflow] = useState(false);
  const [selectedGridIds, setSelectedGridIds] = useState<string[]>([]);
  const [accountingEntries, setAccountingEntries] = useState<
    IAccountingEntries[]
  >([]);
  const [informationsBankBalance, setInformationsBankBalance] = useState<IListAccountingEntriesBankBalanceResponseDTO>()
  const [informationsCashflow, setInformationsCashflow] = useState<IListAccountingEntriesCashflowResponseDTO>()
  const [informationsBalance, setInformationsBalance] = useState<IListAccountingEntriesBalanceResponseDTO>()
  const [vendors, setVendors] = useState<string[]>([]);
  const [finalcialCategories, setFinancialCategories] = useState<string[]>([]);
  const [debits, setDebits] = useState<string[]>([]);
  const [ledgerAccounts, setLedgerAccounts] = useState<string[]>([]);
  const [credits, setCredits] = useState<string[]>([]);
  const [columnsTableSelected, setColumnsTableSelected] = useState<string[]>([
    'date',
    'description',
    'debit',
    'credit',
    'value',
    'historic',
    'status',
    'financialCategory'
  ]);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleSelectColumns = (column: string) => {
    const exists = columnsTableSelected.find(i => i === column);

    if (exists) {
      const filtered = columnsTableSelected.filter(i => i !== column);
      setColumnsTableSelected(filtered);
    } else {
      setColumnsTableSelected(prev => [...prev, column]);
    }
  };

  const handleListAccountingEntriesCashflow =
    async (
      page: number,
      accounts: string[],
      selectedVendors: string[] | undefined,
      initialDate: Date | undefined,
      finalDate: Date | undefined,
      searchQuery: string,
      maxValue: string,
      minValue: string,
      selectedCredits: string[] | undefined,
      selectedDebits: string[]  | undefined,
      selectedCC: string[] | undefined,
      accountingEntriesAlreadyClassified: "true" | "false" | undefined,
      showDespised: "true" | "false" | undefined
    ) => { 
      try {
        setIsLoadingBigNumbersCashflow(true);
        const formatMinValue = minValue ? (Number(minValue?.replace(/[^\d-]/g, '')) / 100).toString() : undefined;
        const formatMaxValue = minValue ? (Number(maxValue?.replace(/[^\d-]/g, '')) / 100).toString() : undefined;
        const usecaseResult = await listAccountingEntriesCashflowUseCase.handle({
          companyId,
          bankAccountId: accounts,
          page,
          vendor: selectedVendors,
          dateStart: initialDate,
          dateEnd: finalDate,
          query: searchQuery,
          credit: selectedCredits,
          debit: selectedDebits,
          accountingAccount: selectedCC,
          valueMaximum: formatMaxValue,
          valueMinimum: formatMinValue,
          accountingEntriesAlreadyClassified,
          showDespised: undefined
        });
        if (usecaseResult.isFailure) {
          setIsLoadingBigNumbersCashflow(false);
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
          );
          return;
        }
  
        const transfersData =
          usecaseResult.getValue();

        if (transfersData) {
          setInformationsCashflow(transfersData)
        }

        setIsLoadingBigNumbersCashflow(false);
      } catch (error) {
        setIsLoadingBigNumbersCashflow(false);
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    };

  const handleListAccountingEntriesBalance =
    async (
      page: number,
      accounts: string[],
      selectedVendors: string[] | undefined,
      initialDate: Date | undefined,
      finalDate: Date | undefined,
      searchQuery: string,
      maxValue: string,
      minValue: string,
      selectedCredits: string[] | undefined,
      selectedDebits: string[]  | undefined,
      selectedCC: string[] | undefined,
      accountingEntriesAlreadyClassified: "true" | "false" | undefined,
      showDespised: "true" | "false" | undefined
    ) => { 
      try {
        const formatMinValue = minValue ? (Number(minValue?.replace(/[^\d-]/g, '')) / 100).toString() : undefined;
        const formatMaxValue = minValue ? (Number(maxValue?.replace(/[^\d-]/g, '')) / 100).toString() : undefined;
        // Chamada à API para obter os dados
        const usecaseResult = await listAccountingEntriesBalanceUseCase.handle({
          companyId,
          bankAccountId: accounts,
          page,
          vendor: selectedVendors,
          dateStart: initialDate,
          dateEnd: finalDate,
          query: searchQuery,
          valueMaximum: formatMaxValue,
          valueMinimum: formatMinValue,
          debit: selectedDebits,
          credit: selectedCredits,
          accountingAccount: selectedCC,
          accountingEntriesAlreadyClassified,
          showDespised: undefined
        });
        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
          );
          return;
        }
  
        const transfersData =
          usecaseResult.getValue();

          if (transfersData) {
          setInformationsBalance(transfersData)
        }
      } catch (error) {
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    };

  const handleListAccountingEntriesBankBalance =
    async (
      page: number,
      accounts: string[],
      selectedVendors: string[] | undefined,
      initialDate: Date | undefined,
      finalDate: Date | undefined,
      searchQuery: string,
      maxValue: string,
      minValue: string,
      selectedCredits: string[] | undefined,
      selectedDebits: string[]  | undefined,
      accountingEntriesAlreadyClassified: "true" | "false" | undefined,
      showDespised: "true" | "false" | undefined
    ) => { 
      try {
        setIsLoadingBigNumbersBalance(true);
        const formatMinValue = minValue ? (Number(minValue?.replace(/[^\d-]/g, '')) / 100).toString() : undefined;
        const formatMaxValue = minValue ? (Number(maxValue?.replace(/[^\d-]/g, '')) / 100).toString() : undefined;
        // Chamada à API para obter os dados
        const usecaseResult = await listAccountingEntriesBankBalanceUseCase.handle({
          companyId,
          bankAccountId: accounts,
          page,
          vendor: selectedVendors,
          dateStart: initialDate,
          dateEnd: finalDate,
          query: searchQuery,
          valueMaximum: formatMaxValue,
          valueMinimum: formatMinValue,
          accountingEntriesAlreadyClassified,
          showDespised: undefined
        });
        if (usecaseResult.isFailure) {
          setIsLoadingBigNumbersBalance(false);
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
          );
          return;
        }
  
        const transfersData =
          usecaseResult.getValue();

        if (transfersData) {
          setInformationsBankBalance(transfersData)
        }

        setIsLoadingBigNumbersBalance(false);
      } catch (error) {
        setIsLoadingBigNumbersBalance(false);
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    };

  const handleListAccountingEntries = 
    async (
      page: number,
      accounts: string[],
      selectedVendors: string[] | undefined,
      initialDate: Date | undefined,
      finalDate: Date | undefined,
      searchQuery: string,
      maxValue: string,
      minValue: string,
      selectedCredits: string[] | undefined,
      selectedDebits: string[]  | undefined,
      selectedCC: string[] | undefined,
      accountingEntriesAlreadyClassified: "true" | "false" | undefined,
      showDespised: "true" | "false" | undefined
    ) => {  
      try {
        if (accounts.length === 0) {
          setAccountingEntries([])
          return
        }
        setIsLoading(true);

        const formatMinValue = minValue ? (Number(minValue?.replace(/[^\d-]/g, '')) / 100).toString() : undefined;
        const formatMaxValue = minValue ? (Number(maxValue?.replace(/[^\d-]/g, '')) / 100).toString() : undefined;
  
        const usecaseResult = await listAccoutingEntriesUseCase.handle({
          companyId,
          bankAccountId: accounts,
          page,
          vendor: selectedVendors,
          dateStart: initialDate,
          dateEnd: finalDate,
          query: searchQuery,
          valueMaximum: formatMaxValue,
          valueMinimum: formatMinValue,
          credit: selectedCredits,
          debit: selectedDebits,
          accountingAccount: selectedCC,
          accountingEntriesAlreadyClassified,
          showDespised,
        });
  
        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.'
          );
          setIsLoading(false);
          return;
        }
  
        const transfersData =
          usecaseResult.getValue() as IListAccountingEntriesResponseDTO;
  
        const normalize: IAccountingEntries[] =
          transfersData?.companyAccountingEntries?.map((entry) => {
            let description = entry?.description ? `${entry?.description}` : ''
            const alreadyHasDescription = description.length > 0
            let partieName = '';
  
            if (
              (entry.bankTransferPartie &&
              (entry.bankTransferPartie.name ||
                entry.bankTransferPartie.document ||
                entry.bankTransferPartie.type)) && !alreadyHasDescription
            ) {
              if (entry.bankTransferPartie.name) {
                description += ` ${entry.bankTransferPartie.name}`;
                partieName = `${entry.bankTransferPartie.name} ${
                  entry.bankTransferPartie.document || ''
                }`;
              }
              if (![null, undefined, ''].includes(entry.bankTransferType)) {
                description += ` ${entry.bankTransferType}`;
              }
              if (entry.bankTransferPartie.type) {
                description += ` ${
                  entry.bankTransferPartie.type === 'PESSOA_NATURAL'
                    ? 'PF'
                    : 'PJ'
                }`;
              }
              if (entry.bankTransferPartie.document) {
                const formattedDocument = entry.bankTransferPartie.document;
                description += ` ${formattedDocument}`;
              }
            } else if (!alreadyHasDescription) {
              if (![null, undefined, ''].includes(entry.bankTransferType)) {
                description += ` ${entry.bankTransferType}`;
              }
              if (!entry.bankTransferType) {
                description += ` ${entry.description || entry.method}`;
              }
            }

            return {
              id: entry.id,
              date: format(new Date(entry.date), 'dd/MM/yyyy'),
              bankTransferDate: format(
                new Date(entry.bankTransferDate),
                'dd/MM/yyyy'
              ),
              description,
              supplier: partieName,
              value: `${entry.method === BankAccountTransferMethodEnum.DEBITO ? '-' : ''} ${formatMoney(Number(entry.value))}`,
              debit: entry.debit,
              credit: entry.credit,
              historic: entry.historic,
              method: entry.method,
              partieDocument: entry?.bankTransferPartie?.document ?? "",
              partieName: entry?.bankTransferPartie?.name ?? "",
              financialCategory: entry.financialCategory,
              status: entry.status,
              conciliatedEntries: entry.conciliatedEntries,
              origin: entry.origin,
              classificationRuleApplied: entry.classificationRuleApplied,
              dismemberOrigin: entry.dismemberOrigin
            };
          }) || [];

        if (normalize.length) {
          if (page === 1) {
            setAccountingEntries(normalize);
          } else {
            setAccountingEntries((prevEntries) => [...prevEntries, ...normalize]);
          }
        } else {
          setAccountingEntries([]);
        }
  
        setTotalPages(transfersData.pages);
        setTotalRegisters(transfersData.count);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    };

  const vendorsOptions = useMemo(() => {
    const payload = [
      { value: 'none', label: 'Sem fornecedor' },
    ];
    if (vendors?.length) {
      const normalize = vendors?.map(vendor => ({
        value: vendor,
        label: vendor,
      }));
      return [...payload, ...normalize];
    } else {
      return payload;
    }
  }, [vendors]);

  const handleListCompanyVendors = async (companyId: string) => {
    try {
      setIsLoading(true);
      const usecaseResult = await listCompanyVendorsUseCase.handle(companyId);
      setIsLoading(false);

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar os dados dos clientes e fornecedores. Tente novamente mais tarde.',
        );
      }

      const value = usecaseResult.getValue();

      console.log(value)

      if (value) {
        setVendors(value.vendors);
      }
    } catch (error) {
      setIsLoading(false);
      ShowToast('error', 'Ocorreu um erro ao buscar o dados dos clientes e fornecedores.');
    }
  };

  const handleGeLedgerAccounts = async () => {
    setIsLoading(true)
    const ledgerAccountResult = await listLedgerAccountsUseCase.handle({
      companyId: companyId as string,
      query: '',
    })

    if (ledgerAccountResult.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar a contas bancária. Tente novamente mais tarde.',
      )
    }

    const ledgerAccountData = ledgerAccountResult.getValue()
    setCredits(ledgerAccountData?.credits ?? [])
    setDebits(ledgerAccountData?.debits ?? [])
    setFinancialCategories(ledgerAccountData?.financialCategories ?? [])
    const combined = [...new Set([...ledgerAccountData?.credits ?? [], ...ledgerAccountData?.debits ?? []])]
    setLedgerAccounts(combined)
    setIsLoading(false)
  }

  const HistoricVariables = [
    {
      label: 'Data',
      value: 'data',
    },
    {
      label: 'Descrição',
      value: 'descricao',
    },
    {
      label: 'Valor',
      value: 'valor',
    },
    {
      label: 'Débito',
      value: 'debito',
    },
    {
      label: 'Crédito',
      value: 'credito',
    },
  ]

  return {
    isLoading,
    isLoadingBigNumbersCashflow,
    isLoadingBigNumbersBalance,
    selectedGridIds,
    setSelectedGridIds,
    setIsLoading,
    handleListAccountingEntries,
    accountingEntries,
    handleListCompanyVendors,
    handleSelectColumns,
    columnsTableSelected,
    totalPages,
    totalRegisters,
    informationsCashflow,
    informationsBalance,
    informationsBankBalance,
    vendorsOptions,
    handleGeLedgerAccounts,
    credits,
    debits,
    ledgerAccounts,
    handleListAccountingEntriesCashflow,
    handleListAccountingEntriesBalance,
    handleListAccountingEntriesBankBalance,
    HistoricVariables,
    finalcialCategories,
    setAccountingEntries,
  };
}
