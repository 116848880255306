import DeleteIcon from '@mui/icons-material/Delete'
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { IAccountingEntries } from 'src/service/service-company'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'

export const DismemberEntryModal: React.FC<{
  data: IAccountingEntries
  handleConfirmAction: (
    data: { value: string; partieName: string; description: string }[],
    entryId: string,
  ) => void
  handleCancelAction: () => void
}> = ({ data, handleConfirmAction, handleCancelAction }) => {
  const isExtraSmallScreen = useMediaQuery('(max-width: 1024px)')
  const { ShowToast } = ToastFullContext()
  const [loading, setLoading] = useState(false)
  const [entries, setEntries] = useState<
    { date: string; value: string; partieName: string; description: string }[]
  >([])
  const [requiredValue, setRequiredValue] = useState<number>(
    Number(data.value?.replace(/[^\d]/g, '')),
  )

  const handleAddRow = () => {
    setEntries([
      ...entries,
      {
        date: data?.date,
        value: '0',
        partieName: '',
        description: '',
      },
    ])
  }

  const handleInputChange = (
    index: number,
    field: string,
    newValue: string | number,
  ) => {
    const updatedEntries = entries.map((entry, i) =>
      i === index ? { ...entry, [field]: newValue } : entry,
    )
    setEntries(updatedEntries)
  }

  const onBlurEvent = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    const totalValue =
      Number(data.value?.replace(/[^\d]/g, '')) -
      entries.reduce(
        (acc, entry) =>
          acc + Number(Number(entry.value?.replace(/[^\d]/g, ''))),
        0,
      )
    setRequiredValue(totalValue)
  }

  const handleRemoveRow = (index: number) => {
    const newEntries = entries.filter((_, i) => i !== index)
    setEntries(newEntries)
    const totalValue =
      Number(data.value?.replace(/[^\d]/g, '')) -
      newEntries.reduce(
        (acc, entry) =>
          acc + Number(Number(entry.value?.replace(/[^\d]/g, ''))),
        0,
      )
    setRequiredValue(totalValue)
  }

  const handleConfirm = async () => {
    const totalValue = entries.reduce(
      (acc, entry) => acc + Number(Number(entry.value?.replace(/[^\d]/g, ''))),
      0,
    )
    if (totalValue !== Number(data.value?.replace(/[^\d]/g, ''))) {
      return ShowToast(
        'error',
        'A soma dos valores não pode ser maior ou menor que o valor original.',
      )
    }
    handleConfirmAction(
      entries.map((item) => {
        return {
          partieName: item?.partieName ?? '',
          description: item?.description ?? '',
          value: (Number(item.value?.replace(/[^\d]/g, '')) / 100).toString(),
        }
      }),
      data?.id as string,
    )
  }

  const handleCancel = () => {
    setLoading(false)
    setEntries([
      {
        date: data?.date,
        value: '',
        partieName: '',
        description: '',
      },
    ])
    handleCancelAction()
  }

  return (
    <Stack
      width="100%"
      height="100%"
      minWidth={isExtraSmallScreen ? '90vw' : '60vw'}
      maxHeight="480px"
      gap="32px"
      overflow="auto"
    >
      {/* Header */}
      <Stack
        direction="row"
        sx={{
          backgroundColor: '#5ED1A2',
          color: 'white',
          padding: '8px',
          height: '40px',
          alignItems: 'center',
          borderRadius: '8px 8px 0 0',
        }}
      >
        <Typography sx={{ width: '15%' }}>Data</Typography>
        <Typography sx={{ width: '15%' }}>Valor</Typography>
        <Typography sx={{ width: '35%' }}>Fornecedor</Typography>
        <Typography sx={{ width: '35%' }}>Descrição</Typography>
      </Stack>

      <Stack
        direction="row"
        sx={{
          padding: '8px',
          borderBottom: '1px solid #ccc',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ width: '15%' }}>{data.date}</Typography>
        <Typography sx={{ width: '15%' }}>{data.value}</Typography>
        <Typography
          sx={{
            width: '35%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data.supplier}
        </Typography>
        <Typography
          sx={{
            width: '35%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data.description}
        </Typography>
      </Stack>

      {/* Data Rows */}
      {entries.map((entry, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          gap="24px"
          sx={{
            padding: '8px',
            borderBottom: '1px solid #ccc',
          }}
        >
          <Typography sx={{ width: '15%' }}>{entry.date}</Typography>
          <Stack width="15%" marginLeft="-24px">
            <BaseInputMoney
              label="Valor"
              fullWidth
              value={entry.value}
              setState={(e) => handleInputChange(index, 'value', e)}
              onBlurEvent={onBlurEvent}
              error={null}
            />
          </Stack>
          <Stack width="35%">
            <BaseInput
              label=""
              fullWidth
              value={entry?.partieName}
              setState={(e) => handleInputChange(index, 'partieName', e)}
              error={null}
            />
          </Stack>
          <Stack width="35%" direction="row" gap="12px">
            <BaseInput
              label=""
              fullWidth
              value={entry.description}
              setState={(e) => handleInputChange(index, 'description', e)}
              error={null}
            />

            <IconButton
              color="primary"
              onClick={() => handleRemoveRow(index)}
              sx={{
                borderRadius: 1,
                width: '40px',
                height: '40px',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
      ))}

      <Stack
        direction="row"
        gap="16px"
        width="100%"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Stack direction="row" gap="16px" alignItems="center">
          <Button variant="contained" onClick={handleAddRow}>
            Novo
          </Button>
          <Typography color="red" fontSize="12px">
            {requiredValue > 0
              ? `Ainda é necessário informar um total de: ${formatMoney(requiredValue / 100)}`
              : ''}
          </Typography>
        </Stack>
        <Stack direction="row" gap="16px">
          <Button variant="outlined" onClick={handleCancel}>
            Cancelar
          </Button>
          {!loading && (
            <Button variant="contained" onClick={handleConfirm}>
              Salvar
            </Button>
          )}
          {loading && <CircularProgress />}
        </Stack>
      </Stack>
    </Stack>
  )
}
