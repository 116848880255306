/* eslint-disable new-cap */
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas'
import OpenI from '../assets/logo/logo-blue-yellow.png'

// Interface para o conteúdo do PDF
interface TableColumn {
  header: string
  dataKey: string
}

interface TableRow {
  [key: string]: string | number
}

interface PDFProps {
  title: string
  subtitle?: string
  columns: TableColumn[]
  data: TableRow[]
  contentDiv?: HTMLElement
}

export const generateCustomPDF = async ({
  title,
  subtitle,
  columns,
  data,
  contentDiv,
}: PDFProps) => {
  const doc = new jsPDF()

  // Logo
  const imgData = OpenI
  const imgProportion = 2.2
  const imgSize = 20
  const imgWidth = imgSize * imgProportion
  const imgHeight = imgSize
  const xPos = 150
  doc.addImage(imgData, 'PNG', xPos, 10, imgWidth, imgHeight)

  // Adicionar Título
  doc.setFontSize(18)
  doc.text(title, 14, 22)

  // Adicionar Subtítulo (opcional)
  let finalY = 30
  if (subtitle) {
    doc.setFontSize(14)
    doc.text(subtitle, 14, 30)
    finalY = 40
  }

  // Adicionar Tabela
  autoTable(doc, {
    startY: finalY,
    head: [columns.map((col) => col.header)],
    body: data.map((row) => columns.map((col) => row[col.dataKey])),
    margin: { top: 40 },
    styles: { fontSize: 10 },
    theme: 'striped',
  })

  // Capturar e adicionar conteúdo de uma div ao PDF
  if (contentDiv) {
    const canvas = await html2canvas(contentDiv)
    const imgData = canvas.toDataURL('image/png')
    const imgWidth = doc.internal.pageSize.getWidth() - 20
    const imgHeight = (canvas.height * imgWidth) / canvas.width

    // Pegar a posição da última linha da tabela
    const tableFinalY = (doc as any).lastAutoTable.finalY || finalY + 10

    doc.addImage(imgData, 'PNG', 10, tableFinalY + 10, imgWidth, imgHeight)
  }

  // Adicionar Rodapé
  const pageCount = doc.getNumberOfPages()
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i)
    doc.text(
      `Página ${i} de ${pageCount}`,
      doc.internal.pageSize.width - 50,
      doc.internal.pageSize.height - 10,
    )
  }

  // Salvar o PDF
  doc.save(`${title}.pdf`)
}
/* eslint-enable new-cap */
