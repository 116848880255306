import { Box, Button, Stack } from '@mui/material'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { BaseInput } from '../base-component/base-input'
import { BaseSelectInput } from '../base-component/base-select-input'
import { ISelectMenuItemProps } from '../base-component/base-select-with-search'

export const FilterModal: React.FC<{
  day: string
  planOptions: ISelectMenuItemProps[]
  selectedPlanOptions: string
  statusOptions: ISelectMenuItemProps[]
  selectedStatus: string
  setFilterStatus: React.Dispatch<React.SetStateAction<string>>
  setFilterPlans: React.Dispatch<React.SetStateAction<string>>
  setFilterDay: React.Dispatch<React.SetStateAction<string>>
  handle: (planId: string, status: string, day: string) => Promise<void>
}> = ({
  day,
  planOptions,
  selectedPlanOptions,
  statusOptions,
  selectedStatus,
  handle,
  setFilterDay,
  setFilterPlans,
  setFilterStatus,
}) => {
  const { HideModal } = ModalFullContext()
  const [internalControlSelectedPlans, setInternalControlSelectedPlans] =
    useState<string>(selectedPlanOptions)
  const [internalControlSelectedStatus, setInternalControlSelectedStatus] =
    useState<string>(selectedStatus)
  const [internalControlSelectedDay, setInternalControlSelectedDay] =
    useState<string>(day)

  const handleApply = () => {
    handle(
      internalControlSelectedPlans,
      internalControlSelectedStatus,
      internalControlSelectedDay,
    )
  }

  const handleClear = () => {
    setInternalControlSelectedDay('')
    setFilterDay('')
    setInternalControlSelectedPlans('')
    setFilterPlans('')
    setInternalControlSelectedStatus('')
    setFilterStatus('')
    handle('', '', '')
  }

  return (
    <Stack
      height="calc(100vh - 48px)"
      width="400px"
      right="0px"
      top="50%"
      position="relative"
      direction="column"
      alignItems="center"
      sx={{ zIndex: 1000 }}
    >
      <Stack
        height="100%"
        width="100%"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="32px"
        padding="24px"
        sx={{ overflowY: 'auto' }}
      >
        <Stack
          display="grid"
          width="100%"
          gridAutoRows="max-content"
          gap="16px"
          paddingTop="12px"
          sx={{ overflow: 'hidden' }}
        >
          <Box width="100%" height="100%">
            <Stack
              width="100%"
              height="100%"
              display="grid"
              direction="row"
              gridAutoRows="max-content"
              gridAutoColumns="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="16px"
            >
              <Box paddingBottom="32px">
                <BaseSelectInput
                  label="Planos"
                  options={planOptions ?? []}
                  value={internalControlSelectedPlans}
                  fullWidth
                  error=""
                  labelBackground="#ffffff"
                  setState={(value) => {
                    setInternalControlSelectedPlans(value)
                    setFilterPlans(value)
                  }}
                />
              </Box>

              <Box paddingBottom="32px">
                <BaseSelectInput
                  label="Status"
                  options={statusOptions ?? []}
                  value={internalControlSelectedStatus}
                  fullWidth
                  error=""
                  labelBackground="#ffffff"
                  setState={(value) => {
                    setInternalControlSelectedStatus(value)
                    setFilterStatus(value)
                  }}
                />
              </Box>

              <Box paddingBottom="32px">
                <Stack
                  width="99%"
                  direction="row"
                  gap="16px"
                  justifyContent="space-between"
                >
                  <BaseInput
                    type="number"
                    fullWidth
                    label="Dia de vencimento"
                    labelBackground="#ffffff"
                    setState={(e) => {
                      setInternalControlSelectedDay(e)
                      setFilterDay(e)
                    }}
                    value={internalControlSelectedDay}
                    error={null}
                  />
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>

        <Stack
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          direction="row"
          gap="16px"
        >
          <Button variant="text" onClick={() => HideModal()}>
            Cancelar
          </Button>

          <Button variant="text" onClick={() => handleClear()}>
            Limpar
          </Button>

          <Button
            variant="contained"
            onClick={() => handleApply()}
            sx={{ width: '140px' }}
          >
            Aplicar filtro
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
