import AddIcon from '@mui/icons-material/Add'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AccountCard } from 'src/component/account/card'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'
import { AccountImportExtractModal } from 'src/component/modal/modal-import-extract'
import { ImportExtractSuccessModal } from 'src/component/modal/modal-import-extract-success'
import { ImportFileModal } from 'src/component/modal/modal-import-file'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { SimpleTextModal } from 'src/component/modal/simple-modal-text'
import { UserFullContext } from 'src/context/context-user'
import { IBankAccount } from 'src/service/service-account'
import { DeleteBankAccountUseCase } from 'src/usecase/bank-account/usecase-account-delete'
import { AccountGetCSVColumnsFileUseCase } from 'src/usecase/bank-account/usecase-account-get-csv-columns'
import { ListAccountsUseCase } from 'src/usecase/bank-account/usecase-account-list'
import { AccountImportOfxFileUseCase } from 'src/usecase/bank-account/usecase-account-upload-file-ofx'
import { extractBankName } from 'src/utils/normalizedBankLogos'
import BlueHeader from '../../assets/background/header-blue.png'
import { useCompanyAccount } from './useCompanyAccount'
import { BaseInput } from 'src/component/base-component/base-input'
import SearchIcon from '@mui/icons-material/Search'

interface CreateAccountPageProps {
  accountUseCase: ListAccountsUseCase
  deleteAccountUseCase: DeleteBankAccountUseCase
  uploadOFXFile: AccountImportOfxFileUseCase
  getCsvFileColumnsUseCase: AccountGetCSVColumnsFileUseCase
}

export function ListSystemicAccountPage(props: CreateAccountPageProps): any {
  const { companyId } = useParams()
  const navigate = useNavigate()
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const { setNewAccount } = useCompanyAccount()
  const { setCurrentCompanyId } = UserFullContext()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [accounts, setAccounts] = useState<IBankAccount[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleListCompanyAccountsUsecase = async () => {
    try {
      setIsLoading(true)
      const accountsResult = await props.accountUseCase.handle({
        companyId: companyId as string,
      })
      setIsLoading(false)

      if (accountsResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar as contas bancárias. Tente novamente mais tarde.',
        )
      }

      const accountsData = accountsResult.getValue() as IBankAccount[]

      setAccounts(accountsData)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as contas bancárias. Tente novamente mais tarde.',
      )
    }
  }

  const handleListCompanyAccounts = async () => {
    try {
      handleListCompanyAccountsUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as contas bancárias. Tente novamente mais tarde.',
      )
    }
  }

  useEffect(() => {
    setCurrentCompanyId(companyId ?? '')
    handleListCompanyAccounts()
  }, [companyId])

  const handleCreate = () => {
    setNewAccount(null)
    navigate(`/company/${companyId}/account/new`)
  }

  const handleDeleteAccount = async (accountId: string) => {
    try {
      setIsLoading(true)
      const accountsResult = await props.deleteAccountUseCase.handle({
        data: {
          companyId: companyId as string,
          accountId,
        },
      })
      setIsLoading(false)

      if (accountsResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao excluír a conta. Tente novamente mais tarde.',
        )
      }

      ShowToast('success', 'Conta excluída com sucesso')
      handleListCompanyAccountsUsecase()
      HideModal()
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao excluír a conta. Tente novamente mais tarde.',
      )
    }
  }

  const handleDeleteAccountModal = (accountId: string) => {
    ShowModal({
      content: (
        <SimpleTextModal
          firstButtonText="Cancelar"
          secondButtonText="Excluir"
          firstButtonAction={() => HideModal()}
          secondButtonAction={() => handleDeleteAccount(accountId)}
          text="Deseja realmente excluír esta conta?"
          height={120}
          width={310}
        />
      ),
      title: 'Excluír conta',
      closeButton: false,
    })
  }

  const handleUploadOFXFile = async (file: File, accountId: string) => {
    try {
      const ofxFile = await props.uploadOFXFile.handle({
        file,
        companyId: companyId as string,
        accountId: accountId as string,
      })

      if (ofxFile.isFailure) {
        HideModal()
        return ShowToast(
          'error',
          'Ocorreu um erro ao processar o arquivo. Tente novamente mais tarde.',
        )
      }

      const ofxResult = ofxFile.getValue()
      HideModal()

      if (ofxResult) {
        ShowModal({
          content: (
            <ImportExtractSuccessModal
              finalBalance={ofxResult.amount}
              totalFailed={ofxResult?.totalFailed}
              totalImported={ofxResult?.totalImported}
              totalRegister={ofxResult?.totalRegister}
            />
          ),
          title: '',
        })
      } else {
        ShowToast(
          'warning',
          'Arquivo importado com sucesso, porém não foi possível recuperar os dados de sucessos / falhas ao importar.',
        )
      }
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao importar o arquivo. Tente novamente mais tarde.',
      )
    }
  }

  const handleUploadConfirmoOFXBalance = async (
    file: File,
    accountId: string,
  ) => {
    handleUploadOFXFile(file, accountId)
  }

  const handleValidateCSVFileColumns = async (
    file: File,
    accountId: string,
  ) => {
    const columnsData = await props.getCsvFileColumnsUseCase.handle({
      file,
      accountId,
      companyId: companyId as string,
    })

    HideModal()
    if (columnsData.isFailure) {
      return ShowToast(
        'error',
        'Ocorreu um erro ao processar o arquivo. Tente novamente mais tarde.',
      )
    }

    const columns = await columnsData.getValue()
    if (columns?.columns.length === 0) {
      return ShowToast(
        'warning',
        'O arquivo selecionado não possui nenhuma coluna válida.',
      )
    }

    return navigate(
      `/company/${companyId}/account/${accountId}/import/extract/csv`,
      {
        state: { fileColumns: columns?.columns, file },
      },
    )
  }

  const handleImportModalHandle = (isCsv: boolean, accountId: string) => {
    HideModal()
    if (isCsv) {
      ShowModal({
        content: (
          <ImportFileModal
            showInstructions={true}
            instructions={[
              '• Datas devem estar no seguinte formato DD/MM/AAAA',
              '• Caso uma conta contábil/bancária não seja encontrada na plataforma, ela não será criada automaticamente',
              '• As células não devem conter fórmulas',
              '• Valores devem respeitar a pontuação correta',
              '• , representa centavos',
              '• Não utilizar . para representar mil',
              '• Colulas * representam obrigatoriedade de preenchimento. Caso não sejam preenchidas a linha inteira será desconsiderada',
              '• CPF/CNPJ não devem conter pontuações nem caracteres especiais',
              '• Em valor, o sinal - [menos] deve representar saídas',
            ]}
            controlRegisterId={accountId}
            handle={handleValidateCSVFileColumns}
            allowedExtensions={['csv']}
            template={{
              url: '/csv-example/import-extract.csv',
              filename: 'import-extract.csv',
            }}
          />
        ),
        title: 'Importar planilha excel',
        closeButton: false,
      })
    } else {
      ShowModal({
        content: (
          <ImportFileModal
            showInstructions={false}
            controlRegisterId={accountId}
            handle={handleUploadConfirmoOFXBalance}
            allowedExtensions={['ofx']}
          />
        ),
        title: 'Importar OFX',
        closeButton: false,
      })
    }
  }

  const handleOpenInportExtractModal = (accountId: string) => {
    ShowModal({
      content: (
        <AccountImportExtractModal
          accountId={accountId}
          handle={handleImportModalHandle}
        />
      ),
      title: 'Importar extrato',
      closeButton: false,
    })
  }

  const handleEditButtonAction = (accountId: string) => {
    navigate(`/company/${companyId}/account/edit/${accountId}`)
  }

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          { title: 'Home', navigateTo: `/company/${companyId}` },
          { title: 'Contas sistêmicas' },
        ]}
      />

      <Stack
        width="100%"
        height="calc(100% - 130px)"
        direction="row"
        gap="32px"
        overflow="hidden"
      >
        <Stack
          width="100%"
          height="calc(100% - 32px)"
          padding="32px"
          gap="48px"
        >
          <Stack
            width="100%"
            height="40px"
            justifyContent="space-between"
            direction="row"
          >
            <BasePageTitle color="#1E3466" text="Contas sistêmicas" />
            <Stack gap="24px" direction="row" alignItems="center">
              <Box width="280px">
                <BaseInput
                  fullWidth
                  labelBackground="#F4F8FA"
                  type="text"
                  label="Buscar"
                  setState={(event) => {
                    if (!event) {
                      return setSearchQuery && setSearchQuery('')
                    }

                    setSearchQuery && setSearchQuery(event)
                  }}
                  value={searchQuery ?? ''}
                  error={null}
                  iconStart={<SearchIcon />}
                />
              </Box>
              <Button
                startIcon={<AddIcon />}
                fullWidth={false}
                variant="contained"
                onClick={handleCreate}
                sx={{
                  padding: '12px 24px 12px 16px',
                  gap: '8px',
                  width: '220px',
                  height: '48px',
                }}
              >
                Criar nova conta
              </Button>
            </Stack>
          </Stack>
          <Stack
            height="calc(100% - 40px - 32px)"
            width="100%"
            gap="24px"
            flexWrap="wrap"
            direction="row"
            sx={{ overflowX: 'auto' }}
          >
            {isLoading && <CircularProgress />}
            {!isLoading && accounts.length === 0 && (
              <Stack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  maxWidth="344px"
                  fontWeight="600"
                  fontSize="16px"
                  color="#000000"
                  textAlign="center"
                >
                  Você ainda não possui contas cadastradas. Clique em Criar nova
                  conta para começar.
                </Typography>
              </Stack>
            )}

            {!isLoading &&
              accounts.length > 0 &&
              accounts
                ?.filter((f) =>
                  searchQuery
                    ? f.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      f.bank?.toLowerCase().includes(searchQuery.toLowerCase())
                    : f.name,
                )
                .map((account) => {
                  return (
                    <AccountCard
                      isOpenFinance={account.openFinance}
                      accountType={account.accountType}
                      id={account.id}
                      personType={account.personType}
                      title={account.name}
                      key={account.id}
                      bankLogo={account.bankLogo}
                      bankName={extractBankName(account.bank)}
                      deleteButtonAction={handleDeleteAccountModal}
                      importButtonAction={handleOpenInportExtractModal}
                      editButtonAction={handleEditButtonAction}
                    />
                  )
                })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
