import { Box, Button, Stack, Typography } from '@mui/material'
import { ModalFullContext } from 'src/component/modal/modal-provider'

export const ModalClassifierPendency: React.FC<{
  handle: () => Promise<void>
}> = ({ handle }) => {
  const { HideModal } = ModalFullContext()

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
      }}
    >
      <Box
        sx={{
          width: '450px',
          height: '100px',
          overflow: 'auto',
          borderRadius: '16px',
        }}
      >
        <Typography color="#777C84">
          Deseja seguir com a exportação de acordo com os filtros atuais?
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gap: '14px',
          justifySelf: 'end',
          padding: '0 4px',
        }}
      >
        <Button
          variant="outlined"
          onClick={HideModal}
          sx={{ width: '104px', height: '40px' }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            HideModal()
            handle()
          }}
          sx={{ width: '108px', height: '40px' }}
        >
          Exportar
        </Button>
      </Box>
    </Box>
  )
}
