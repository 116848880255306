import { AxiosInstance } from 'axios'
import { Result } from 'src/shared/protocol/protoco-result'
import { IBankAccount } from './service-account'
import { BankAccountTransferMethodEnum } from './service-bank'
import { TUserOwnInfoResponse } from './service-user'

export type ICompanyPlan = {
  id: string
  name: string
  value: string
  createdAt: Date
  updatedAt: Date
}
export interface ISubscriptionPlan {
  id: string
  // Relational data
  plan: ICompanyPlan
  // Metadata
  value: string
  licenses: number
  licensesInUse: number
  createdAt: Date
  updatedAt: Date
}

export type TCompanyCreateDTO = {
  name: string
  cnpj: string
  subscriptionId?: string
  companyPlanId?: string
  plan?: ISubscriptionPlan
}

export type TCompanyUpdateDTO = TCompanyCreateDTO & {
  id: string
}

export enum CompanyUserPermissionTypeEnum {
  MASTER = 'MASTER',
  ANALISTA = 'ANALISTA',
}
export class CompanyUserPermissionTypeEnumUtil {
  public static VALUES = [
    CompanyUserPermissionTypeEnum.MASTER,
    CompanyUserPermissionTypeEnum.ANALISTA,
  ]

  public static NAMES = ['Master', 'Analista']

  public static toString(value: CompanyUserPermissionTypeEnum): string {
    return this.NAMES[this.VALUES.findIndex((v) => v === value)]
  }
}

export type TCompanyUserCreateDTO = {
  companyId: string
  users: {
    userId: string
    permission: CompanyUserPermissionTypeEnum
  }[]
  doNotDeleteCurrentUsers: boolean
}

export type TCompaniesManyUserCreateDTO = {
  companies: {
    companyId: string
  }[]
  users: {
    userId: string
  }[]
  permission: CompanyUserPermissionTypeEnum
}

export type TCompanyClassificationRulesCreateDTO = {
  companyId: string // Empresa de origem
  companies: {
    id: string
  }[]
  classificationRules: {
    id: string
  }[]
}

export type TCompanyCreateMassDTO = {
  companies: TCompanyCreateDTO[]
}

export type ICompanyUser = {
  id: string
  user: {
    id: string
    name: string
    cpfCnpj: string
    username: string
  }
  permission: CompanyUserPermissionTypeEnum
}

export enum CompanyClassificationRuleConditionalsConditionEnum {
  CONTAINS = 'Contains',
  BETWEEN = 'Between',
  GREATER_THAN = 'GreaterThan',
  LESS_THAN = 'LessThan',
  EQUAL = 'Equal',
  NO_CONTAIN = 'NoContain',
}

export class CompanyClassificationRuleConditionalsConditionEnumUtil {
  public static VALUES = [
    CompanyClassificationRuleConditionalsConditionEnum.CONTAINS,
    CompanyClassificationRuleConditionalsConditionEnum.BETWEEN,
    CompanyClassificationRuleConditionalsConditionEnum.GREATER_THAN,
    CompanyClassificationRuleConditionalsConditionEnum.LESS_THAN,
    CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
    CompanyClassificationRuleConditionalsConditionEnum.NO_CONTAIN,
  ]

  public static NAMES = [
    'Contém',
    'Entre',
    'Maior que',
    'Menor que',
    'Igual',
    'Não contém',
  ]

  public static toString(
    value: CompanyClassificationRuleConditionalsConditionEnumUtil,
  ): string {
    return this.NAMES[this.VALUES.findIndex((v) => v === value)]
  }

  public static parse(
    value: string,
  ): CompanyClassificationRuleConditionalsConditionEnumUtil {
    return this.VALUES[this.NAMES.findIndex((v) => v === value)]
  }

  public static getOptions(): Array<{ id: string; title: string }> {
    return this.VALUES.map((value, index) => ({
      id: value.toString(),
      title: this.NAMES[index],
    }))
  }
}

export enum CompanyClassificationRuleConditionalsVariableEnum {
  VALUE = 'Value',
  DATE = 'Date',
  THIRD_PARTY = 'ThirdParty',
  DESCRIPTION = 'Description',
}

export type ICompanyAccountingChartItem = {
  id: string
  code: string
  createdAt: Date
  updatedAt: Date
}

export enum ISubscriptionStatusEnum {
  PAYMENT_PENDING,
  ACTIVE,
  INACTIVE,
  PAYMENT_MISSING,
}

export interface ISubscription {
  id: string
  // Relational data
  user: {
    id: string
    name: string
  } // Usuário pagador
  plans: ISubscriptionPlan[]
  // Metadata
  email: string
  pagarmeClientId?: string | null
  status: ISubscriptionStatusEnum
  startOfBillingPeriod: Date
  createdAt: Date
  updatedAt: Date
}

export type ICompanyClassificationRuleConditionals = {
  condition: CompanyClassificationRuleConditionalsConditionEnum
  variable: CompanyClassificationRuleConditionalsVariableEnum
  data: {
    value?: {
      value: string
    }
    date?: {
      init: string
      end: string
    }
    thirdParty?: {
      name?: string
      documentNumber?: string
    }
    description?: {
      description: string
    }
  }
}

export type ICompanyClassificationRule = {
  id: string
  accountingChartItem: ICompanyAccountingChartItem
  name: string
  conditionals: ICompanyClassificationRuleConditionals[]
  credit: string | undefined
  debit: string | undefined
  historic: string | undefined
  financialCategory: string | undefined
  generalRule: boolean
  createdAt?: Date
  updatedAt?: Date
}

export enum ICompanyStatusEnum {
  ACTIVE,
  INACTIVE,
  DELETED,
}

export type ICompany = {
  id: string
  name: string
  cnpj: string
  plan?: {
    id: string
    name: string
  }
  subscription?: {
    id: string
    info?: {
      invoiceDay: number
    }
  }
  owner: TUserOwnInfoResponse['user']
  bankAccounts: IBankAccount[]
  status: ICompanyStatusEnum
  users: ICompanyUser[]
  createdAt: Date
  updatedAt: Date
}

export type TReplyCompanyClassificationRuleResponse = {
  rule: ICompanyClassificationRule
  status: 'CREATED' | 'UPDATED'
  company: ICompany
}

export type TCompanyInfoResponse = {
  companies: ICompany
  classificationRules: ICompanyClassificationRule
}

export type TCompanyBigNumbersResponse = {
  totalLicenses: number
  totalLicensesInUse: number
  totalClassified: number
  totalTimeInHours: string
  since: string
}

export interface IListCompanyFilterDTO {
  planIds?: string[]
  status?: string[]
  validateAtStartPeriod?: string
  validateAtEndPeriod?: string
}

export type TAccountClassificationRuleCreateDTO = {
  accountingChartItem: {
    code: string
  }
  name: string
  conditionals: {
    condition: CompanyClassificationRuleConditionalsConditionEnum
    variable: CompanyClassificationRuleConditionalsVariableEnum
    data: {
      value?: {
        value: string
      }
      date?: {
        init: string | number
        end: string | number
      }
      thirdParty?: {
        name?: string
        documentNumber?: string
      }
      description?: {
        description: string
      }
    }
  }[]
  credit: string | undefined
  debit: string | undefined
  historic: string[] | undefined
  financialCategory: string | undefined
  generalRule: boolean
}

export type TAccountClassificationRuleUpdateDTO = {
  id: string
} & TAccountClassificationRuleCreateDTO

export type IListAccountingEntriesInformations = {
  bankBalance: string
  accountingBalance: string
  bankCashflow: {
    total: string
    inflow: string
    outflow: string
  }
}

export enum CompanyAccountEntryConciliationStatusEnum {
  DESPISED = 'DESPISED',
  CONCILIATED = 'CONCILIATED',
  CONCILIATED_PARTIAL = 'CONCILIATED_PARTIAL',
  NOT_CONCILIATED = 'NOT_CONCILIATED',
}

export enum CompanyAccountEntryOriginEnum {
  TRANSFER = 'TRANSFER',
  MANUAL = 'MANUAL',
}
export interface ICompanyAccouyntEntryConciliatedItems {
  value: string
  entryId: string
}

export type ICompanyAccountEntriesResponse = {
  id: string
  company: Omit<ICompany, 'owner' | 'bankAccounts' | 'users'>
  bankAccount: Omit<IBankAccount, 'balanceHistory' | 'company' | 'balance'>
  date: string
  description: string | null
  vendor: string | null
  value: string
  method:
    | BankAccountTransferMethodEnum.CREDITO
    | BankAccountTransferMethodEnum.DEBITO
  debit: string | null
  credit: string | null
  historic: string | null
  financialCategory: string | null
  classificationRuleApplied: null
  classificationRules: any[]
  accountingChartItems: any[]
  bankTransferExternalId: string
  bankTransferStatus: string
  bankTransferType: null
  bankTransferMethod:
    | BankAccountTransferMethodEnum.CREDITO
    | BankAccountTransferMethodEnum.DEBITO
  bankTransferAmount: string
  bankTransferCurrency: 'BRL'
  bankTransferDate: string
  bankTransferPartie: {
    name: string
    type: string | null
    document: string | null
  }
  bankTransferCreatedAt: string
  bankTransferUpdatedAt: string
  bankAccountName: string
  bankAccountDescription: string
  bankAccountBank: string
  bankAccountBankNumber: string
  bankAccountAccountType: number
  bankAccountPersonType: number
  bankAccountTransactionCommencementDate: string
  bankAccountAgencyNumber: string
  bankAccountAccountNumber: string
  bankAccountOpenFinance: boolean
  bankAccountConsentedAccountId: string | null
  bankAccountCreatedAt: string
  bankAccountUpdatedAt: string
  status: CompanyAccountEntryConciliationStatusEnum
  origin: CompanyAccountEntryOriginEnum
  conciliatedEntries: ICompanyAccouyntEntryConciliatedItems[]
  createdAt: string
  updatedAt: string
  dismemberOrigin?: string | null
}

export type IListAccountingEntriesResponseDTO = {
  companyAccountingEntries: ICompanyAccountEntriesResponse[]
  count: number
  pages: number
}

export type ILIstAccountingEntriesImportFileDTO = {
  name: string
  cnpj: string
  description: string
  historic: string
  value: string
  date: string
  debit: string
  credit: string
  financialCategory: string
  bankAccountId: string
  bankAccountName: string
}

export type IListAccountingEntriesCashflowResponseDTO = {
  bankCashflow: {
    total: string
    inflow: string
    outflow: string
    balance?: string
  }
}

export type IListAccountingEntriesBalanceResponseDTO = {
  accountingBalance: string
}

export type IListAccountingEntriesBankBalanceResponseDTO = {
  bankBalance: string
}

export type IListAccountingEntriesConciliatorResponseDTO = {
  debits: ICompanyAccountEntriesResponse[]
  credits: ICompanyAccountEntriesResponse[]
  total: number
  count: number
  pages: number
}

export type IAccountingEntriesRequest = {
  companyId: string
  bankAccountId: string[]
  page: number
  vendor?: string[]
  dateStart?: Date
  dateEnd?: Date
  query?: string
  valueMinimum?: string
  valueMaximum?: string
  debit?: string[]
  credit?: string[]
  accountingAccount?: string[] | undefined
  accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined
  showDespised?: 'true' | 'false' | undefined
}

export type IAccountingEntriesConciliatorRequest = {
  companyId: string
  ledgerAccount: string[]
  dateStart?: Date
  dateEnd?: Date
  query?: string
  status: CompanyAccountEntryConciliationStatusEnum[]
}

export type IAccountingEntries = {
  id: string
  date: string
  bankTransferDate: string
  description: string | null
  supplier: string | null
  value: string | null
  debit: string | null
  credit: string | null
  historic: string | null
  financialCategory: string | null
  method?: BankAccountTransferMethodEnum
  partieName?: string
  partieDocument?: string
  status: CompanyAccountEntryConciliationStatusEnum
  conciliatedEntries: ICompanyAccouyntEntryConciliatedItems[]
  origin: CompanyAccountEntryOriginEnum
  classificationRuleApplied: Date | null
  dismemberOrigin?: string | null
}

export type CompanyDebitRequest = {
  companyId: string
  query: string
}

export type TClassificationRulesExecuteDTO = {
  companyId: string
  bankAccountId?: string
  entryId?: string | string[]
  reclassify?: boolean
}

export type TConciliatorExecuteDTO = {
  companyId: string
  entryId?: string | string[]
  ledgerAccount: string[]
}

export type TCompanyMasCreateResponse = {
  createdCompanies: ICompany[]
  alreadyExistCompanies: { name: string; cnpj: string }[]
  companiesWithError: { name: string; cnpj: string }[]
}

export type TCompanyAccountEntryCreate = {
  id?: string
  date: string
  value: string
  method: string
  credit?: string
  debit?: string
  description?: string
  financialCategory?: string
  historic?: string
  vendor?: string
  bankAccountFromId?: string
  bankAccountToId?: string
  origin?: CompanyAccountEntryOriginEnum
}

export class CompanyApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public create = async (data: TCompanyCreateDTO): Promise<Result<void>> => {
    try {
      await this.service.post(`/v1/company`, data)
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public update = async (
    data: TCompanyUpdateDTO,
  ): Promise<Result<TCompanyInfoResponse['companies']>> => {
    try {
      const result = await this.service.put(`/v1/company/${data.id}`, {
        name: data.name,
        cnpj: data.cnpj,
      })
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public createClassificationRule = async (
    companyId: string,
    data: TAccountClassificationRuleCreateDTO,
  ): Promise<Result<void>> => {
    try {
      const formattedData = data

      let historic
      if (data.historic && data.historic?.length > 0) {
        historic = data.historic.join('|')
      }
      await this.service.post(`/v1/company/${companyId}/classification-rule`, {
        accountingChartItem: formattedData.accountingChartItem,
        classificationRule: {
          name: formattedData.name,
          generalRule: formattedData.generalRule,
          conditionals: formattedData.conditionals,
          credit:
            typeof formattedData.credit === 'string' &&
            formattedData?.credit?.trim()?.length === 0
              ? undefined
              : formattedData.credit,
          debit:
            typeof formattedData.debit === 'string' &&
            formattedData?.debit?.trim()?.length === 0
              ? undefined
              : formattedData.debit,
          historic,
          financialCategory:
            typeof formattedData.financialCategory === 'string' &&
            formattedData?.financialCategory?.trim()?.length === 0
              ? undefined
              : formattedData.financialCategory,
        },
      })
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public updateClassificationRule = async (
    companyId: string,
    data: TAccountClassificationRuleUpdateDTO,
  ): Promise<Result<ICompanyClassificationRule>> => {
    try {
      const formattedData = data
      let historic = ''
      if (data.historic && data.historic?.length > 0) {
        historic = data.historic.join('|')
      }
      const result = (await this.service.put(
        `/v1/company/${companyId}/classification-rule/${data.id}`,
        {
          accountingChartItem: formattedData.accountingChartItem,
          classificationRule: {
            name: formattedData.name,
            generalRule: formattedData.generalRule,
            conditionals: formattedData.conditionals,
            credit:
              typeof formattedData.credit === 'string' &&
              formattedData?.credit?.trim()?.length === 0
                ? undefined
                : formattedData.credit,
            debit:
              typeof formattedData.debit === 'string' &&
              formattedData?.debit?.trim()?.length === 0
                ? undefined
                : formattedData.debit,
            historic,
            financialCategory:
              typeof formattedData.financialCategory === 'string' &&
              formattedData?.financialCategory?.trim()?.length === 0
                ? undefined
                : formattedData.financialCategory,
          },
        },
      )) as ICompanyClassificationRule
      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public deleteClassificationRule = async ({
    companyId,
    classificationRuleId,
  }: {
    companyId: string
    classificationRuleId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.delete(
        `/v1/company/${companyId}/classification-rule/${classificationRuleId}`,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public executeClassificationRules = async (
    data: TClassificationRulesExecuteDTO,
  ): Promise<Result<void>> => {
    try {
      await this.service.post(
        `/v1/company/${data.companyId}/classification-rule/execute`,
        data,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public executeConciliation = async (
    data: TConciliatorExecuteDTO,
  ): Promise<Result<void>> => {
    try {
      await this.service.post(
        `/v1/company/${data.companyId}/conciliation/execute`,
        data,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public createUser = async (
    data: TCompanyUserCreateDTO,
  ): Promise<Result<void>> => {
    try {
      await this.service.post(
        `/v1/company/${data.companyId}/permissions/allowed`,
        data,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public transferCompanyOwner = async (data: {
    companyId: string
    userId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.post(
        `/v1/company/${data.companyId}/transfer-owner`,
        data,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public createManyUsers = async (
    data: TCompaniesManyUserCreateDTO,
  ): Promise<Result<void>> => {
    try {
      await this.service.post(`/v1/company/companies/permissions/allowed`, data)
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public companyLeave = async ({
    companyId,
  }: {
    companyId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.delete(`/v1/company/${companyId}/permissions/leave`)
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public editCompanySubscriptionPlan = async ({
    companyId,
    subscriptionId,
    planId,
  }: {
    companyId: string
    subscriptionId: string
    planId: string
  }): Promise<Result<void>> => {
    try {
      const result = await this.service.put(`/v1/company/${companyId}`, {
        subscriptionId,
        companyPlanId: planId,
      })
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public pauseCompanySubscriptionPlan = async ({
    companyId,
  }: {
    companyId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.put(`/v1/company/${companyId}/pause`)
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public createMass = async (
    data: TCompanyCreateMassDTO,
  ): Promise<Result<TCompanyMasCreateResponse>> => {
    try {
      const result = await this.service.post(`/v1/company/mass-import`, data)
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public uploadFile = async (
    file: File,
  ): Promise<Result<TCompanyCreateDTO[]>> => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await this.service.postForm(
        '/v1/company/validate-import-file',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return Result.ok(response.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public uploadAccountingEntriesFile = async (
    file: File,
  ): Promise<Result<ILIstAccountingEntriesImportFileDTO[]>> => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await this.service.postForm(
        `/v1/company/accounting-entries/import-file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return Result.ok(response.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getUserCompanies = async ({
    search,
    paymentDay,
    planId,
    status,
  }: {
    search?: string
    planId?: string
    paymentDay?: string
    status?: string
  }): Promise<Result<TCompanyInfoResponse['companies'][]>> => {
    try {
      const result = await this.service.get(`/v1/company`, {
        params: { search, planId: [planId], status, paymentDay },
      })
      return Result.ok(result.data.companies)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompaniesBigNumbers = async ({
    companyIds,
    accountIds,
  }: {
    companyIds?: string[]
    accountIds?: string[]
  }): Promise<Result<TCompanyBigNumbersResponse>> => {
    try {
      const result = await this.service.get(`/v1/company/numbers`, {
        params: {
          companyIds: companyIds ? [...companyIds] : undefined,
          accountIds: accountIds ? [...accountIds] : undefined,
        },
      })
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyInfo = async ({
    companyId,
  }: {
    companyId: string
  }): Promise<Result<ICompany>> => {
    try {
      const result = await this.service.get(`/v1/company/${companyId}`)
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyClassificationRules = async (
    companyId: string,
    query?: string,
  ): Promise<Result<TCompanyInfoResponse['classificationRules'][]>> => {
    try {
      const queryString = query ? `?query=${encodeURIComponent(query)}` : ''
      const result = await this.service.get(
        `/v1/company/${companyId}/classification-rule${queryString}`,
      )
      return Result.ok(result.data.classificationRules)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public replyClassificationRules = async (
    data: TCompanyClassificationRulesCreateDTO,
  ): Promise<Result<TReplyCompanyClassificationRuleResponse[]>> => {
    try {
      const result = await this.service.post(
        `/v1/company/${data.companyId}/classification-rule/reply`,
        {
          companies: data.companies,
          classificationRules: data.classificationRules,
        },
      )
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getAccountingEntries = async (
    companyId: string,
    bankAccountId: string[],
    page: number,
    vendor?: string[],
    dateStart?: Date,
    dateEnd?: Date,
    query?: string,
    valueMinimum?: string,
    valueMaximum?: string,
    credit?: string[],
    debit?: string[],
    accountingAccount?: string[] | undefined,
    accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined,
    showDespised?: 'true' | 'false' | undefined,
  ): Promise<Result<IListAccountingEntriesResponseDTO>> => {
    try {
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries`,
        {
          params: {
            query,
            credit,
            debit,
            accountingAccount,
            valueMaximum,
            valueMinimum,
            bankAccountId,
            vendor,
            accountingEntriesAlreadyClassified,
            dateStart,
            dateEnd,
            page,
            showDespised,
          },
        },
      )

      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getAccountingEntriesBankBalance = async (
    companyId: string,
    bankAccountId: string[],
    page: number,
    vendor?: string[],
    dateStart?: Date,
    dateEnd?: Date,
    query?: string,
    valueMinimum?: string,
    valueMaximum?: string,
    accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined,
    showDespised?: 'true' | 'false' | undefined,
  ): Promise<Result<IListAccountingEntriesBankBalanceResponseDTO>> => {
    try {
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries-bank-balance`,
        {
          params: {
            query,
            valueMaximum,
            valueMinimum,
            bankAccountId,
            vendor,
            accountingEntriesAlreadyClassified,
            dateStart,
            dateEnd,
            page,
            showDespised,
          },
        },
      )

      return Result.ok(result.data?.informations)
    } catch (error: any) {
      return Result.fail(error?.response?.data?.informations ?? error)
    }
  }

  public getAccountingEntriesBalance = async (
    companyId: string,
    bankAccountId: string[],
    page: number,
    vendor?: string[],
    dateStart?: Date,
    dateEnd?: Date,
    query?: string,
    valueMinimum?: string,
    valueMaximum?: string,
    credit?: string[],
    debit?: string[],
    accountingAccount?: string[] | undefined,
    accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined,
    showDespised?: 'true' | 'false' | undefined,
  ): Promise<Result<IListAccountingEntriesBalanceResponseDTO>> => {
    try {
      console.log('balance', credit, debit, accountingAccount)

      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries-balance`,
        {
          params: {
            query,
            credit,
            debit,
            accountingAccount,
            valueMaximum,
            valueMinimum,
            bankAccountId,
            vendor,
            accountingEntriesAlreadyClassified,
            dateStart,
            dateEnd,
            page,
            showDespised,
          },
        },
      )

      return Result.ok(result.data?.informations)
    } catch (error: any) {
      return Result.fail(error?.response?.data?.informations ?? error)
    }
  }

  public getAccountingEntriesCashflow = async (
    companyId: string,
    bankAccountId: string[],
    page: number,
    vendor?: string[],
    dateStart?: Date,
    dateEnd?: Date,
    query?: string,
    valueMinimum?: string,
    valueMaximum?: string,
    credit?: string[],
    debit?: string[],
    accountingAccount?: string[] | undefined,
    accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined,
    showDespised?: 'true' | 'false' | undefined,
  ): Promise<Result<IListAccountingEntriesCashflowResponseDTO>> => {
    try {
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries-cashflow`,
        {
          params: {
            query,
            valueMaximum,
            valueMinimum,
            bankAccountId,
            vendor,
            accountingEntriesAlreadyClassified,
            dateStart,
            dateEnd,
            credit,
            debit,
            accountingAccount,
            page,
            showDespised,
          },
        },
      )

      return Result.ok(result.data?.informations)
    } catch (error: any) {
      return Result.fail(error?.response?.data?.informations ?? error)
    }
  }

  public getAccountingEntriesConciliator = async (
    companyId: string,
    status: CompanyAccountEntryConciliationStatusEnum[],
    ledgerAccount: string[],
    dateStart?: Date,
    dateEnd?: Date,
    query?: string,
  ): Promise<Result<IListAccountingEntriesConciliatorResponseDTO>> => {
    try {
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries/conciliator`,
        {
          params: {
            ledgerAccount,
            query,
            dateStart,
            dateEnd,
            status,
          },
        },
      )

      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public createAccountingEntries = async ({
    data,
    companyId,
  }: {
    data: TCompanyAccountEntryCreate[]
    companyId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.post(`/v1/company/${companyId}/manual-account-entry`, {
        data,
      })
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public accountingEntryDismeber = async ({
    data,
    companyId,
    entryId,
  }: {
    data: {
      value: string
      partieName: string
      description: string
    }[]
    companyId: string
    entryId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.post(
        `/v1/company/${companyId}/${entryId}/accounting-entry/dismember`,
        {
          data,
        },
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public updateAccountingEntries = async ({
    companyId,
    entryId,
    field,
    value,
    multipleFields,
  }: {
    companyId: string
    entryId: string
    field?: string
    value?: string
    multipleFields?: { field: string; value: string }[]
  }): Promise<Result<void>> => {
    console.log(companyId, entryId, field, value, multipleFields)
    try {
      if (multipleFields && multipleFields?.length > 0) {
        const toUpdate = multipleFields.reduce(
          (acc: { [key: string]: string }, item) => {
            acc[item.field] = item.value
            return acc
          },
          {},
        )
        await this.service.put(
          `/v1/company/${companyId}/accounting-entries/${entryId}`,
          toUpdate,
        )
        return Result.ok()
      }

      if (!field) {
        return Result.ok()
      }
      const toUpdate = {
        [field]: value,
      }
      const result = await this.service.put(
        `/v1/company/${companyId}/accounting-entries/${entryId}`,
        toUpdate,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyVendors = async (
    companyId: string,
  ): Promise<Result<{ vendors: string[]; financialCategories: string[] }>> => {
    try {
      // const queryString = query ? `?query=${encodeURIComponent(query)}` : '';
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries/vendors`,
      )
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyDebit = async (
    companyId: string,
    query: string,
  ): Promise<Result<string[]>> => {
    try {
      // const queryString = query ? `?query=${encodeURIComponent(query)}` : '';
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries/debits`,
        {
          params: {
            query,
          },
        },
      )
      return Result.ok(result.data.debits)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyPlans = async (): Promise<Result<ICompanyPlan[]>> => {
    try {
      const result = await this.service.get(`/v1/plans`)
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getUserSubscriptions = async (): Promise<Result<ISubscription>> => {
    try {
      const result = await this.service.get(`/v1/subscriptions/`)
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }
}
