import { useState } from 'react'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { IVariationData } from 'src/component/charts/variations'
import {
  ICashFlow,
  ICashFlowConsolidate,
  ICashFlowResponse,
} from 'src/service/service-bank'
import { getMonthName } from 'src/shared/util/formatter/formatter-utils'
import { ListCashFlowUseCase } from 'src/usecase/cashflow/list-cashflow'

export function useCashFlow(listCashFlowUseCase: ListCashFlowUseCase) {
  const { ShowToast } = ToastFullContext()
  const [isLoading, setIsLoading] = useState(false)

  const [cashflow, setCashFlow] = useState<ICashFlow[]>([])
  const [cashflowConsolidate, setCashflowConsolidate] =
    useState<ICashFlowConsolidate>({} as ICashFlowConsolidate)
  const [variationData, setVariationData] = useState<
    {
      name: string
      data: IVariationData[]
    }[]
  >([])
  const [minVariation, setMinVariation] = useState(0)
  const [maxVariation, setMaxVariation] = useState(0)
  const [categoriesVariations, setCategoriesVariations] = useState<string[]>([])

  const handleListCashFlowUsecase = async ({
    companiesIds,
    accountIds,
    date,
    accountRequired,
  }: {
    date?: string[]
    accountIds?: string[]
    companiesIds?: string[]
    accountRequired: boolean
  }) => {
    try {
      setIsLoading(true)

      if (accountRequired && accountIds?.length === 0) {
        setCashFlow([])
        setMinVariation(0)
        setMaxVariation(0)
        setCategoriesVariations([])
        return
      }

      const usecaseResult = await listCashFlowUseCase.handle({
        companiesIds,
        accountIds,
        date,
      })
      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro buscar o fluxo de caixa. Tente novamente mais tarde.',
        )
      }

      const transfersData = usecaseResult.getValue() as ICashFlowResponse

      setCashFlow(transfersData.cashflows)
      setCashflowConsolidate(transfersData.consolidate)

      const normalizeVariationDataInflow = transfersData.cashflows.flatMap(
        (cashflow) => {
          const inflowData: IVariationData = {
            x: getMonthName(
              Number(cashflow.month),
              Number(cashflow.year),
              Number(cashflow.day),
            ),
            y: [cashflow.inflow],
          }
          return inflowData
        },
      )

      const normalizeVariationDataOutflow = transfersData.cashflows.flatMap(
        (cashflow) => {
          const outflowData: IVariationData = {
            x: getMonthName(
              Number(cashflow.month),
              Number(cashflow.year),
              Number(cashflow.day),
            ),
            y: [-cashflow.outflow],
          }
          return outflowData
        },
      )

      let maxInflow = Number.NEGATIVE_INFINITY
      let minOutflow = Number.POSITIVE_INFINITY
      const tempCategories: string[] = []

      transfersData.cashflows.forEach((entry) => {
        const inflow = parseFloat(entry.inflow)
        const outflow = -parseFloat(entry.outflow)

        const monthName = getMonthName(
          Number(entry.month),
          Number(entry.year),
          Number(entry.day),
        )

        tempCategories.push(monthName)

        if (inflow > maxInflow) {
          maxInflow = inflow
        }
        if (outflow < minOutflow) {
          minOutflow = outflow
        }
      })

      setVariationData([
        {
          name: 'Entradas',
          data: normalizeVariationDataInflow,
        },
        {
          name: 'Saídas',
          data: normalizeVariationDataOutflow,
        },
      ])
      setMinVariation(minOutflow)
      setMaxVariation(maxInflow)
      setCategoriesVariations(tempCategories)
    } catch (error) {
      setIsLoading(false)
      ShowToast('error', 'Ocorreu um erro ao buscar o fluxo de caixa.')
    }
  }

  const handleListCashFlow = async ({
    companiesIds,
    accountIds,
    date,
    accountRequired = true,
  }: {
    date?: string[]
    accountIds?: string[]
    companiesIds?: string[]
    accountRequired: boolean
  }) => {
    try {
      handleListCashFlowUsecase({
        // companiesIds,
        accountIds,
        date,
        accountRequired,
      })
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar o fluxo de caixa. Tente novamente mais tarde.',
      )
    }
  }

  return {
    isLoading,
    cashflow,
    setIsLoading,
    handleListCashFlow,
    cashflowConsolidate,
    variationData,
    minVariation,
    maxVariation,
    categoriesVariations,
  }
}
