import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { BaseInputSingleSelect } from 'src/component/base-component/base-input-single-select'
import { BaseSelectInput } from 'src/component/base-component/base-select-input'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { ModalFullContext } from 'src/component/modal/modal-provider'

export const ModalClassifierExport: React.FC<{
  handle: (data?: any) => void
  options: {
    value: string | number
    label: string
    component?: React.ReactNode
    setSubOptionsSelected?: React.Dispatch<React.SetStateAction<string[]>>
  }[]
  showSubTypes?: boolean
  subTypes?: {
    value: string | number
    label: string
  }[]
}> = ({ handle, options, showSubTypes = false, subTypes = [] }) => {
  const { HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()
  const [typeSelected, setTypeSelected] = useState('excel')
  const [erpSelected, setERPSelected] = useState('')
  const [checkedSubTypes, setCheckedSubTypes] = useState(
    subTypes.map((subType) => subType.value),
  )

  const toggleCheckbox = (value: string | number) => {
    setCheckedSubTypes((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value],
    )
  }

  const handleExport = () => {
    if (showSubTypes && subTypes.length > 0) {
      if (checkedSubTypes.length === 0) {
        ShowToast('error', 'Selecione ao menos um dos formatos de exportação.')
        return
      }
      handle(
        checkedSubTypes.length === subTypes.length
          ? typeSelected
          : checkedSubTypes[0],
      )
    } else {
      handle(typeSelected)
    }
    HideModal()
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
        gap: '24px',
      }}
    >
      <Box
        sx={{
          width: '45vw',
          height: 'auto',
          maxHeight: '400px',
          maxWidth: '488px',
          overflow: 'auto',
          gap: '24px',
          borderRadius: '16px',
        }}
      >
        <Typography>Escolha o formato</Typography>

        <BaseInputSingleSelect
          options={[
            { label: 'Excel', value: 'excel' },
            { label: 'ERP', value: 'erp' },
          ]}
          onSelect={setTypeSelected}
          value={typeSelected}
          showLabel={false}
        />

        {typeSelected === 'erp' && (
          <>
            <Typography marginBottom="15px">
              Selecione o ERP para exportação
            </Typography>

            <BaseSelectInput
              label="ERP"
              labelBackground="#ffffff"
              options={options}
              fullWidth
              error=""
              value={erpSelected}
              setState={(e) => setERPSelected(e)}
            />
          </>
        )}

        {showSubTypes && subTypes.length > 0 && (
          <Box sx={{ marginTop: '16px' }}>
            {subTypes.map((subType) => (
              <FormControlLabel
                key={subType.value}
                control={
                  <Checkbox
                    checked={checkedSubTypes.includes(subType.value)}
                    onChange={() => toggleCheckbox(subType.value)}
                  />
                }
                label={subType.label}
              />
            ))}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gap: '14px',
          justifySelf: 'end',
          padding: '0 4px',
        }}
      >
        <Button
          variant="outlined"
          onClick={HideModal}
          sx={{ width: '104px', height: '40px' }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleExport}
          sx={{ width: '108px', height: '40px' }}
        >
          Exportar
        </Button>
      </Box>
    </Box>
  )
}
