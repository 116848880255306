import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { CascadeButton } from 'src/component/buttons/cascade-button'
import { FilterButton } from 'src/component/buttons/filter-button'
import { CompanyTotalizerCard } from 'src/component/card/company-totalizer'
import { NotificationBellIcon } from 'src/component/icons/notification-bell'
import { TotalizerPlanIcon } from 'src/component/icons/totalizer-plan'
import { TotalizerReleasesIcon } from 'src/component/icons/totalizer-releases'
import { TotalizerUsedTimeIcon } from 'src/component/icons/totalizer-used-time'
import { Table } from 'src/component/table/table'
import { UserFullContext } from 'src/context/context-user'
import { TCompanyBigNumbersResponse } from 'src/service/service-company'
import { CreateCompanyUsersUseCase } from 'src/usecase/company/usecase-company-add-users'
import { ListCompanyClassificationRulesUseCase } from 'src/usecase/company/usecase-company-classification-rules-list'
import { EditCompanySubscriptionPlanUseCase } from 'src/usecase/company/usecase-company-edit-subscriptionplan'
import { LeaveCompanyUseCase } from 'src/usecase/company/usecase-company-leave'
import { ListCompanyUseCase } from 'src/usecase/company/usecase-company-list'
import { PauseCompanySubscriptionPlanUseCase } from 'src/usecase/company/usecase-company-pause-subsacription-plan'
import { ListCompanyPlansUseCase } from 'src/usecase/company/usecase-company-plans-list'
import { ReplyCompanyClassificationRuleUseCase } from 'src/usecase/company/usecase-company-reply-classification-rule'
import { ListCompanyBigNumbersUseCase } from 'src/usecase/company/usecase-list-companies-big-numbers'
import { UserListUseCase } from 'src/usecase/user/usecase-user-list'
import {
  getFormattedUserName,
  getInitials,
} from 'src/utils/stringFormatterUtils'
import Background from '../../assets/background/home-header.png'
import { UserAvatar } from './styles'
import { useCompany } from './useCompany'
import { ListUserSubscriptionsPlansUseCase } from 'src/usecase/company/usecase-company-subscriptions'

interface MultiCompanyPanelPageProps {
  listCompaniesUseCase: ListCompanyUseCase
  listClassificationRulesUseCase: ListCompanyClassificationRulesUseCase
  addUserToCompanyUseCase: CreateCompanyUsersUseCase
  replyCompanyClassificationRuleUseCase: ReplyCompanyClassificationRuleUseCase
  listUsersUseCase: UserListUseCase
  leaveCompanyUseCase: LeaveCompanyUseCase
  listCompanyBigNumbersUseCase: ListCompanyBigNumbersUseCase
  listCompanyPlansUseCase: ListCompanyPlansUseCase
  pauseCompanySubscriptionPlanUseCase: PauseCompanySubscriptionPlanUseCase
  editCompanySubscriptionPlanUseCase: EditCompanySubscriptionPlanUseCase
  listUserSubscriptionsPlansUseCase: ListUserSubscriptionsPlansUseCase
}

export function MultiCompanyPanelPage(props: MultiCompanyPanelPageProps): any {
  const isMediumScreen = useMediaQuery(`(max-width: 1024px)`)
  const navigate = useNavigate()
  const { ShowToast } = ToastFullContext()
  const { GetUserData, Logout, CompanyInfo } = UserFullContext()
  const [bigNumbers, setBigNumbers] = useState<TCompanyBigNumbersResponse>({
    totalClassified: 0,
    totalLicensesInUse: 0,
    totalLicenses: 0,
    totalTimeInHours: '',
    since: '',
  })
  const {
    isLoading,
    searchQuery,
    columns,
    companies,
    setSearchQuery,
    filter,
    setFilter,
    handleListUserCompanies,
    handleListUsers,
    handleOpenLeaveCompanyModal,
    handleCreateCompany,
    handleOpenFilterModal,
    setSelectedGridIds,
    handleAddMassUsersModal,
    setIsLoading,
    handleListPlansUseCase,
    handleOpenPauseSubscriptionModal,
    handleOpenEditSubscriptionModal,
    handleListUserSubscriptionsUseCase,
  } = useCompany(
    props.listCompaniesUseCase,
    props.addUserToCompanyUseCase,
    props.listUsersUseCase,
    props.leaveCompanyUseCase,
    props.listCompanyPlansUseCase,
    props.pauseCompanySubscriptionPlanUseCase,
    props.editCompanySubscriptionPlanUseCase,
    props.listUserSubscriptionsPlansUseCase,
  )

  const handleGetBigNumbers = async () => {
    try {
      setIsLoading(true)
      const bigNumbersResult = await props.listCompanyBigNumbersUseCase.handle({
        companyIds: [],
      })
      setIsLoading(false)

      if (bigNumbersResult.isFailure) {
        ShowToast('error', 'Erro ao recuperar os dados de big numbers.')
      }

      const bigNumbersData = bigNumbersResult.getValue()

      setBigNumbers(bigNumbersData as TCompanyBigNumbersResponse)
    } catch (error) {
      setIsLoading(false)
      ShowToast('error', 'Erro ao recuperar os dados de big numbers.')
    }
  }

  useEffect(() => {
    handleListUsers()
  }, [])

  useEffect(() => {
    handleListPlansUseCase()
    handleListUserSubscriptionsUseCase()
    CompanyInfo({ id: '', name: '', plan: undefined })
    handleListUserCompanies()
    handleGetBigNumbers()
  }, [])

  const handleLogout = () => {
    Logout()
    ShowToast('success', 'Sessão encerrada')
    navigate('/')
  }

  const handleSubscription = () => {
    navigate('/subscription/new')
  }

  return (
    <Stack
      width="100%"
      height="100vh"
      gap="32px"
      alignItems="center"
      sx={{ backgroundColor: '#F4F8FA', overflow: 'hidden' }}
    >
      <Stack
        direction="row"
        padding="36px"
        sx={{
          height: '140px',
          width: 'calc(100% - 48px)',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Stack
          marginLeft={`${isMediumScreen ? '0px' : '48px'}`}
          gap="16px"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <UserAvatar>{getInitials(GetUserData().name)}</UserAvatar>
          <Typography
            color="#ffffff"
            display="flex"
            fontWeight="700"
            fontSize="24px"
          >
            {getFormattedUserName(GetUserData().name)}
          </Typography>
        </Stack>
        <Stack gap="24px" direction="row">
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#ffffff',
              color: '#4865CC',
            }}
            onClick={handleCreateCompany}
          >
            Cadastrar empresa
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#ffffff',
              color: '#4865CC',
            }}
            onClick={() => handleSubscription()}
          >
            Contratar plano
          </Button>
          <IconButton
            sx={{
              backgroundColor: '#ffffff',
              color: '#4865CC',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              padding: 0,
            }}
            onClick={() => console.log('empty')}
          >
            <NotificationBellIcon />
          </IconButton>
          <CascadeButton
            options={[
              {
                label: 'Minha conta',
                onClick: () => navigate('/user/profile'),
              },
              {
                label: 'Relatórios',
                onClick: () => navigate('/company/reports'),
              },
              { label: 'Suporte', onClick: () => navigate('/support') },
              { label: 'Sair', onClick: handleLogout },
            ]}
            iconButton
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        height="140px"
        width="100%"
        maxWidth={`${
          isMediumScreen ? 'calc(100% - 48px)' : 'calc(100% - 280px)'
        }`}
        marginTop="-70px"
      >
        <CompanyTotalizerCard
          data={[
            {
              title: 'Licenças contratadas',
              value: bigNumbers?.totalLicenses?.toString(),
              Icon: TotalizerPlanIcon,
              since: bigNumbers?.since,
              aditionalData: {
                title: 'Licença(s) em uso',
                value: bigNumbers?.totalLicensesInUse?.toString(),
              },
            },
            {
              title: 'Lançamentos',
              value: bigNumbers?.totalClassified?.toString(),
              Icon: TotalizerReleasesIcon,
              since: bigNumbers?.since,
            },
            {
              title: 'Economia de tempo',
              value: bigNumbers?.totalTimeInHours,
              Icon: TotalizerUsedTimeIcon,
              since: bigNumbers?.since,
            },
          ]}
        />
      </Stack>

      <Stack
        width="calc(100% - 64px)"
        height="auto"
        padding="32px"
        marginBottom="32px"
        paddingTop="12px"
        gap="32px"
        sx={{
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {isLoading && (
          <Stack width="100%" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        {!isLoading && (
          <Table
            loadMore={() => console.log('press me')}
            totalPages={1}
            currentPage={1}
            columns={columns}
            rows={
              !searchQuery
                ? companies
                : companies.filter((f) =>
                    f.name.toLowerCase().includes(searchQuery.toLowerCase()),
                  )
            }
            totalRegisters={companies.length}
            allowSelectAll={true}
            loading={false}
            selectedItemActions={[
              {
                title: 'Sair das empresas selecionadas',
                action: handleOpenLeaveCompanyModal,
              },
              {
                title: 'Recontratar / Trocar assinatura',
                action: () => handleOpenEditSubscriptionModal(),
              },
              {
                title: 'Pausar assinatura',
                action: () => handleOpenPauseSubscriptionModal(),
              },
              {
                title: 'Adicionar usuários em massa',
                action: () => handleAddMassUsersModal(),
              },
            ]}
            filter={filter}
            handleOpenFilterModal={handleOpenFilterModal}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setFilter={setFilter}
            rightActions={
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                alignSelf="center"
                gap="32px"
              >
                <Box width="350px">
                  <BaseInput
                    fullWidth
                    labelBackground="#F4F8FA"
                    type="text"
                    label={'Buscar empresa'}
                    setState={(event) => {
                      if (!event) {
                        return setSearchQuery && setSearchQuery('')
                      }

                      setSearchQuery && setSearchQuery(event)
                    }}
                    value={searchQuery ?? ''}
                    error={null}
                    iconStart={<SearchIcon />}
                  />
                </Box>
                <Box>
                  <FilterButton onClick={handleOpenFilterModal}>
                    Filtrar
                  </FilterButton>
                </Box>
              </Stack>
            }
            setSelectedGridIds={setSelectedGridIds}
          />
        )}
      </Stack>
    </Stack>
  )
}
