import React, { useState, useRef } from 'react'
import {
  Checkbox,
  Input,
  ListItemText,
  Box,
  MenuItem,
  Paper,
  Popper,
  ClickAwayListener,
  TextField,
  InputAdornment,
} from '@mui/material'
import { ToastFullContext } from './base-snackbar'
import { ListCompanyDebitUseCase } from 'src/usecase/classifier/usecase-campany-debit-list'
import { BaseInputProps } from './base-input'
import ReactInputMask from 'react-input-mask'
import theme from 'src/shared/util/theme/theme'
import { ErrorOutlineOutlined } from '@mui/icons-material'

interface BaseInputAutocompleteProps extends BaseInputProps {
  error: string | null
  companyId?: string
  variant?: 'debits' | 'credits' | 'vendors' | 'financialCategory'
  inputVariant?: 'outlined' | 'bordered' | 'bottomBordered'
  options: string[]
}

export const BaseInputAutocomplete: React.FC<BaseInputAutocompleteProps> = ({
  companyId,
  variant,
  inputVariant = 'outlined',
  error,
  label,
  setState,
  value,
  containerStyle,
  disabled,
  fullWidth,
  iconEnd,
  iconStart,
  labelBackground,
  lines,
  mask,
  multiline,
  type,
  options,
}) => {
  const [inputValue, setInputValue] = useState(value)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setState(event.target.value)
    if (event.target.value?.length > 0) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleSelect = (optionValue: string) => {
    setInputValue(optionValue)
    setState(optionValue)
    setAnchorEl(null)
  }

  const handleClickAway = () => {
    setAnchorEl(null)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') {
      event.stopPropagation()
    }
  }

  return (
    <Box position="relative">
      {inputVariant === 'outlined' && (
        <Input
          value={inputValue}
          onChange={handleInputChange}
          inputRef={inputRef}
          id="autocomplete-input"
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl)}
          disableUnderline
        />
      )}

      {(inputVariant === 'bordered' || inputVariant === 'bottomBordered') && (
        <TextField
          variant="outlined"
          type={type || 'text'}
          label={label}
          value={inputValue}
          onChange={handleInputChange}
          inputRef={inputRef}
          id="autocomplete-input"
          onKeyDown={handleKeyDown} // Evita que o espaço afete a navegação no DataGrid
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl)}
          InputProps={{
            inputComponent: mask ? (ReactInputMask as any) : undefined,
            inputProps: mask
              ? {
                  mask,
                }
              : undefined,
            startAdornment: iconStart ? (
              <InputAdornment position="start">{iconStart}</InputAdornment>
            ) : undefined,
            endAdornment: error ? (
              <InputAdornment position="end">
                <ErrorOutlineOutlined
                  style={{ color: theme.palette.error.main }}
                />
              </InputAdornment>
            ) : iconEnd ? (
              <InputAdornment position="end">{iconEnd}</InputAdornment>
            ) : undefined,
            classes:
              inputVariant === 'bottomBordered'
                ? { notchedOutline: 'customOutline' }
                : undefined,
          }}
          sx={{
            width: '100%',
            ...(inputVariant === 'bottomBordered' && {
              '& .customOutline': {
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: '0px',
                marginBottom: '8px',
                borderBottom: `1px solid ${theme.palette.primary.main}`,
              },
            }),
          }}
          helperText={error}
          error={!!error}
        />
      )}

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ zIndex: 99999999999 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            style={{
              maxHeight: 400,
              overflowY: 'auto',
              width: 'inherit',
              minWidth: '300px',
              marginTop: 9,
              marginLeft: -10,
            }}
          >
            {options
              .filter((option) =>
                option?.toLowerCase().includes(inputValue?.toLowerCase()),
              )
              .map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={() => handleSelect(option)}
                >
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  )
}
