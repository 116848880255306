import {
  CompanyApiRemoteService,
  IAccountingEntriesRequest,
  IListAccountingEntriesCashflowResponseDTO,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListAccountingEntriesCashflowUseCase
  implements
    IUsecase<
      IAccountingEntriesRequest,
      IListAccountingEntriesCashflowResponseDTO
    >
{
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle(
    data: IAccountingEntriesRequest,
  ): Promise<Result<IListAccountingEntriesCashflowResponseDTO>> {
    try {
      const {
        companyId,
        bankAccountId,
        page,
        vendor,
        dateEnd,
        dateStart,
        query,
        valueMaximum,
        valueMinimum,
        credit,
        debit,
        accountingAccount,
        accountingEntriesAlreadyClassified,
        showDespised,
      } = data

      const response = await this.companyApi.getAccountingEntriesCashflow(
        companyId,
        bankAccountId,
        page,
        vendor,
        dateStart,
        dateEnd,
        query,
        valueMinimum,
        valueMaximum,
        credit,
        debit,
        accountingAccount,
        accountingEntriesAlreadyClassified,
        showDespised,
      )

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados do classificador.'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
