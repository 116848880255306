import ExcelJS from 'exceljs'
import logo from '../assets/logo/logo-full-blue.png'

interface SheetData {
  sheetName: string
  columns: string[]
  data: (string | number)[][]
}

const getBase64FromImageUrl = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous' // Caso a imagem precise de permissões
    img.src = url

    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      if (!ctx) return

      ctx.drawImage(img, 0, 0)
      const base64 = canvas.toDataURL('image/png') // Converte para base64
      resolve(base64)
    }

    img.onerror = (err) => reject(err)
  })
}

export const generateXLS = async (
  sheets: SheetData[],
  fileName: string,
): Promise<void> => {
  const workbook = new ExcelJS.Workbook()

  for (const { sheetName, columns, data } of sheets) {
    const sheet = workbook.addWorksheet(sheetName)

    const base64Logo = await getBase64FromImageUrl(logo)
    if (base64Logo) {
      const logoId = workbook.addImage({
        base64: base64Logo,
        extension: 'png',
      })

      sheet.addImage(logoId, {
        tl: { col: 0, row: 0 },
        ext: { width: 200, height: 100 },
      })

      sheet.mergeCells('A1:D4')
    }

    sheet.mergeCells('A5:D5')
    sheet.getCell('A5').value = `Relatório - ${sheetName}`
    sheet.getCell('A5').font = { bold: true, size: 14 }

    sheet.mergeCells('A6:D6')
    sheet.getCell('A6').value =
      `Data de Criação: ${new Date().toLocaleString()}`
    sheet.getCell('A6').font = { bold: true, size: 12 }

    const headerRowIndex = 7
    sheet.addRow(columns).font = { bold: true }
    data.forEach((row) => sheet.addRow(row))
  }

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  link.click()
}
