import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { StorageLocalstorage } from 'src/shared/storage/storage-localstorage'

type CompanyInfo = {
  id?: string
  name?: string
  cnpj?: string
  plan?: {
    id: string
    name: string
  }
}

type UserStateType = {
  id?: string
  name?: string
  companyName?: string
  companies?: CompanyInfo[]
  personType?: string
  cpfCnpj?: string
  username?: string
  termsOfUse?: string
  email?: string
  backupCode?: string
  twoAuthEnabled?: boolean
}

type UserInfo = {
  id?: string
  name?: string
  companyName?: string
  companies?: CompanyInfo[]
  personType?: string
  cpfCnpj?: string
  username?: string
  termsOfUse?: string
  email?: string
  backupCode?: string
  twoAuthEnabled?: boolean
}

export type UserContextType = {
  currentCompanyId: string
  setCurrentCompanyId: React.Dispatch<React.SetStateAction<string>>
  UserInfo: (userInfo: UserInfo) => void
  CompanyInfo: (companyInfo: CompanyInfo) => void
  Logout: () => void
  GetUserData: () => UserStateType
  GetCompanyData: () => CompanyInfo
  SetUserDataKeyValue: (key: keyof UserStateType, value: string) => void
}

const UserContext = createContext({} as UserContextType)

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const userStorage = StorageLocalstorage.get('user')
  const currentCompanyStorage = StorageLocalstorage.get('currentCompany')
  const [currentCompanyId, setCurrentCompanyId] = useState<string>('')

  const [userState, setUserState] = useState<UserStateType>({
    id: userStorage?.id || '',
    name: userStorage?.name || '',
    companyName: userStorage?.companyName || '',
    companies: userStorage?.companies || [],
    personType: userStorage?.personType || '',
    cpfCnpj: userStorage?.cpfCnpj || '',
    username: userStorage?.username || '',
    termsOfUse: userStorage?.termsOfUse || '',
    email: userStorage?.email || '',
    backupCode: userStorage?.backupCode || '',
    twoAuthEnabled: userStorage?.twoAuthEnabled === true,
  })

  const [currentCompanyState, setCurrentCompanyState] = useState<CompanyInfo>({
    id: currentCompanyStorage?.id || '',
    name: currentCompanyStorage?.name || '',
    plan: currentCompanyStorage?.plan || undefined,
  })

  const UserInfo = (userInfo: UserInfo) => {
    try {
      setUserState({
        id: userInfo.id,
        name: userInfo.name,
        companyName: userInfo.companyName,
        companies: userInfo.companies,
        personType: userInfo.personType,
        cpfCnpj: userInfo.cpfCnpj,
        username: userInfo.username,
        termsOfUse: userInfo.termsOfUse,
        email: userInfo.email,
        backupCode: userInfo.backupCode,
        twoAuthEnabled: userInfo.twoAuthEnabled,
      })

      StorageLocalstorage.set('user', {
        id: userInfo.id,
        name: userInfo.name,
        companyName: userInfo.companyName,
        companies: userInfo.companies,
        personType: userInfo.personType,
        cpfCnpj: userInfo.cpfCnpj,
        username: userInfo.username,
        termsOfUse: userInfo.termsOfUse,
        email: userInfo.email,
        backupCode: userInfo.backupCode,
        twoAuthEnabled: userInfo.twoAuthEnabled,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const CompanyInfo = (companyInfo: CompanyInfo) => {
    try {
      setCurrentCompanyState({
        id: companyInfo?.id ?? '',
        name: companyInfo.name ?? '',
        plan: companyInfo?.plan ?? undefined,
      })
      StorageLocalstorage.set('currentCompany', {
        id: companyInfo?.id ?? '',
        name: companyInfo.name ?? '',
        plan: companyInfo?.plan ?? undefined,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const Logout = () => {
    try {
      localStorage.clear()

      StorageLocalstorage.set('user', {
        id: '',
        name: '',
        companyName: '',
        companies: [],
        personType: '',
        cpfCnpj: '',
        username: '',
        termsOfUse: '',
        email: '',
        backupCode: '',
        twoAuthEnabled: false,
      })
      StorageLocalstorage.set('currentCompany', {
        id: '',
        name: '',
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetUserData = useCallback(() => userState, [userState])
  const GetCompanyData = useCallback(
    () => currentCompanyState,
    [currentCompanyState],
  )

  const SetUserDataKeyValue = (key: keyof UserStateType, value: string) => {
    try {
      const userStorage = StorageLocalstorage.get('user')

      StorageLocalstorage.set('user', {
        ...userStorage,
        [key]: value,
      })
      setUserState((state) => ({ ...state, [key]: value }))
    } catch (error) {
      console.log(error)
    }
  }

  const userProviderValue = useMemo(
    () => ({
      currentCompanyId,
      setCurrentCompanyId,
      UserInfo,
      CompanyInfo,
      Logout,
      GetUserData,
      GetCompanyData,
      SetUserDataKeyValue,
    }),
    [
      UserInfo,
      Logout,
      GetUserData,
      GetCompanyData,
      currentCompanyId,
      setCurrentCompanyId,
      CompanyInfo,
    ],
  )

  // const handleRefreshToken = async () => {
  //   try {
  //     const userRefreshToken = localStorage.getItem('refreshToken')

  //     if (!userRefreshToken || userRefreshToken?.length === 0) {
  //       return
  //     }

  //     const { refreshToken, sessionToken } = await Services.Token.refresh(
  //       userRefreshToken,
  //     )

  //     setUserState((prevState) => ({
  //       ...prevState,
  //       refreshToken,
  //       sessionToken,
  //     }))

  //     localStorage.setItem('refreshToken', refreshToken)
  //     localStorage.setItem('sessionToken', sessionToken)
  //   } catch (error) {
  //     console.log(error)
  //     Logout()
  //     navigate('/sign-in', { replace: true })
  //   }
  // }

  // useEffect(() => {
  //   handleRefreshToken()
  // }, [location.pathname])

  return (
    <UserContext.Provider value={userProviderValue}>
      {children}
    </UserContext.Provider>
  )
}

export function UserFullContext() {
  const context = useContext(UserContext)
  return context
}
