import { AxiosInstance } from 'axios'
import { Result } from 'src/shared/protocol/protoco-result'

export enum OpenFinanceAdapterAccountInfoTypeEnum {
  CONTA_DEPOSITO_A_VISTA = 'CONTA_DEPOSITO_A_VISTA',
  CONTA_POUPANCA = 'CONTA_POUPANCA',
  CONTA_PAGAMENTO_PRE_PAGA = 'CONTA_PAGAMENTO_PRE_PAGA',
  CARTAO_CREDITO = 'CARTAO_CREDITO',
}

export enum BankAccountTypeEnum {
  CONTA_DEPOSITO_A_VISTA,
  CONTA_POUPANCA,
  CONTA_PAGAMENTO_PRE_PAGA,
  CARTAO_CREDITO,
  CONTA_CORRENTE,
  CAIXINHA,
  INVESTIMENTOS,
  APLICACAO_AUTOMATICA,
  MEIOS_DE_RECEBIMENTO,
  OUTROS,
}

export class BankAccountTypeEnumUtil {
  public static VALUES = [
    BankAccountTypeEnum.CONTA_DEPOSITO_A_VISTA,
    BankAccountTypeEnum.CONTA_POUPANCA,
    BankAccountTypeEnum.CONTA_PAGAMENTO_PRE_PAGA,
    BankAccountTypeEnum.CARTAO_CREDITO,
    BankAccountTypeEnum.CONTA_CORRENTE,
    BankAccountTypeEnum.CAIXINHA,
    BankAccountTypeEnum.INVESTIMENTOS,
    BankAccountTypeEnum.APLICACAO_AUTOMATICA,
    BankAccountTypeEnum.MEIOS_DE_RECEBIMENTO,
    BankAccountTypeEnum.OUTROS,
  ]

  public static NAMES = [
    'Conta Depósito a Vista',
    'Conta Poupança',
    'Conta Pagamento Pré Paga',
    'Cartão de Crédito',
    'Conta Corrente',
    'Caixinha',
    'Investimentos',
    'Aplicação Automática',
    'Meios de Recebimento',
    'Outros',
  ]

  public static OPTIONS_STRING = [
    'CONTA_DEPOSITO_A_VISTA',
    'CONTA_POUPANCA',
    'CONTA_PAGAMENTO_PRE_PAGA',
    'CARTAO_CREDITO',
    'CONTA_CORRENTE',
    'CAIXINHA',
    'INVESTIMENTOS',
    'APLICACAO_AUTOMATICA',
    'MEIOS_DE_RECEBIMENTO',
    'OUTROS',
  ]

  public static toString(value: BankAccountTypeEnum): string {
    return this.NAMES[this.VALUES.findIndex((v) => v === value)]
  }

  public static optionsToString(value: BankAccountTypeEnum): string {
    return this.OPTIONS_STRING[this.VALUES.findIndex((v) => v === value)]
  }

  public static parse(value: string): BankAccountTypeEnum {
    return this.VALUES[this.NAMES.findIndex((v) => v === value)]
  }

  public static getOptions(): Array<{ id: string; title: string }> {
    return this.VALUES.map((value, index) => ({
      id: value.toString(),
      title: this.NAMES[index],
    }))
  }
}

export enum BankAccountPersonTypeEnum {
  FISICA,
  JURIDICA,
}

export class BankAccountPersonTypeEnumUtil {
  public static VALUES = [
    BankAccountPersonTypeEnum.FISICA,
    BankAccountPersonTypeEnum.JURIDICA,
  ]

  public static NAMES = ['Física', 'Jurídica']

  public static toString(value: BankAccountPersonTypeEnum): string {
    return this.NAMES[this.VALUES.findIndex((v) => v === value)]
  }

  public static parse(value: string): BankAccountPersonTypeEnum {
    return this.VALUES[this.NAMES.findIndex((v) => v === value)]
  }

  public static getOptions(): Array<{ id: string; title: string }> {
    return this.VALUES.map((value, index) => ({
      id: value.toString(),
      title: this.NAMES[index],
    }))
  }
}

export type TAccountCreateDTO = {
  id?: string
  name: string
  description: string
  companyId: string
  openFinance: boolean
  accountType: BankAccountTypeEnum
  personType: BankAccountPersonTypeEnum
  bank: string
  bankNumber: string
  agencyNumber: string
  accountNumber: string
  transactionCommencementDate: Date
  amount: string
  consentedAccountId?: string
  externalUserId?: string
  holderId?: string
  accountId?: string
  bankLogo?: string
}

export type TAccountUpdateDTO = {
  id: string
  name?: string
  description?: string
  companyId: string
  accountType?: BankAccountTypeEnum
  personType?: BankAccountPersonTypeEnum
  bank?: string
  bankNumber?: string
  agencyNumber?: string
  accountNumber?: string
  ledgerAccount?: string
  ledgerAccountBalance?: number
  ledgerAccountStartDate?: Date | string
  transactionCommencementDate?: Date | string
  balance?: number
}

export enum UserBankAccountPermissionsEnum {}

export interface IBankBalance {
  id: string
  // Relational data
  transfersImportId: string | null | undefined
  // Common data
  amount: string
  date: Date
  // Metadata
  createdAt: Date
  updatedAt: Date
}

export type IBankAccount = {
  id: string
  // Relational data
  balanceHistory: IBankBalance[]
  // Common data
  name: string
  description: string
  bank: string
  bankNumber: string
  accountType: BankAccountTypeEnum
  personType: BankAccountPersonTypeEnum
  // Account data
  agencyNumber: string
  accountNumber: string
  // OpenFinance
  openFinance: boolean
  consentedAccountId: string
  transactionCommencementDate: Date
  // Metadata
  createdAt: Date
  updatedAt: Date
  // Company join
  company?: any
  balance: string
  initialBalance: string
  bankLogo?: string
  ledgerAccount: string
  ledgerAccountBalance: string
  ledgerAccountStartDate: Date
}

export type IBankAccountOpenFinanceInitialFlowResponse = {
  accessToken: string
  consentedAccountId: string
  externalUserId: string
  webhookUrl: string
}

export type TPluggyCallback = {
  event: string
  itemId: string
}

export type TBankAccountOpenFinanceFinalFLowDTO = {
  companyId: string
  externalUserId: string
  consentedAccountId: string
  pluggyCallback: TPluggyCallback
}

export interface IHolderInformedIncome {
  id: string
  frequency: string | null | undefined
  amount: string | null | undefined
  currency: string | null | undefined
  date: Date | null | undefined
  holderId: string | null | undefined
  createdAt: Date
  updatedAt: Date
}

export interface IHolderInformedPatrimony {
  id: string
  amount: string | null | undefined
  currency: string | null | undefined
  year: number | null | undefined
  date: Date | null | undefined
  holderId: string | null | undefined
  createdAt: Date
  updatedAt: Date
}

export interface IHolder {
  id: string
  personalId: string
  startDate: Date | null | undefined
  brandName: string
  civilName: string
  socialName: string
  birthDate: Date
  maritalStatusAdditionalInfo: string
  hasBrazilianNationality: boolean
  informedIncome: IHolderInformedIncome
  informedPatrimony: IHolderInformedPatrimony
  documentsCpfNumber: string
  documentsPassportCountry: string
  documentsPassportExpirationDate: Date
  documentsPassportIssueDate: Date
  documentsPassportNumber: string
  createdAt: Date
  updatedAt: Date
}

// Dicionário
export enum OpenFinanceAdapterDocumentTypeEnum {
  CNH = 'CNH',
  RG = 'RG',
  NIF = 'NIF',
  RNE = 'RNE',
  OUTROS = 'OUTROS',
}

export interface IOpenFinanceAdapterConsentedAccount {
  data: {
    brandName: string
    companyCnpj: string
    type: OpenFinanceAdapterAccountInfoTypeEnum
    compeCode?: string // Ver dicionário (?)
    branchCode: string
    number: string
    checkDigit: string
    accountId: string
    actualBalance: string
  }[]
}

export type IBankAccountOpenFinanceFinalFlowResponse = {
  id: string
  holder: IHolder
  accounts: IOpenFinanceAdapterConsentedAccount
}

export enum BankTransfersImportTypeEnum {
  OFX = 'OFX',
  CSV = 'CSV',
}
export interface IBankTransfersImport {
  id: string
  bankAccount: IBankAccount
  balance: IBankBalance
  type: BankTransfersImportTypeEnum
  createdAt: Date
  updatedAt: Date
}

export interface IBankCashflow {
  id: string
  // Relational data
  bankAccount: IBankAccount
  // Common data
  month: string
  year: string
  balance: string
  inflow: string
  outflow: string
  // Metadata
  createdAt: Date
  updatedAt: Date
}

export enum BankTransferStatus {}

export enum BankTransferType {}

export enum BankTransferMethod {}

export enum BankTransferPartieType {}

export interface IImportBankTransfer {
  length: number
  severity: string
  code: number
  detail: string
  schema: string
  table: string
  constraint: string
  file: string
  line: string
  routine: string
  amount?: string
}

export interface IBankCashflowEntity {
  id: string
  month: string
  year: string
  balance: string
  inflow: string
  outflow: string
  createdAt: Date
  updatedAt: Date
}

export type TImportOfxUsecaseResponse = {
  transactions: IImportBankTransfer[]
  balance: IBankBalance
  cashflows: IBankCashflowEntity[]
}

export interface TAccountCSVImportTransactionsResponse {
  successfulTransactions: {
    method: string
    id: string
    amount: string
  }[]
  failedTransactions: string[]
}

export class AccountApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public create = async (
    data: TAccountCreateDTO,
  ): Promise<Result<IBankAccount>> => {
    try {
      const result = await this.service.post(`/v1/bank/${data.companyId}`, data)
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public update = async (
    data: TAccountUpdateDTO,
  ): Promise<Result<IBankAccount>> => {
    try {
      console.log(data)
      const result = (await this.service.put(
        `/v1/bank/${data.id}`,
        data,
      )) as IBankAccount
      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public delete = async ({
    companyId,
    accountId,
  }: {
    companyId: string
    accountId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.delete(`/v1/bank/${companyId}/account/${accountId}`)
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyAccounts = async (
    companyId: string,
  ): Promise<Result<IBankAccount[]>> => {
    try {
      const result = await this.service.get(`/v1/bank/${companyId}`)
      return Result.ok(result.data.accounts)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getLedgerAccounts = async (
    companyId: string,
    query: string,
  ): Promise<
    Result<{
      debits: string[]
      credits: string[]
      financialCategories: string[]
    }>
  > => {
    try {
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries/debits`,
        {
          params: { query },
        },
      )
      return Result.ok({
        debits: result.data.debits,
        credits: result.data.credits,
        financialCategories: result.data.financialCategories,
      })
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getUserAccountsByCompaniesIds = async (
    companiesIds?: string[],
  ): Promise<Result<IBankAccount[]>> => {
    try {
      const result = await this.service.get(`/v1/bank`, {
        params: {
          companyId: companiesIds,
        },
      })
      return Result.ok(result.data.accounts)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyAccount = async (
    companyId: string,
    accountId: string,
  ): Promise<Result<IBankAccount>> => {
    try {
      const result = await this.service.get(
        `/v1/bank/${companyId}/account/${accountId}`,
      )
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyAccountActualOpenFinanceBalance = async (
    accountId: string,
    holderId: string,
  ): Promise<Result<{ actualBalance: string }>> => {
    try {
      const result = await this.service.get(
        `/v1/bank/open-finance/actual-balance/${holderId}/${accountId}`,
      )
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  // OpenFinance
  public openFinanceInititalFlow = async (): Promise<
    Result<IBankAccountOpenFinanceInitialFlowResponse>
  > => {
    try {
      const result = await this.service.post(
        `/v1/bank/open-finance/flow/initial`,
      )
      return Result.ok(
        result.data as IBankAccountOpenFinanceInitialFlowResponse,
      )
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public openFinanceFinalFlow = async (
    data: TBankAccountOpenFinanceFinalFLowDTO,
  ): Promise<Result<IBankAccountOpenFinanceFinalFlowResponse>> => {
    try {
      const result = await this.service.post(
        `/v1/bank/open-finance/flow/final`,
        data,
      )
      return Result.ok(result.data as IBankAccountOpenFinanceFinalFlowResponse)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public openFinanceDisconnect = async ({
    accountId,
    companyId,
  }: {
    companyId: string
    accountId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.put(
        `v1/bank/${companyId}/bank-account/${accountId}/open-finance/disconect`,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public openFinanceConnect = async ({
    accountId,
    companyId,
    editData,
  }: {
    companyId: string
    accountId: string
    editData: {
      bankLogo?: string
      consentedAccountId: string
    }
  }): Promise<Result<void>> => {
    try {
      const result = await this.service.put(
        `v1/bank/${companyId}/bank-account/${accountId}/open-finance/connect`,
        {
          bankLogo: editData.bankLogo,
          consentedAccountId: editData.consentedAccountId,
        },
      )

      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public uploadOfxFile = async (
    file: File,
    companyId: string,
    accountId: string,
  ): Promise<Result<TImportOfxUsecaseResponse>> => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await this.service.postForm(
        `/v1/bank/${companyId}/bank-account/${accountId}/import/ofx`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return Result.ok(response.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public uploadCSVAndGetColumns = async (
    file: File,
    companyId: string,
    accountId: string,
  ): Promise<Result<{ columns: string[] }>> => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await this.service.postForm(
        `/v1/bank/${companyId}/bank-account/${accountId}/import/csv/columns`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return Result.ok(response.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public uploadCSVAndPreviewTransactions = async (
    file: File,
    companyId: string,
    accountId: string,
    matchingColumns: string,
  ): Promise<
    Result<{
      preview: {
        [key: string]: string
      }[]
    }>
  > => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('matchingColumns', matchingColumns)

      const response = await this.service.postForm(
        `/v1/bank/${companyId}/bank-account/${accountId}/import/csv/preview`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return Result.ok(response.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public uploadCSVTransactions = async (
    file: File,
    companyId: string,
    accountId: string,
    matchingColumns: string,
  ): Promise<Result<TAccountCSVImportTransactionsResponse>> => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('matchingColumns', matchingColumns)

      const response = await this.service.postForm(
        `/v1/bank/${companyId}/bank-account/${accountId}/import/csv`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return Result.ok(response.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }
}
