import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BaseDropDownInput } from 'src/component/base-component/base-dropdown'
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { Logo } from 'src/component/base-component/base-logo-component'
import { BaseMultipleSelectInput } from 'src/component/base-component/base-multiple-select'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { CascadeButton } from 'src/component/buttons/cascade-button'
import { FilterButton } from 'src/component/buttons/filter-button'
import { DefaultHeader } from 'src/component/header/header'
import { NotificationBellIcon } from 'src/component/icons/notification-bell'
import { ClassifierFilterModal } from 'src/component/modal/classifier/filter-modal'
import { DismemberEntryModal } from 'src/component/modal/classifier/modal-classifier-dismember'
import { ModalClassifierExport } from 'src/component/modal/classifier/modal-classifier-export'
import { ModalClassifierPaymentEntry } from 'src/component/modal/classifier/modal-classifier-payment-entry'
import { ModalClassifierPendency } from 'src/component/modal/classifier/modal-classifier-pendency'
import { ModalClassifierEditEntry } from 'src/component/modal/classifier/modal-edit-entry'
import {
  ModalFullContext,
  ModalPositionEnum,
} from 'src/component/modal/modal-provider'
import { ColumnsCommon } from 'src/component/table/header'
import { Table } from 'src/component/table/table'
import {
  CompanyAccountEntryConciliationStatusEnum,
  CompanyClassificationRuleConditionalsConditionEnum,
  CompanyClassificationRuleConditionalsVariableEnum,
  IAccountingEntries,
  ICompanyClassificationRuleConditionals,
  IListAccountingEntriesResponseDTO,
  TCompanyAccountEntryCreate,
} from 'src/service/service-company'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts'
import { ListUserCompaniesAccountsUseCase } from 'src/usecase/bank-account/usecase-user-companies-list'
import { ListAccountingEntriesBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-balance-list'
import { ListAccountingEntriesBankBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-bank-balance-list'
import { ListAccountingEntriesCashflowUseCase } from 'src/usecase/classifier/usecase-accounting-entries-cashflow-list'
import { ClassifyAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-classify'
import { CreateManualAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-create'
import { AccountingEntryDismemberUseCase } from 'src/usecase/classifier/usecase-accounting-entries-dismember'
import { ListAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-list'
import { UpdateAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-update'
import { ListCompanyDebitUseCase } from 'src/usecase/classifier/usecase-campany-debit-list'
import { ListCompanyVendorsUseCase } from 'src/usecase/classifier/usecase-company-vendors-list'
import { generateXLS } from 'src/utils/generateXLS'
import { extractBankName } from 'src/utils/normalizedBankLogos'
import BlueHeader from '../../assets/background/header-blue.png'
import { useUserAccount } from '../systemic-accounts/useUserAccount'
import { useClassifier } from './useClassifier'
import { BankAccountTransferMethodEnum } from 'src/service/service-bank'
import { ClassificationRuleModal } from 'src/component/modal/classificationRules/modal-create-classification-rule'
import { CreateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-create-classification-rule'
import { BaseTooltip } from 'src/component/base-component/base-tooltip'
import { ImportFileModal } from 'src/component/modal/modal-import-file'
import { CreateCompanyAccountingEntriesImportFileUseCase } from 'src/usecase/classifier/usecase-accounting-entries-import-file'
import { CompanyAccountingEntriesCreateMassModal } from 'src/component/modal/classifier/modal-import-entries'

interface TemporaryProps {
  listUserCompaniesAccountsUseCase: ListUserCompaniesAccountsUseCase
  listAccountingEntriesUseCase: ListAccountingEntriesUseCase
  updateAccountingEntriesUseCase: UpdateAccountingEntriesUseCase
  listCompanyVendorsUserCase: ListCompanyVendorsUseCase
  listCompanyDebitsUserCase: ListCompanyDebitUseCase
  classifyAccountingEntriesUseCase: ClassifyAccountingEntriesUseCase
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase
  listAccountingEntriesBankBalanceUseCase: ListAccountingEntriesBankBalanceUseCase
  listAccountingEntriesBalanceUseCase: ListAccountingEntriesBalanceUseCase
  listAccountingEntriesCashflowUseCase: ListAccountingEntriesCashflowUseCase
  createManualAccountingEntriesUseCase: CreateManualAccountingEntriesUseCase
  accountingEntryDismemberUseCase: AccountingEntryDismemberUseCase
  createClassificationRuleUseCase: CreateAccountClassificationRuleUseCase
  createCompanyAccountingEntriesImportFileUseCase: CreateCompanyAccountingEntriesImportFileUseCase
}

export function ClassifierPage(props: TemporaryProps) {
  const { ShowToast } = ToastFullContext()
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')
  const useTwoLinesHeader = useMediaQuery('(max-width: 1280px)')
  const { companyId } = useParams()
  const [showResults, setShowResults] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const ContainerRef = useRef<HTMLDivElement>(null)
  const { ShowModal, HideModal } = ModalFullContext()
  const [selectedVendors, setSelectedVendors] = useState<string[] | undefined>(
    undefined,
  )
  const [selectedDebits, setSelectedDebits] = useState<string[] | undefined>(
    undefined,
  )
  const [selectedCredits, setSelectedCredits] = useState<string[] | undefined>(
    undefined,
  )
  const [selectedCC, setSelectedCC] = useState<string[] | undefined>(undefined)
  const [initialDate, setInitialDate] = useState<Date | undefined>(
    startOfMonth(subMonths(new Date(), 3)),
  )
  const [finalDate, setFinalDate] = useState<Date | undefined>(
    endOfMonth(new Date()),
  )
  const [minValue, setMinValue] = useState('')
  const [maxValue, setMaxValue] = useState('')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [
    accountingEntriesAlreadyClassified,
    setAccountingEntriesAlreadyClassified,
  ] = useState<'true' | 'false' | undefined>(undefined)
  const [showDespised, setshowDespised] = useState<
    'true' | 'false' | undefined
  >(undefined)
  const [accountsSelected, setAccountsSelected] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  const [creditAccounts, setCreditAccounts] = useState<string[]>([])
  const [debitAccounts, setDebitAccounts] = useState<string[]>([])
  const [financialCategories, setFinancialCategories] = useState<string[]>([])

  const { handleListUserCompaniesAccounts, userCompaniesAccounts } =
    useUserAccount(props.listUserCompaniesAccountsUseCase)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const bankAccountOptions = userCompaniesAccounts.map((item) => {
    return {
      label: item.name,
      value: item.id,
      icon: (
        <Logo
          name={extractBankName(item.bank)}
          width={32}
          height={32}
          logo={item.bankLogo}
        />
      ),
    }
  })

  const {
    isLoading,
    isLoadingBigNumbersCashflow,
    isLoadingBigNumbersBalance,
    selectedGridIds,
    setIsLoading,
    handleListAccountingEntries,
    handleListAccountingEntriesBalance,
    handleListAccountingEntriesBankBalance,
    handleListAccountingEntriesCashflow,
    setSelectedGridIds,
    accountingEntries,
    handleListCompanyVendors,
    handleSelectColumns,
    columnsTableSelected,
    totalPages,
    totalRegisters,
    informationsCashflow,
    informationsBankBalance,
    vendorsOptions,
    credits,
    debits,
    finalcialCategories,
    HistoricVariables,
    handleGeLedgerAccounts,
    setAccountingEntries,
  } = useClassifier(
    props.listAccountingEntriesUseCase,
    props.updateAccountingEntriesUseCase,
    props.listCompanyVendorsUserCase,
    props.listLedgerAccountsUseCase,
    props.listAccountingEntriesBankBalanceUseCase,
    props.listAccountingEntriesBalanceUseCase,
    props.listAccountingEntriesCashflowUseCase,
    props.classifyAccountingEntriesUseCase,
    props.accountingEntryDismemberUseCase,
    companyId as string,
  )

  const handleRefetch = useCallback(
    async (accounts: string[]) => {
      setCurrentPage(1)
      if (accounts.length === 0) {
        return setAccountingEntries([])
      }
      handleListAccountingEntries(
        1,
        accounts,
        selectedVendors,
        initialDate,
        finalDate,
        searchQuery,
        maxValue,
        minValue,
        selectedCredits,
        selectedDebits,
        selectedCC,
        accountingEntriesAlreadyClassified,
        showDespised,
      )
      handleListAccountingEntriesBalance(
        1,
        accounts,
        selectedVendors,
        initialDate,
        finalDate,
        searchQuery,
        maxValue,
        minValue,
        selectedCredits,
        selectedDebits,
        selectedCC,
        accountingEntriesAlreadyClassified,
        showDespised,
      )
      handleListAccountingEntriesBankBalance(
        1,
        accounts,
        selectedVendors,
        initialDate,
        finalDate,
        searchQuery,
        maxValue,
        minValue,
        selectedCredits,
        selectedDebits,
        accountingEntriesAlreadyClassified,
        showDespised,
      )
      handleListAccountingEntriesCashflow(
        1,
        accounts,
        selectedVendors,
        initialDate,
        finalDate,
        searchQuery,
        maxValue,
        minValue,
        selectedCredits,
        selectedDebits,
        selectedCC,
        accountingEntriesAlreadyClassified,
        showDespised,
      )
    },
    [
      selectedVendors,
      initialDate,
      finalDate,
      searchQuery,
      maxValue,
      minValue,
      selectedCredits,
      selectedDebits,
      accountingEntriesAlreadyClassified,
      showDespised,
    ],
  )

  const handleLoadMore = useCallback(
    async (newPage: number) => {
      setCurrentPage(newPage)
      await handleListAccountingEntries(
        newPage,
        accountsSelected,
        selectedVendors,
        initialDate,
        finalDate,
        searchQuery,
        maxValue,
        minValue,
        selectedCredits,
        selectedDebits,
        selectedCC,
        accountingEntriesAlreadyClassified,
        showDespised,
      )
    },
    [
      accountsSelected,
      selectedVendors,
      initialDate,
      finalDate,
      searchQuery,
      maxValue,
      minValue,
      selectedCredits,
      selectedDebits,
      accountingEntriesAlreadyClassified,
      showDespised,
    ],
  )

  const handleCreateNewEntries = useCallback(
    async (data: TCompanyAccountEntryCreate[]) => {
      try {
        setIsLoading(true)
        const usecaseResult =
          await props.createManualAccountingEntriesUseCase.handle({
            data,
            companyId: companyId as string,
          })

        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
          )
        }
        if (accountsSelected) {
          await handleRefetch(accountsSelected)
        }
        HideModal()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        ShowToast(
          'error',
          'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
        )
      }
    },
    [accountsSelected],
  )

  const handleApplyFilter = useCallback(
    async (
      currentInitialDate: Date | undefined,
      currentFinalDate: Date | undefined,
      currentMinValue: string,
      currentMaxValue: string,
      currentSelectedVendors: string[] | undefined,
      currentSelectedCredits: string[] | undefined,
      currentSelectedDebits: string[] | undefined,
      currentSelectedCC: string[] | undefined,
      searchQuery: string | undefined,
    ) => {
      const validatedSearchQuery = searchQuery ?? ''
      setCurrentPage(1)
      handleListAccountingEntries(
        1,
        accountsSelected,
        currentSelectedVendors,
        currentInitialDate,
        currentFinalDate,
        validatedSearchQuery,
        currentMaxValue,
        currentMinValue,
        currentSelectedCredits,
        currentSelectedDebits,
        currentSelectedCC,
        accountingEntriesAlreadyClassified,
        showDespised,
      )
      handleListAccountingEntriesBalance(
        1,
        accountsSelected,
        currentSelectedVendors,
        currentInitialDate,
        currentFinalDate,
        validatedSearchQuery,
        currentMaxValue,
        currentMinValue,
        currentSelectedCredits,
        currentSelectedDebits,
        currentSelectedCC,
        accountingEntriesAlreadyClassified,
        showDespised,
      )
      handleListAccountingEntriesBankBalance(
        1,
        accountsSelected,
        currentSelectedVendors,
        currentInitialDate,
        currentFinalDate,
        validatedSearchQuery,
        currentMaxValue,
        currentMinValue,
        currentSelectedCredits,
        currentSelectedDebits,
        accountingEntriesAlreadyClassified,
        showDespised,
      )
      handleListAccountingEntriesCashflow(
        1,
        accountsSelected,
        currentSelectedVendors,
        currentInitialDate,
        currentFinalDate,
        validatedSearchQuery,
        currentMaxValue,
        currentMinValue,
        currentSelectedCredits,
        currentSelectedDebits,
        currentSelectedCC,
        accountingEntriesAlreadyClassified,
        showDespised,
      )
    },
    [accountsSelected, accountingEntriesAlreadyClassified, showDespised],
  )

  const handleOpenFilter = () => {
    console.log('selectedCC', selectedCC)

    return ShowModal({
      content: (
        <ClassifierFilterModal
          setInitialDate={setInitialDate}
          setFinalDate={setFinalDate}
          clientOptions={vendorsOptions}
          creditOptions={credits?.map((item) => {
            return {
              label: item,
              value: item,
            }
          })}
          debitOptions={debits?.map((item) => {
            return {
              label: item,
              value: item,
            }
          })}
          accountingAccountOptions={[
            ...new Set([
              ...credits?.map((item) => item),
              ...debits?.map((item) => item),
            ]),
          ]
            .map((item) => ({
              label: item,
              value: item,
            }))
            .filter((item) => item.value && item.value?.trim()?.length > 0)}
          initialDate={initialDate}
          finalDate={finalDate}
          minValue={minValue}
          maxValue={maxValue}
          searchQuery={searchQuery}
          selectedClients={selectedVendors}
          selectedCredits={selectedCredits}
          selectedDebits={selectedDebits}
          selectedCC={selectedCC}
          setSelectedClients={setSelectedVendors}
          setSelectedCredits={setSelectedCredits}
          setSelectedDebits={setSelectedDebits}
          setSelectedCC={setSelectedCC}
          setMaxValue={setMaxValue}
          setMinValue={setMinValue}
          setSearchQuery={setSearchQuery}
          handleApply={handleApplyFilter}
        />
      ),
      title: 'Filtrar lançamentos',
      closeButton: true,
      position: ModalPositionEnum.right,
    })
  }

  useEffect(() => {
    if (companyId) {
      handleListCompanyVendors(companyId)
      handleListUserCompaniesAccounts([companyId])
      handleGeLedgerAccounts()
    }
  }, [companyId])

  const handleClassifyAll = useCallback(async () => {
    try {
      setIsLoading(true)
      const usecaseResult = await props.classifyAccountingEntriesUseCase.handle(
        {
          data: {
            companyId: companyId as string,
            bankAccountId: undefined,
            entryId: selectedGridIds ?? undefined,
            reclassify: false,
          },
        },
      )

      await handleRefetch(accountsSelected)
      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
        )
      }
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
      )
    }
  }, [accountsSelected])

  const bigNumbersNames = [
    {
      name: 'Saldo do extrato',
    },
    {
      name: 'Saldo dos lançamentos',
    },
    {
      name: 'Variação de caixa',
    },
    {
      name: 'Total de crédito',
    },
    {
      name: 'Total de débito',
    },
  ]

  useEffect(() => {
    const getResults = async () => {
      await handleRefetch(accountsSelected)
      HideModal()
    }
    getResults()
    if (accountsSelected?.length > 0) {
      setShowResults(true)
    } else {
      setShowResults(false)
    }
  }, [accountsSelected, accountingEntriesAlreadyClassified, showDespised])

  useEffect(() => {
    if (ContainerRef.current) {
      setContainerWidth(ContainerRef.current.offsetWidth)
    }
  }, [])

  useEffect(() => {
    const updateWidth = () => {
      const sidebar = document.getElementById('sidebar-id')
      if (sidebar) {
        setContainerWidth(window.innerWidth - sidebar.offsetWidth)
      }
    }

    // Cria um ResizeObserver para observar o elemento com id "sidebar-id"
    const resizeObserver = new ResizeObserver(() => {
      updateWidth()
    })

    const sidebar = document.getElementById('sidebar-id')
    if (sidebar) {
      resizeObserver.observe(sidebar)
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const ExportOptions = useMemo(() => {
    const normalizeOptions = [
      { name: 'Bling', premium: false },
      { name: 'DBCorp', premium: true },
    ]?.map((option) => {
      return {
        label: option.name,
        value: option.name,
        component: (
          <Stack direction="row" alignItems="center" padding="0 20px">
            <Typography marginLeft="15px" fontSize={14} fontWeight={400}>
              {option.name}
            </Typography>
            {option.premium && (
              <Stack
                alignItems="center"
                justifyContent="center"
                bgcolor="#FCD24C"
                borderRadius="16px"
                padding="8px 16px"
                marginLeft="10px"
              >
                <Typography fontSize="12px" fontWeight={700} color="#1E3466">
                  Premium
                </Typography>
              </Stack>
            )}
          </Stack>
        ),
      }
    })

    return normalizeOptions
  }, [])

  const handleGenerateXLS = async () => {
    ShowToast('warning', 'Processo de geração de arquivo iniciado.')
    const columns = [
      { header: 'Data', dataKey: 'date' },
      { header: 'Descrição - Cliente / Fornecedor', dataKey: 'description' },
      { header: 'Valor', dataKey: 'value' },
      { header: 'Debito', dataKey: 'debit' },
      { header: 'Credito', dataKey: 'credit' },
      { header: 'Categoria Financeira', dataKey: 'category' },
      { header: 'Histórico', dataKey: 'historic' },
    ]

    const data: any[] = []

    setIsLoading(true)
    let pageNumber = 1
    let hasMoreItems = true
    const allEntries: IAccountingEntries[] = []
    while (hasMoreItems) {
      const usecaseResult = await props.listAccountingEntriesUseCase.handle({
        companyId: companyId as string,
        bankAccountId: accountsSelected,
        page: pageNumber,
        vendor: selectedVendors,
        dateStart: initialDate,
        dateEnd: finalDate,
        query: searchQuery,
        valueMaximum: maxValue,
        valueMinimum: minValue,
        credit: selectedCredits,
        debit: selectedDebits,
        accountingEntriesAlreadyClassified,
        showDespised,
      })

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
        )
        setIsLoading(false)
        return
      }

      const transfersData =
        usecaseResult.getValue() as IListAccountingEntriesResponseDTO

      const normalize: IAccountingEntries[] =
        transfersData?.companyAccountingEntries?.map((entry) => {
          let description = entry?.description ? `${entry?.description}` : ''
          const alreadyHasDescription = description.length > 0
          let partieName = ''

          if (
            entry.bankTransferPartie &&
            (entry.bankTransferPartie.name ||
              entry.bankTransferPartie.document ||
              entry.bankTransferPartie.type) &&
            !alreadyHasDescription
          ) {
            if (entry.bankTransferPartie.name) {
              description += ` ${entry.bankTransferPartie.name}`
              partieName = `${entry.bankTransferPartie.name} ${
                entry.bankTransferPartie.document || ''
              }`
            }
            if (![null, undefined, ''].includes(entry.bankTransferType)) {
              description += ` ${entry.bankTransferType}`
            }
            if (entry.bankTransferPartie.type) {
              description += ` ${
                entry.bankTransferPartie.type === 'PESSOA_NATURAL' ? 'PF' : 'PJ'
              }`
            }
            if (entry.bankTransferPartie.document) {
              const formattedDocument = entry.bankTransferPartie.document
              description += ` ${formattedDocument}`
            }
          } else if (!alreadyHasDescription) {
            if (![null, undefined, ''].includes(entry.bankTransferType)) {
              description += ` ${entry.bankTransferType}`
            }
            if (!entry.bankTransferType) {
              description += ` ${entry.description || entry.method}`
            }
          }
          const value = Number(entry.value)

          return {
            id: entry.id,
            date: format(new Date(entry.date), 'dd/MM/yyyy'),
            bankTransferDate: format(
              new Date(entry.bankTransferDate),
              'dd/MM/yyyy',
            ),
            description,
            supplier: partieName,
            value: `${entry.method === BankAccountTransferMethodEnum.DEBITO ? '-' : ''} ${value?.toString()?.replace('.', ',')}`,
            debit: entry.debit,
            credit: entry.credit,
            historic: entry.historic,
            financialCategory: entry.financialCategory,
            status: entry.status,
            conciliatedEntries: entry.conciliatedEntries,
            origin: entry.origin,
            classificationRuleApplied: entry.classificationRuleApplied,
          }
        }) || []

      allEntries.push(...normalize)
      pageNumber += 1
      if (pageNumber > transfersData.pages) {
        hasMoreItems = false
      }
    }

    await Promise.all(
      allEntries.map((item) => {
        const date = item.bankTransferDate
        const value = item.value
        const description = item.description
        const category = item.financialCategory
        const debit = item.debit
        const credit = item.credit

        let historic = ''
        if (item?.historic) {
          if (item.historic.includes('data')) {
            historic += `${date}`
          }

          if (item.historic.includes('descricao')) {
            historic += ` - ${description}`
          }

          if (item.historic.includes('valor')) {
            historic += ` - ${value}`
          }

          if (item.historic.includes('credito')) {
            historic += ` - ${credit}`
          }

          if (item.historic.includes('debito')) {
            historic += ` - ${debit}`
          }
        }

        data.push([date, description, value, debit, credit, category, historic])
        return item
      }),
    )

    const sheets = [
      {
        sheetName: 'Relatório Classificador',
        columns: columns.map((column) => column.header),
        data,
      },
    ]

    setIsLoading(false)

    await generateXLS(
      sheets,
      `Relatorio-Classificador-${format(new Date(), 'dd-MM-yyyy')}.xls`,
    )
  }

  const handleExport = (type: string) => {
    if (type === 'excel') {
      setTimeout(() => {
        ShowModal({
          content: (
            <ModalClassifierPendency handle={() => handleGenerateXLS()} />
          ),
          title: 'Exportar Classificação',
        })
      }, 100)
    }

    if (type === 'erp') {
      ShowToast('warning', 'Função não implementada.')
    }
  }

  const handleReturnBigNumber = (name: string) => {
    switch (name) {
      case 'Saldo do extrato':
        return informationsBankBalance?.bankBalance
      case 'Saldo dos lançamentos':
        return informationsCashflow?.bankCashflow?.balance
      case 'Variação de caixa':
        return informationsCashflow?.bankCashflow?.total
      case 'Total de crédito':
        return informationsCashflow?.bankCashflow?.inflow
      case 'Total de débito':
        return informationsCashflow?.bankCashflow?.outflow

      default:
        return '0'
    }
  }

  const handleRecoverAll = useCallback(async () => {
    if (selectedGridIds.length <= 0) {
      return
    }

    await Promise.all(
      selectedGridIds.map(async (item) => {
        const itemEntry = accountingEntries.find((f) => f.id === item)

        if (itemEntry) {
          await handleRecover(itemEntry, accountsSelected)
        }

        return item
      }),
    )

    setSelectedGridIds([])
  }, [accountsSelected, selectedGridIds])

  const handleDespiesell = useCallback(async () => {
    if (selectedGridIds.length <= 0) {
      return
    }

    await Promise.all(
      selectedGridIds.map(async (item) => {
        const itemEntry = accountingEntries.find((f) => f.id === item)

        if (itemEntry) {
          await handleDespise(itemEntry, accountsSelected)
        }

        return item
      }),
    )
    setSelectedGridIds([])
  }, [accountsSelected, selectedGridIds])

  const handleSetAsClassified = useCallback(async () => {
    if (selectedGridIds.length <= 0) {
      return
    }

    await Promise.all(
      selectedGridIds.map(async (item) => {
        const itemEntry = accountingEntries.find((f) => f.id === item)

        if (itemEntry) {
          await handleUpdateAsClassified(itemEntry, accountsSelected)
        }

        return item
      }),
    )
    setSelectedGridIds([])
    await handleRefetch(accountsSelected)
  }, [accountsSelected, selectedGridIds])

  const selectedItemActions = [
    {
      title: 'Desprezar',
      action: () => handleDespiesell(),
    },
    {
      title: 'Recuperar',
      action: () => handleRecoverAll(),
    },
    {
      title: 'Marcar como classificado',
      action: () => handleSetAsClassified(),
    },
  ]

  const handleUpdateAccountingEntry = useCallback(
    async ({
      entryId,
      oldValue,
      newValue,
      field,
    }: {
      entryId: string
      oldValue: string
      newValue: string
      field: string
    }) => {
      try {
        const accounts = accountsSelected
        const normalizeNewValue = newValue === '' ? null : newValue
        if (oldValue === normalizeNewValue) return

        const usecaseResult = await props.updateAccountingEntriesUseCase.handle(
          {
            companyId: companyId as string,
            entryId,
            field,
            value: newValue,
          },
        )

        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao atualizar os dados do classificador. Tente novamente mais tarde.',
          )
        }
        setAccountingEntries((prevItems) =>
          prevItems.map((currentItem) =>
            currentItem.id === entryId
              ? {
                  ...currentItem,
                  [field]: newValue,
                  classificationRuleApplied: new Date(),
                }
              : currentItem,
          ),
        )
        setCurrentPage(1)
        handleListAccountingEntriesBalance(
          1,
          accounts,
          selectedVendors,
          initialDate,
          finalDate,
          searchQuery,
          maxValue,
          minValue,
          selectedCredits,
          selectedDebits,
          selectedCC,
          accountingEntriesAlreadyClassified,
          showDespised,
        )
        handleListAccountingEntriesBankBalance(
          1,
          accounts,
          selectedVendors,
          initialDate,
          finalDate,
          searchQuery,
          maxValue,
          minValue,
          selectedCredits,
          selectedDebits,
          accountingEntriesAlreadyClassified,
          showDespised,
        )
        handleListAccountingEntriesCashflow(
          1,
          accounts,
          selectedVendors,
          initialDate,
          finalDate,
          searchQuery,
          maxValue,
          minValue,
          selectedCredits,
          selectedDebits,
          selectedCC,
          accountingEntriesAlreadyClassified,
          showDespised,
        )
      } catch (error) {
        ShowToast('error', 'Ocorreu um erro ao buscar atualizar os dados.')
      }
    },
    [
      accountsSelected,
      selectedVendors,
      initialDate,
      finalDate,
      searchQuery,
      maxValue,
      minValue,
      selectedCredits,
      selectedDebits,
      accountingEntriesAlreadyClassified,
      showDespised,
    ],
  )

  const handleClassifyManual = useCallback(
    async (item: IAccountingEntries) => {
      try {
        setIsLoading(true)
        const usecaseResult =
          await props.classifyAccountingEntriesUseCase.handle({
            data: {
              companyId: companyId as string,
              bankAccountId: undefined,
              entryId: [item.id],
              reclassify: false,
            },
          })

        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
          )
        }
        await handleRefetch(accountsSelected)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        ShowToast(
          'error',
          'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
        )
      }
    },
    [accountsSelected],
  )

  const handleDespise = useCallback(
    async (item: IAccountingEntries, accounts: string[]) => {
      setIsLoading(true)
      try {
        await props.updateAccountingEntriesUseCase.handle({
          companyId: companyId as string,
          entryId: item.id,
          field: 'status',
          value: CompanyAccountEntryConciliationStatusEnum.DESPISED,
        })
        await handleRefetch(accounts)
        setIsLoading(false)
      } catch (error) {
        ShowToast(
          'error',
          'Ocorreu ao desprezar o(s) registro(s). Tente novamente mais tarde.',
        )
        setIsLoading(false)
      }
    },
    [],
  )

  const handleUpdateAsClassified = useCallback(
    async (item: IAccountingEntries, accounts: string[]) => {
      setIsLoading(true)
      try {
        await props.updateAccountingEntriesUseCase.handle({
          companyId: companyId as string,
          entryId: item.id,
          field: 'forceClassifyStatus',
          value: 'true',
        })
        setIsLoading(false)
      } catch (error) {
        ShowToast(
          'error',
          'Ocorreu ao marcar como classificado. Tente novamente mais tarde.',
        )
        setIsLoading(false)
      }
    },
    [],
  )

  const handleDismember = useCallback(
    async (
      data: { value: string; partieName: string; description: string }[],
      entryId: string,
    ) => {
      setIsLoading(true)

      try {
        await props.accountingEntryDismemberUseCase.handle({
          companyId: companyId as string,
          entryId,
          data,
        })
        await handleRefetch(accountsSelected)
        HideModal()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    },
    [accountsSelected],
  )

  const handleDismemberModal = async (item: IAccountingEntries) => {
    if (
      item.status === CompanyAccountEntryConciliationStatusEnum.CONCILIATED ||
      item.status ===
        CompanyAccountEntryConciliationStatusEnum.CONCILIATED_PARTIAL
    ) {
      return ShowToast(
        'error',
        'Registros já conciliados total ou parcialmente não podem ser desmembrados.',
      )
    }
    if (item.status === CompanyAccountEntryConciliationStatusEnum.DESPISED) {
      return ShowToast(
        'error',
        'Registros deprezados não podem ser desmembrados.',
      )
    }
    return ShowModal({
      content: (
        <DismemberEntryModal
          handleCancelAction={() => HideModal()}
          handleConfirmAction={handleDismember}
          data={item}
        />
      ),
      title: '',
    })
  }

  const handleCreateClassificationRule = (item: IAccountingEntries) => {
    if (!item.classificationRuleApplied) {
      return ShowToast(
        'error',
        'Registros ainda não classificados não podem ser tranformados em regra.',
      )
    }
    const predefinedRules: ICompanyClassificationRuleConditionals[] = [
      {
        condition: CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
        variable: CompanyClassificationRuleConditionalsVariableEnum.VALUE,
        data: {
          value: {
            value: `${item.value}`,
          },
          date: undefined,
          thirdParty: undefined,
          description: undefined,
        },
      },
    ]

    if (item.description) {
      predefinedRules.push({
        condition: CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
        variable: CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION,
        data: {
          value: undefined,
          date: undefined,
          thirdParty: undefined,
          description: {
            description: item.description,
          },
        },
      })
    }

    if (item?.partieDocument !== '' || item?.partieName !== '') {
      predefinedRules.push({
        condition: CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
        variable: CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY,
        data: {
          value: undefined,
          date: undefined,
          thirdParty: {
            name: item?.partieName,
            documentNumber: item?.partieDocument,
          },
          description: undefined,
        },
      })
    }

    ShowModal({
      content: (
        <ClassificationRuleModal
          companyId={companyId as string}
          createClassificationRuleUseCase={
            props.createClassificationRuleUseCase
          }
          defaultCredit={item?.credit}
          defaultDebit={item?.debit}
          defaultFinancialCategory={item?.financialCategory}
          defaultHistoric={item?.historic?.split('|')}
          defaultConditionals={predefinedRules}
        />
      ),
      title: 'Regras de classificação',
      closeOnBackgroundClick: false,
    })
  }

  const handleEdit = async (item: TCompanyAccountEntryCreate) => {
    setIsLoading(true)

    try {
      await props.updateAccountingEntriesUseCase.handle({
        companyId: companyId as string,
        entryId: item.id as string,
        multipleFields: [
          {
            field: 'value',
            value: item.value as string,
          },
          {
            field: 'date',
            value: item.date as string,
          },
          {
            field: 'credit',
            value: item.credit as string,
          },
          {
            field: 'debit',
            value: item.debit as string,
          },
          {
            field: 'financialCategory',
            value: item.financialCategory as string,
          },
          {
            field: 'historic',
            value: item.historic as string,
          },
        ],
      })
      setAccountingEntries((prevItems) =>
        prevItems.map((currentItem) =>
          currentItem.id === (item.id as string)
            ? {
                ...currentItem,
                ...item,
                method: item.method as BankAccountTransferMethodEnum,
                value: formatMoney(Number(item.value)),
              }
            : currentItem,
        ),
      )
      HideModal()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleEditEntry = async (item: TCompanyAccountEntryCreate) => {
    return ShowModal({
      content: (
        <ModalClassifierEditEntry
          handle={handleEdit}
          companyId={companyId as string}
          data={item}
        />
      ),
      title: 'Editar lançamento',
    })
  }

  const handleRecover = useCallback(
    async (item: IAccountingEntries, accounts: string[]) => {
      setIsLoading(true)

      try {
        await props.updateAccountingEntriesUseCase.handle({
          companyId: companyId as string,
          entryId: item.id,
          field: 'status',
          value: CompanyAccountEntryConciliationStatusEnum.NOT_CONCILIATED,
        })
        await handleRefetch(accounts)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    },
    [],
  )

  const columns = useMemo(() => {
    const order = [
      'Data',
      'Descrição - Clientes e fornecedor',
      'Valor',
      'Débito',
      'Crédito',
      'Categoria financeira',
      'Histórico',
      'Status',
      'Ações',
    ]
    const makeGrid: GridColDef[] = [
      {
        ...ColumnsCommon,
        flex: 0.5,
        ...{
          field: 'Ações',
          headerName: 'Ações',
          minWidth: 100,
          align: 'left',
          renderCell: ({ row }) => (
            <CascadeButton
              variant="secondary"
              direction="right"
              options={[
                {
                  label: 'Editar',
                  onClick: () => handleEditEntry(row),
                },
                {
                  label: 'Aplicar regra',
                  onClick: () => handleClassifyManual(row),
                },
                {
                  label:
                    row.status ===
                    CompanyAccountEntryConciliationStatusEnum.DESPISED
                      ? 'Recuperar'
                      : 'Desprezar',
                  onClick: () =>
                    row.status ===
                    CompanyAccountEntryConciliationStatusEnum.DESPISED
                      ? handleRecover(row, accountsSelected)
                      : handleDespise(row, accountsSelected),
                },
                {
                  label: 'Desmembrar',
                  onClick: () => handleDismemberModal(row),
                },
                {
                  label: 'Transformar em regra',
                  onClick: () => handleCreateClassificationRule(row),
                },
              ]}
              iconButton
            />
          ),
        },
      },
    ]

    if (columnsTableSelected.includes('status')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        align: 'center',
        flex: 0.5,
        ...{
          minWidth: 200,
          field: 'Status',
          headerAlign: 'center',
          headerName: 'Status',
        },
        renderCell: (params) =>
          `${params.row.classificationRuleApplied ? 'Classificado' : 'Não classificado'}`,
      })
    }

    if (columnsTableSelected.includes('financialCategory')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        align: 'center',
        flex: 0.6,
        ...{
          minWidth: 250,
          field: 'Categoria financeira',
          headerName: 'Categoria financeira',
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={String(companyId)}
              options={finalcialCategories}
              variant="credits"
              error=""
              label=""
              setState={(e) =>
                handleUpdateAccountingEntry({
                  entryId: row.id,
                  field: 'financialCategory',
                  newValue: e,
                  oldValue: row.financialCategory,
                })
              }
              value={row.financialCategory}
              inputVariant="bottomBordered"
            />
          </Stack>
        ),
      })
    }

    if (columnsTableSelected.includes('historic')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'center',
        minWidth: 250,
        ...{
          field: 'Histórico',
          headerName: 'Histórico',
          headerAlign: 'center',
        },
        renderCell: ({ row }) => (
          <Tooltip
            title={row?.historic ? row.historic.split('|').join(', ') : ''}
            arrow
            sx={{
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#777C84',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19.07px',
                color: 'white',
              },
            }}
            key={row?.id}
          >
            <Stack width="100%">
              <BaseDropDownInput
                options={HistoricVariables}
                error=""
                setState={(e) =>
                  handleUpdateAccountingEntry({
                    entryId: row.id,
                    field: 'historic',
                    newValue: e?.join('|'),
                    oldValue: row.historic,
                  })
                }
                value={row?.historic?.split('|')}
              />
            </Stack>
          </Tooltip>
        ),
      })
    }

    if (columnsTableSelected.includes('date')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.5,
        ...{
          field: 'Data',
          minWidth: 130,
          headerName: 'Data',
        },
        renderCell: (params) => `${params.row.bankTransferDate || ''}`,
      })
    }

    if (columnsTableSelected.includes('description')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 1,
        align: 'left',
        ...{
          field: 'Descrição - Clientes e fornecedor',
          minWidth: 350,
          headerName: 'Descrição - Clientes e fornecedor',
        },
        renderCell: (params) => (
          <Tooltip
            title={
              params.row.description
                ? params.row.description.split('|').join(', ')
                : ''
            }
            arrow
            sx={{
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#777C84',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19.07px',
                color: 'white',
              },
            }}
            key={params.row?.id}
          >
            <Stack
              direction="row"
              gap="12px"
              width="100%"
              height="100%"
              alignItems="center"
              alignContent="center"
              alignSelf="center"
            >
              <Typography>{params.row.description}</Typography>
              {params.row?.dismemberOrigin && (
                <BaseTooltip
                  message={params.row?.dismemberOrigin}
                  key={params.row?.id}
                />
              )}
            </Stack>
          </Tooltip>
        ),
      })
    }

    if (columnsTableSelected.includes('value')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Valor',
          minWidth: 150,
          headerName: 'Valor',
        },
        renderCell: (params) => `${params.row.value}`,
      })
    }

    if (columnsTableSelected.includes('debit')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Débito',
          headerName: 'Débito',
          minWidth: 150,
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={String(companyId)}
              options={debits}
              variant="debits"
              error=""
              label=""
              setState={(e) =>
                handleUpdateAccountingEntry({
                  entryId: row.id,
                  field: 'debit',
                  newValue: e,
                  oldValue: row.debit,
                })
              }
              value={row.debit}
              inputVariant="bottomBordered"
            />
          </Stack>
        ),
      })
    }

    if (columnsTableSelected.includes('credit')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Crédito',
          headerName: 'Crédito',
          minWidth: 150,
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={String(companyId)}
              options={credits}
              variant="credits"
              error=""
              label=""
              setState={(e) =>
                handleUpdateAccountingEntry({
                  entryId: row.id,
                  field: 'credit',
                  newValue: e,
                  oldValue: row.credit,
                })
              }
              value={row.credit}
              inputVariant="bottomBordered"
            />
          </Stack>
        ),
      })
    }

    return order
      .map((key) => makeGrid.find((column) => column.field === key))
      .filter((column): column is GridColDef => column !== undefined)
  }, [vendorsOptions, columnsTableSelected, accountsSelected])

  const appliedFilters = useMemo(() => {
    let response = 'Não existem filtros aplicados.'

    if (
      ![null, undefined, ''].includes(minValue) ||
      ![null, undefined, ''].includes(maxValue) ||
      initialDate ||
      finalDate ||
      (selectedCredits && selectedCredits?.length > 0) ||
      (selectedDebits && selectedDebits?.length > 0) ||
      (selectedVendors && selectedVendors?.length > 0) ||
      ![null, undefined, ''].includes(searchQuery)
    ) {
      response = 'Existem filtros aplicados'
    }
    return response
  }, [
    minValue,
    maxValue,
    initialDate,
    finalDate,
    selectedCredits,
    selectedDebits,
    selectedVendors,
    searchQuery,
  ])

  const handleCreateMass = useCallback(
    async (data: TCompanyAccountEntryCreate[]) => {
      setIsLoading(true)

      let totalRegister = 0
      let totalSuccess = 0
      let totalError = 0

      await Promise.all(
        data.map(async (itemData) => {
          totalRegister += 1
          try {
            const usecaseResult =
              await props.createManualAccountingEntriesUseCase.handle({
                data: [itemData],
                companyId: companyId as string,
              })

            if (usecaseResult.isFailure) {
              ShowToast(
                'error',
                'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
              )
            }
            totalSuccess += 1
            return itemData
          } catch (e) {
            return (totalError += 1)
          }
        }),
      )

      if (accountsSelected) {
        await handleRefetch(accountsSelected)
      }
      HideModal()
      setIsLoading(false)
    },
    [accountsSelected],
  )

  const handleUploadFile = async (file: File) => {
    try {
      const companiesFromFile =
        await props.createCompanyAccountingEntriesImportFileUseCase.handle(file)

      if (companiesFromFile.isFailure) {
        return ShowToast(
          'error',
          'Ocorreu um erro ao processar o arquivo. Tente novamente mais tarde.',
        )
      }

      const companiesData = companiesFromFile.getValue()

      if (companiesData && companiesData?.length > 0) {
        HideModal()
        return ShowModal({
          content: (
            <CompanyAccountingEntriesCreateMassModal
              handle={handleCreateMass}
              data={companiesData}
              bankAccountOptions={bankAccountOptions}
            />
          ),
          title: 'Lançamentos via planilha - Conferência de dados',
        })
      }

      HideModal()
      ShowToast(
        'warning',
        'Nenhum registro foi processano para o arquivo importado.',
      )
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao processar o arquivo. Tente novamente mais tarde.',
      )
    }
  }

  const handleGetLedgerAccounts = async () => {
    try {
      const ledgerAccountResult = await props.listLedgerAccountsUseCase.handle({
        companyId: companyId as string,
        query: '',
      })

      if (ledgerAccountResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar as contas contábeis. Tente novamente mais tarde.',
        )
        return
      }

      const ledgerAccountData = ledgerAccountResult.getValue()

      setCreditAccounts(ledgerAccountData?.credits ?? [])
      setDebitAccounts(ledgerAccountData?.debits ?? [])
      setFinancialCategories(ledgerAccountData?.financialCategories ?? [])
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as contas contábeis. Tente novamente mais tarde.',
      )
      console.error(error)
    }
  }

  useEffect(() => {
    handleGetLedgerAccounts()
  }, [])

  return (
    <Stack
      sx={{
        backgroundColor: '#F4F8FA',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        overflowX: 'hidden',
        flex: 1,
        flexDirection: 'column',
      }}
      ref={ContainerRef}
    >
      <DefaultHeader
        breadcumbItems={[{ title: 'Home' }, { title: 'Classificador' }]}
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        buttons={
          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '24px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <NotificationBellIcon />
            </IconButton>
          </Stack>
        }
      />
      <Stack
        width="100%"
        direction="row"
        gap="24px"
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Stack
          width="100%"
          padding="32px"
          gap="24px"
          sx={{
            flex: 1,
            overflowY: 'auto',
          }}
        >
          <BaseInternalHeader
            leftComponent={
              <BasePageTitle color="#1E3466" text="Classificador" />
            }
          />
          <Stack
            direction="row"
            justifyContent={!useTwoLinesHeader ? 'space-between' : 'flex-start'}
          >
            <Stack direction="row" width="351px">
              <Stack flexDirection="row" alignItems="flex-end" gap="18px">
                <Stack width="280px">
                  <BaseMultipleSelectInput
                    label="Conta bancária"
                    labelBackground="transparent"
                    options={[
                      ...bankAccountOptions,
                      {
                        label: 'Sem Conta',
                        value: 'null',
                        icon: (
                          <Logo
                            name={extractBankName('Sem Conta')}
                            width={32}
                            height={32}
                            logo={undefined}
                          />
                        ),
                      },
                    ]}
                    fullWidth
                    error=""
                    value={accountsSelected}
                    setState={(e) => setAccountsSelected(e)}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              gap={!useTwoLinesHeader ? '18px' : '12px'}
            >
              <Button
                variant="outlined"
                sx={{ minWidth: '123px', height: '40px' }}
                onClick={() =>
                  ShowModal({
                    content: (
                      <ModalClassifierExport
                        handle={(e) => handleExport(e)}
                        options={ExportOptions}
                      />
                    ),
                    title: 'Exportar',
                  })
                }
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="16.41px"
                >
                  Exportar
                </Typography>
              </Button>

              <Box width={120}>
                <Tooltip title={appliedFilters}>
                  <FilterButton variant="outlined" onClick={handleOpenFilter}>
                    Filtrar
                  </FilterButton>
                </Tooltip>
              </Box>

              <Button
                variant="contained"
                sx={{ minWidth: '123px', height: '40px' }}
                onClick={() => handleClassifyAll()}
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="16.41px"
                >
                  Aplicar regras
                </Typography>
              </Button>

              <CascadeButton
                variant="primary"
                direction="left"
                options={[
                  {
                    label: 'Criar lançamento',
                    onClick: () => console.log('go to my account'),
                    subItens: [
                      {
                        label: 'Realizada',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ModalClassifierPaymentEntry
                                accountsOptions={bankAccountOptions}
                                handle={handleCreateNewEntries}
                                type="made"
                                companyId={companyId as string}
                                creditAccounts={creditAccounts}
                                debitAccounts={debitAccounts}
                                financialCategories={financialCategories}
                              />
                            ),
                            title: 'Criar lançamento',
                          }),
                        type: 'single',
                      },
                      {
                        label: 'Provisão',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ModalClassifierPaymentEntry
                                accountsOptions={bankAccountOptions}
                                handle={handleCreateNewEntries}
                                type="provision"
                                companyId={companyId as string}
                                creditAccounts={creditAccounts}
                                debitAccounts={debitAccounts}
                                financialCategories={financialCategories}
                              />
                            ),
                            title: 'Criar lançamento',
                          }),
                        type: 'single',
                      },
                      {
                        label: 'Transferência',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ModalClassifierPaymentEntry
                                accountsOptions={bankAccountOptions}
                                handle={handleCreateNewEntries}
                                type="transfer"
                                companyId={companyId as string}
                                creditAccounts={creditAccounts}
                                debitAccounts={debitAccounts}
                                financialCategories={financialCategories}
                              />
                            ),
                            title: 'Criar lançamento',
                          }),
                        type: 'single',
                      },
                      {
                        label: 'Via planilha',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ImportFileModal
                                showInstructions={true}
                                instructions={[
                                  '• Datas devem estar no seguinte formato DD/MM/AAAA',
                                  '• Caso uma conta contábil/bancária não seja encontrada na plataforma, ela não será criada automaticamente',
                                  '• As células não devem conter fórmulas',
                                  '• Valores devem respeitar a pontuação correta',
                                  '• , representa centavos',
                                  '• Não utilizar . para representar mil',
                                  '• Colulas * representam obrigatoriedade de preenchimento. Caso não sejam preenchidas a linha inteira será desconsiderada',
                                  '• CPF/CNPJ não devem conter pontuações nem caracteres especiais',
                                  '• Em valor, o sinal - [menos] deve representar saídas',
                                ]}
                                handle={handleUploadFile}
                                allowedExtensions={['csv']}
                                template={{
                                  url: '/csv-example/mass-classifier.csv',
                                  filename: 'mass-classifier.csv',
                                }}
                              />
                            ),
                            title: 'Lançamentos via planilha',
                            closeButton: false,
                          }),
                        type: 'single',
                      },
                    ],
                  },
                  {
                    label: 'Colunas',
                    onClick: () => console.log('press me'),
                    subItensSelected: columnsTableSelected,
                    subItens: [
                      {
                        label: 'Data',
                        onClick: () => handleSelectColumns('date'),
                        type: 'multi',
                        default: 'checked',
                        value: 'date',
                      },
                      {
                        label: 'Descrição - Cliente Fornecedor',
                        onClick: () => handleSelectColumns('description'),
                        type: 'multi',
                        value: 'description',
                      },
                      {
                        label: 'Valor',
                        onClick: () => handleSelectColumns('value'),
                        type: 'multi',
                        value: 'value',
                      },
                      {
                        label: 'Débito',
                        onClick: () => handleSelectColumns('debit'),
                        type: 'multi',
                        value: 'debit',
                      },
                      {
                        label: 'Crédito',
                        onClick: () => handleSelectColumns('credit'),
                        type: 'multi',
                        value: 'credit',
                      },
                      {
                        label: 'Cat. Financeira',
                        onClick: () => handleSelectColumns('financialCategory'),
                        type: 'multi',
                        value: 'financialCategory',
                      },
                      {
                        label: 'Histórico',
                        onClick: () => handleSelectColumns('historic'),
                        type: 'multi',
                        value: 'historic',
                      },
                    ],
                  },
                ]}
                iconButton
              />
            </Stack>
          </Stack>
          {!accountsSelected?.length && (
            <Stack alignItems="center" justifyContent="center">
              <Typography fontWeight={400} fontSize="16px" marginTop="50px">
                Selecione uma ou mais contas bancárias para visualizar os
                lançamentos
              </Typography>
            </Stack>
          )}
          {showResults && (
            <Stack flex={1} width="100%" overflow="hidden">
              <Stack
                direction="row"
                bgcolor="#FFFFFF"
                borderRadius="16px"
                width="100%"
              >
                <Stack
                  width="100%"
                  direction="row"
                  padding={isSmallScreen ? '16px' : '24px'}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {(isLoadingBigNumbersCashflow ||
                    isLoadingBigNumbersBalance ||
                    isLoading) && (
                    <>
                      <Stack
                        width="100%"
                        height="56px"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                      </Stack>
                    </>
                  )}
                  {!isLoadingBigNumbersCashflow &&
                    !isLoadingBigNumbersBalance &&
                    !isLoading &&
                    bigNumbersNames?.map((item) => (
                      <Box key={item.name}>
                        <Typography
                          color="#777C84"
                          fontSize="14px"
                          fontWeight={600}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          color="#1E3466"
                          fontSize={isSmallScreen ? '16px' : '24px'}
                          fontWeight={700}
                        >
                          {formatMoney(
                            Number(handleReturnBigNumber(item.name)),
                          )}
                        </Typography>
                      </Box>
                    ))}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                gap="16px"
                marginTop="12px"
              >
                <Box marginRight={isSmallScreen ? '16px' : '24px'}>
                  <Button
                    variant="outlined"
                    disabled={selectedGridIds.length <= 0}
                    onClick={handleClick}
                  >
                    Ações nos selecionados
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{
                      boxShadow: '0px 4px 4px 0px #00000040',
                      marginTop: '8px',
                    }}
                  >
                    {selectedItemActions.map((selectedItem, index) => {
                      return (
                        <MenuItem
                          key={index}
                          sx={{
                            width: 'auto',
                            minWidth: '197px',
                          }}
                          onClick={() => {
                            handleClose()
                            selectedItem.action && selectedItem.action()
                          }}
                        >
                          {selectedItem.title}
                        </MenuItem>
                      )
                    })}
                  </Menu>
                </Box>
                <Stack direction="row" gap="12px" alignItems="center">
                  <Checkbox
                    sx={{
                      color: '#4865CC',
                      '&.Mui-checked': {
                        color: '#4865CC',
                      },
                    }}
                    checked={accountingEntriesAlreadyClassified === 'true'}
                    onChange={() => {
                      setAccountingEntriesAlreadyClassified(
                        accountingEntriesAlreadyClassified === 'true'
                          ? undefined
                          : 'true',
                      )
                    }}
                  />
                  <ListItemText
                    primary={
                      isSmallScreen
                        ? 'Apenas classificados'
                        : 'Mostrar apenas lançamentos classificados'
                    }
                  />
                </Stack>
                <Stack direction="row" gap="12px" alignItems="center">
                  <Checkbox
                    sx={{
                      color: '#4865CC',
                      '&.Mui-checked': {
                        color: '#4865CC',
                      },
                    }}
                    checked={showDespised === 'true'}
                    onChange={() => {
                      setshowDespised(
                        showDespised === 'true' ? undefined : 'true',
                      )
                    }}
                  />
                  <ListItemText
                    primary={
                      isSmallScreen
                        ? 'Mostrar desprezados'
                        : 'Mostrar lançamentos desprezados'
                    }
                  />
                </Stack>
              </Stack>
              {isLoading && (
                <Stack
                  width="100%"
                  height="200px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Stack>
              )}
              {!isLoading && (
                <Table
                  columns={columns}
                  rows={accountingEntries}
                  loadMore={(valuePage) => {
                    return handleLoadMore(valuePage)
                  }}
                  tableStyle={{
                    flex: 1,
                  }}
                  tableContainerStyle={{
                    maxHeight: '650px',
                    flex: 1,
                    width: `${containerWidth - 48}px`,
                    overflowY: 'auto',
                    overflowX: 'auto',
                  }}
                  disabledStatuses={[
                    CompanyAccountEntryConciliationStatusEnum.DESPISED,
                  ]}
                  totalPages={totalPages}
                  totalRegisters={totalRegisters}
                  currentPage={currentPage}
                  allowSelectAll={true}
                  loading={false}
                  showFilterButton={true}
                  showSearchButton={true}
                  setSelectedGridIds={setSelectedGridIds}
                  showNoResultsOverlay={false}
                />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
