import { Button, ButtonProps } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'

export type BaseButtonFilterProps = {
  variant?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
} & ButtonProps
export const FilterButton = ({
  onClick,
  variant = 'contained',
  ...props
}: BaseButtonFilterProps) => {
  return (
    <Button
      variant={variant}
      onClick={onClick && onClick}
      sx={{ height: '40px' }}
      startIcon={<TuneIcon />}
      {...props}
    >
      Filtrar
    </Button>
  )
}
