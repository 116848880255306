import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import SearchIcon from '@mui/icons-material/Search'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BaseDateSelect } from 'src/component/base-component/base-date-select'
import { endOfMonth, startOfMonth, subMonths } from 'date-fns'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'

export const ClassifierFilterModal: React.FC<{
  clientOptions: {
    label: string
    value: string
  }[]
  creditOptions: {
    label: string
    value: string
  }[]
  debitOptions: {
    label: string
    value: string
  }[]
  accountingAccountOptions: {
    label: string
    value: string
  }[]
  selectedClients: string[] | undefined
  setSelectedClients: React.Dispatch<React.SetStateAction<string[] | undefined>>
  selectedDebits: string[] | undefined
  setSelectedDebits: React.Dispatch<React.SetStateAction<string[] | undefined>>
  selectedCredits: string[] | undefined
  setSelectedCredits: React.Dispatch<React.SetStateAction<string[] | undefined>>
  selectedCC: string[] | undefined
  setSelectedCC: React.Dispatch<React.SetStateAction<string[] | undefined>>
  searchQuery: string
  setSearchQuery: (value: React.SetStateAction<string>) => void
  initialDate: Date | undefined
  finalDate: Date | undefined
  minValue: string
  maxValue: string
  setMinValue: (value: React.SetStateAction<string>) => void
  setMaxValue: (value: React.SetStateAction<string>) => void
  setInitialDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  setFinalDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  handleApply: (
    initialDate: Date | undefined,
    finalDate: Date | undefined,
    minValue: string,
    maxValue: string,
    selectedClients: string[] | undefined,
    selectedDebits: string[] | undefined,
    selectedCredits: string[] | undefined,
    currentSelectedCC: string[] | undefined,
    searchQuery: string | undefined,
  ) => Promise<void>
}> = ({
  clientOptions,
  creditOptions,
  accountingAccountOptions,
  debitOptions,
  finalDate,
  initialDate,
  maxValue,
  minValue,
  searchQuery,
  selectedClients,
  selectedCredits,
  selectedDebits,
  selectedCC,
  setMaxValue,
  setMinValue,
  setSearchQuery,
  setSelectedClients,
  setSelectedCredits,
  setSelectedDebits,
  setSelectedCC,
  setInitialDate,
  setFinalDate,
  handleApply,
}) => {
  const { HideModal } = ModalFullContext()
  const [expanded, setExpanded] = useState<string | false>(false)
  const [internalControlSearchClients, setInternalControlSearchClients] =
    useState<string>('')
  const [internalControlSearchDebits, setInternalControlSearchDebits] =
    useState<string>('')
  const [internalControlSearchCredits, setInternalControlSearchCredits] =
    useState<string>('')
  const [internalControlSearchCC, setInternalControlSearchCC] =
    useState<string>('')
  const [internalControlSelectedClients, setInternalControlSelectedClients] =
    useState<string[] | undefined>(selectedClients ?? [])
  const [internalControlInitialDate, setInternalControlInitialDate] = useState<
    Date | undefined
  >(initialDate)
  const [internalControlFinalDate, setInternalControlFinalDate] = useState<
    Date | undefined
  >(finalDate)
  const [internalControlMinValue, setInternalControlMinValue] =
    useState<string>(minValue)
  const [internalControlMaxValue, setInternalControlMaxValue] =
    useState<string>(maxValue)
  const [internalControlSelectedDebits, setInternalControlSelectedDebits] =
    useState<string[] | undefined>(selectedDebits ?? [])
  const [internalControlSelectedCredits, setInternalControlSelectedCredits] =
    useState<string[] | undefined>(selectedCredits ?? [])
  const [internalControlSelectedCC, setInternalControlSelectedCC] = useState<
    string[] | undefined
  >(selectedCC ?? [])
  const [internalControlSearchQuery, setInternalControlSearchQuery] =
    useState<string>(searchQuery)

  const handleExecute = async () => {
    await handleApply(
      internalControlInitialDate,
      internalControlFinalDate,
      internalControlMinValue,
      internalControlMaxValue,
      internalControlSelectedClients,
      internalControlSelectedCredits,
      internalControlSelectedDebits,
      internalControlSelectedCC,
      internalControlSearchQuery,
    )
  }

  const handleClear = async () => {
    setInternalControlSelectedClients([])
    setSelectedClients([])
    setInternalControlSelectedCredits([])
    setSelectedCredits([])
    setInternalControlSelectedDebits([])
    setInternalControlMaxValue('')
    setMaxValue('')
    setInternalControlMinValue('')
    setMinValue('')
    setSelectedDebits([])
    setInternalControlSelectedCC([])
    setSelectedCC([])
    setInternalControlInitialDate(startOfMonth(subMonths(new Date(), 3)))
    setInternalControlFinalDate(endOfMonth(new Date()))
    setInitialDate(startOfMonth(subMonths(new Date(), 3)))
    setFinalDate(endOfMonth(new Date()))
    setInternalControlSearchQuery('')
    setSearchQuery('')
    await handleApply(
      startOfMonth(subMonths(new Date(), 3)),
      endOfMonth(new Date()),
      '',
      '',
      [],
      [],
      [],
      [],
      '',
    )
    HideModal()
  }

  const handleSelectAllClients = () => {
    if (internalControlSelectedClients?.length === 0) {
      const optionData = clientOptions?.map((item) => {
        return item.value
      })

      setSelectedClients && setSelectedClients(optionData)
      setInternalControlSelectedClients(optionData)
      return
    }

    setSelectedClients([])
    setInternalControlSelectedClients([])
  }

  const handleSelectAllDebits = () => {
    if (internalControlSelectedDebits?.length === 0) {
      const optionData = debitOptions?.map((item) => {
        return item.value
      })

      setSelectedDebits && setSelectedDebits(optionData)
      setInternalControlSelectedDebits(optionData)
      return
    }

    setSelectedDebits([])
    setInternalControlSelectedDebits([])
  }

  const handleSelectAllCredits = () => {
    if (internalControlSelectedCredits?.length === 0) {
      const optionData = creditOptions?.map((item) => {
        return item.value
      })

      setSelectedCredits && setSelectedCredits(optionData)
      setInternalControlSelectedCredits(optionData)
      return
    }

    setSelectedCredits([])
    setInternalControlSelectedCredits([])
  }

  const handleSelectAllCC = () => {
    if (internalControlSelectedCC?.length === 0) {
      const optionData = accountingAccountOptions?.map((item) => {
        return item.value
      })

      setSelectedCC && setSelectedCC(optionData)
      setInternalControlSelectedCC(optionData)
      return
    }

    setSelectedCC([])
    setInternalControlSelectedCC([])
  }

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Stack
      height="calc(100vh - 48px)"
      width="400px"
      right="0px"
      top="50%"
      position="relative"
      direction="column"
      alignItems="center"
      sx={{ zIndex: 1000 }}
    >
      <Stack
        height="90%"
        width="100%"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="32px"
        padding="24px"
      >
        <Stack
          display="grid"
          width="100%"
          height="100%"
          gridAutoRows="max-content"
          gap="16px"
          paddingTop="12px"
          sx={{ overflow: 'auto' }}
        >
          <Stack
            gap="24px"
            paddingLeft="2px"
            width="calc(100% - 12px)"
            height="100%"
          >
            <Stack gap="24px">
              <Typography color="#000" fontSize="14px" fontWeight={600}>
                Descrição
              </Typography>
              <Stack direction="row" gap="24px">
                <BaseInput
                  fullWidth
                  labelBackground="#F4F8FA"
                  type="text"
                  label="Busca por descrição"
                  setState={(event) => {
                    if (!event) {
                      setInternalControlSearchQuery('')
                      setSearchQuery('')
                    }
                    setInternalControlSearchQuery(event)
                    setSearchQuery(event)
                  }}
                  value={internalControlSearchQuery ?? ''}
                  error={null}
                  iconStart={<SearchIcon />}
                />
              </Stack>
            </Stack>

            <Stack gap="24px">
              <Typography
                color="#000"
                fontSize="14px"
                width="100%"
                fontWeight={600}
              >
                Período
              </Typography>
              <Stack direction="row" gap="24px">
                <BaseDateSelect
                  title="De"
                  onSelect={(e) => {
                    setInitialDate(e)
                    setInternalControlInitialDate(e)
                  }}
                  buttonStyle={{ width: '160px' }}
                  containerStyle={{ width: '160px' }}
                  selectedDate={internalControlInitialDate}
                  useCalendarPosition={false}
                />

                <BaseDateSelect
                  title="Até"
                  onSelect={(e) => {
                    setFinalDate(e)
                    setInternalControlFinalDate(e)
                  }}
                  buttonStyle={{ width: '160px' }}
                  containerStyle={{ width: '160px' }}
                  selectedDate={internalControlFinalDate}
                  useCalendarPosition={false}
                />
              </Stack>
            </Stack>

            <Stack gap="24px">
              <Typography color="#000" fontSize="14px" fontWeight={600}>
                Valores
              </Typography>
              <Stack direction="row" gap="24px">
                <BaseInputMoney
                  label="Mínimo"
                  fullWidth
                  type="text"
                  setState={(e) => {
                    setMinValue(e)
                    setInternalControlMinValue(e)
                  }}
                  error=""
                  value={internalControlMinValue as string}
                  containerStyle={{ width: '160px' }}
                />

                <BaseInputMoney
                  label="Máximo"
                  type="number"
                  fullWidth
                  setState={(e) => {
                    setMaxValue(e)
                    setInternalControlMaxValue(e)
                  }}
                  error=""
                  value={internalControlMaxValue as string}
                  containerStyle={{ width: '160px' }}
                />
              </Stack>
            </Stack>

            <Accordion
              sx={{ height: 'auto', maxHeight: '50vh', overflow: 'auto' }}
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Clientes / Fornecedores
              </AccordionSummary>
              <AccordionDetails>
                <Box width="100%" height="100%">
                  <Stack
                    width="100%"
                    height="100%"
                    display="grid"
                    direction="row"
                    gridAutoRows="max-content"
                    gridAutoColumns="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    gap="16px"
                  >
                    <Box paddingBottom="32px">
                      <BaseInput
                        fullWidth
                        labelBackground="#F4F8FA"
                        type="text"
                        label="Buscar Clientes / Fornecedores"
                        setState={(event) => {
                          if (!event) {
                            setInternalControlSearchClients('')
                          }
                          setInternalControlSearchClients(event)
                        }}
                        value={internalControlSearchClients ?? ''}
                        error={null}
                        iconStart={<SearchIcon />}
                      />

                      <Box marginTop="20px">
                        <Stack
                          direction="row"
                          alignItems="center"
                          key="selectedOptions-select-all"
                          marginBottom="10px"
                        >
                          <Checkbox
                            sx={{
                              color: '#4865CC',
                              '&.Mui-checked': {
                                color: '#4865CC',
                              },
                            }}
                            onClick={() => handleSelectAllClients()}
                            checked={
                              internalControlSelectedClients?.length ===
                              clientOptions.length
                            }
                          />
                          <ListItemText
                            primary="Selecionar todos"
                            sx={{ marginLeft: '5px' }}
                          />
                        </Stack>
                        {clientOptions &&
                          clientOptions
                            ?.filter((f) =>
                              internalControlSearchClients
                                ? f?.value
                                    ?.toLowerCase()
                                    .includes(internalControlSearchClients)
                                : !!f,
                            )
                            .map((item) => (
                              <Stack
                                direction="row"
                                alignItems="center"
                                key={item.label}
                                marginBottom="10px"
                              >
                                <Checkbox
                                  sx={{
                                    color: '#4865CC',
                                    '&.Mui-checked': {
                                      color: '#4865CC',
                                    },
                                  }}
                                  onClick={() => {
                                    setInternalControlSelectedClients(
                                      (prevOptions) => {
                                        const options = prevOptions || []

                                        if (
                                          options.includes(
                                            item.value.toString(),
                                          )
                                        ) {
                                          return options.filter(
                                            (option) =>
                                              option !== item.value.toString(),
                                          )
                                        } else {
                                          return [
                                            ...options,
                                            item.value.toString(),
                                          ]
                                        }
                                      },
                                    )
                                    setSelectedClients &&
                                      setSelectedClients((prevOptions) => {
                                        const options = prevOptions || []
                                        if (
                                          options.includes(
                                            item.value.toString(),
                                          )
                                        ) {
                                          return options.filter(
                                            (option) =>
                                              option !== item.value.toString(),
                                          )
                                        } else {
                                          return [
                                            ...options,
                                            item.value.toString(),
                                          ]
                                        }
                                      })
                                  }}
                                  checked={
                                    internalControlSelectedClients?.includes(
                                      item.value.toString(),
                                    ) ?? false
                                  }
                                />
                                <ListItemText
                                  primary={item.label}
                                  sx={{ marginLeft: '5px' }}
                                />
                              </Stack>
                            ))}
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{ height: 'auto', maxHeight: '50vh', overflow: 'auto' }}
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1b-content"
                id="panelba-header"
              >
                Débito
              </AccordionSummary>
              <AccordionDetails>
                <Box width="100%" height="100%">
                  <Stack
                    width="100%"
                    height="100%"
                    display="grid"
                    direction="row"
                    gridAutoRows="max-content"
                    gridAutoColumns="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    gap="16px"
                  >
                    <Box paddingBottom="32px">
                      <BaseInput
                        fullWidth
                        key="searchDebit"
                        labelBackground="#F4F8FA"
                        type="text"
                        label="Buscar Conta Débito"
                        setState={(event) => {
                          if (!event) {
                            setInternalControlSearchDebits('')
                          }
                          setInternalControlSearchDebits(event)
                        }}
                        value={internalControlSearchDebits ?? ''}
                        error={null}
                        iconStart={<SearchIcon />}
                      />

                      <Box marginTop="20px">
                        <Stack
                          direction="row"
                          alignItems="center"
                          key="selectedOptions-select-all"
                          marginBottom="10px"
                        >
                          <Checkbox
                            sx={{
                              color: '#4865CC',
                              '&.Mui-checked': {
                                color: '#4865CC',
                              },
                            }}
                            onClick={() => handleSelectAllDebits()}
                            checked={
                              internalControlSelectedDebits?.length ===
                              debitOptions.length
                            }
                          />
                          <ListItemText
                            primary="Selecionar todos"
                            sx={{ marginLeft: '5px' }}
                          />
                        </Stack>
                        {debitOptions &&
                          debitOptions
                            ?.filter((f) =>
                              internalControlSearchDebits
                                ? f?.value
                                    ?.toLowerCase()
                                    .includes(internalControlSearchDebits)
                                : !!f,
                            )
                            .map((item) => (
                              <Stack
                                direction="row"
                                alignItems="center"
                                key={item.label}
                                marginBottom="10px"
                              >
                                <Checkbox
                                  sx={{
                                    color: '#4865CC',
                                    '&.Mui-checked': {
                                      color: '#4865CC',
                                    },
                                  }}
                                  onClick={() => {
                                    setInternalControlSelectedDebits(
                                      (prevOptions) => {
                                        const options = prevOptions || []

                                        if (
                                          options.includes(
                                            item.value.toString(),
                                          )
                                        ) {
                                          return options.filter(
                                            (option) =>
                                              option !== item.value.toString(),
                                          )
                                        } else {
                                          return [
                                            ...options,
                                            item.value.toString(),
                                          ]
                                        }
                                      },
                                    )
                                    setSelectedDebits &&
                                      setSelectedDebits((prevOptions) => {
                                        const options = prevOptions || []
                                        if (
                                          options.includes(
                                            item.value.toString(),
                                          )
                                        ) {
                                          return options.filter(
                                            (option) =>
                                              option !== item.value.toString(),
                                          )
                                        } else {
                                          return [
                                            ...options,
                                            item.value.toString(),
                                          ]
                                        }
                                      })
                                  }}
                                  checked={
                                    internalControlSelectedDebits?.includes(
                                      item.value.toString(),
                                    ) ?? false
                                  }
                                />
                                <ListItemText
                                  primary={item.label}
                                  sx={{ marginLeft: '5px' }}
                                />
                              </Stack>
                            ))}
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{ height: 'auto', maxHeight: '50vh', overflow: 'auto' }}
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panelca-header"
              >
                Crédito
              </AccordionSummary>
              <AccordionDetails>
                <Box width="100%" height="100%">
                  <Stack
                    width="100%"
                    height="100%"
                    display="grid"
                    direction="row"
                    gridAutoRows="max-content"
                    gridAutoColumns="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    gap="16px"
                  >
                    <Box paddingBottom="32px">
                      <BaseInput
                        fullWidth
                        key="searchCredit"
                        labelBackground="#F4F8FA"
                        type="text"
                        label="Buscar Conta Crédito"
                        setState={(event) => {
                          if (!event) {
                            setInternalControlSearchCredits('')
                          }
                          setInternalControlSearchCredits(event)
                        }}
                        value={internalControlSearchCredits ?? ''}
                        error={null}
                        iconStart={<SearchIcon />}
                      />

                      <Box marginTop="20px">
                        <Stack
                          direction="row"
                          alignItems="center"
                          key="selectedOptions-select-all"
                          marginBottom="10px"
                        >
                          <Checkbox
                            sx={{
                              color: '#4865CC',
                              '&.Mui-checked': {
                                color: '#4865CC',
                              },
                            }}
                            onClick={() => handleSelectAllCredits()}
                            checked={
                              internalControlSelectedCredits?.length ===
                              creditOptions.length
                            }
                          />
                          <ListItemText
                            primary="Selecionar todos"
                            sx={{ marginLeft: '5px' }}
                          />
                        </Stack>
                        {creditOptions &&
                          creditOptions
                            ?.filter((f) =>
                              internalControlSearchCredits
                                ? f?.value
                                    ?.toLowerCase()
                                    .includes(internalControlSearchCredits)
                                : !!f,
                            )
                            .map((item) => (
                              <Stack
                                direction="row"
                                alignItems="center"
                                key={item.label}
                                marginBottom="10px"
                              >
                                <Checkbox
                                  sx={{
                                    color: '#4865CC',
                                    '&.Mui-checked': {
                                      color: '#4865CC',
                                    },
                                  }}
                                  onClick={() => {
                                    setInternalControlSelectedCredits(
                                      (prevOptions) => {
                                        const options = prevOptions || []

                                        if (
                                          options.includes(
                                            item.value.toString(),
                                          )
                                        ) {
                                          return options.filter(
                                            (option) =>
                                              option !== item.value.toString(),
                                          )
                                        } else {
                                          return [
                                            ...options,
                                            item.value.toString(),
                                          ]
                                        }
                                      },
                                    )
                                    setSelectedCredits &&
                                      setSelectedCredits((prevOptions) => {
                                        const options = prevOptions || []
                                        if (
                                          options.includes(
                                            item.value.toString(),
                                          )
                                        ) {
                                          return options.filter(
                                            (option) =>
                                              option !== item.value.toString(),
                                          )
                                        } else {
                                          return [
                                            ...options,
                                            item.value.toString(),
                                          ]
                                        }
                                      })
                                  }}
                                  checked={
                                    internalControlSelectedCredits?.includes(
                                      item.value.toString(),
                                    ) ?? false
                                  }
                                />
                                <ListItemText
                                  primary={item.label}
                                  sx={{ marginLeft: '5px' }}
                                />
                              </Stack>
                            ))}
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{ height: 'auto', maxHeight: '50vh', overflow: 'auto' }}
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panelca-header"
              >
                Conta contábil
              </AccordionSummary>
              <AccordionDetails>
                <Box width="100%" height="100%">
                  <Stack
                    width="100%"
                    height="100%"
                    display="grid"
                    direction="row"
                    gridAutoRows="max-content"
                    gridAutoColumns="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    gap="16px"
                  >
                    <Box paddingBottom="32px">
                      <BaseInput
                        fullWidth
                        key="searchCC"
                        labelBackground="#F4F8FA"
                        type="text"
                        label="Buscar Conta Contábil"
                        setState={(event) => {
                          if (!event) {
                            setInternalControlSearchCC('')
                          }
                          setInternalControlSearchCC(event)
                        }}
                        value={internalControlSearchCC ?? ''}
                        error={null}
                        iconStart={<SearchIcon />}
                      />

                      <Box marginTop="20px">
                        <Stack
                          direction="row"
                          alignItems="center"
                          key="selectedOptions-select-all"
                          marginBottom="10px"
                        >
                          <Checkbox
                            sx={{
                              color: '#4865CC',
                              '&.Mui-checked': {
                                color: '#4865CC',
                              },
                            }}
                            onClick={() => handleSelectAllCC()}
                            checked={
                              internalControlSelectedCC?.length ===
                              accountingAccountOptions.length
                            }
                          />
                          <ListItemText
                            primary="Selecionar todos"
                            sx={{ marginLeft: '5px' }}
                          />
                        </Stack>
                        {accountingAccountOptions &&
                          accountingAccountOptions
                            ?.filter((f) =>
                              internalControlSearchCC
                                ? f?.value
                                    ?.toLowerCase()
                                    .includes(internalControlSearchCC)
                                : !!f,
                            )
                            .map((item) => (
                              <Stack
                                direction="row"
                                alignItems="center"
                                key={item.label}
                                marginBottom="10px"
                              >
                                <Checkbox
                                  sx={{
                                    color: '#4865CC',
                                    '&.Mui-checked': {
                                      color: '#4865CC',
                                    },
                                  }}
                                  onClick={() => {
                                    setInternalControlSelectedCC(
                                      (prevOptions) => {
                                        const options = prevOptions || []

                                        if (
                                          options.includes(
                                            item.value.toString(),
                                          )
                                        ) {
                                          return options.filter(
                                            (option) =>
                                              option !== item.value.toString(),
                                          )
                                        } else {
                                          return [
                                            ...options,
                                            item.value.toString(),
                                          ]
                                        }
                                      },
                                    )
                                    setSelectedCC &&
                                      setSelectedCC((prevOptions) => {
                                        const options = prevOptions || []
                                        if (
                                          options.includes(
                                            item.value.toString(),
                                          )
                                        ) {
                                          return options.filter(
                                            (option) =>
                                              option !== item.value.toString(),
                                          )
                                        } else {
                                          return [
                                            ...options,
                                            item.value.toString(),
                                          ]
                                        }
                                      })
                                  }}
                                  checked={
                                    internalControlSelectedCC?.includes(
                                      item.value.toString(),
                                    ) ?? false
                                  }
                                />
                                <ListItemText
                                  primary={item.label}
                                  sx={{ marginLeft: '5px' }}
                                />
                              </Stack>
                            ))}
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>

        <Stack
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          direction="row"
          gap="16px"
        >
          <Button variant="text" onClick={() => HideModal()}>
            Cancelar
          </Button>

          <Button variant="text" onClick={() => handleClear()}>
            Limpar
          </Button>

          <Button
            variant="contained"
            onClick={() => handleExecute()}
            sx={{ width: '140px' }}
          >
            Aplicar filtro
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
