import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'
import { ImportExtractSuccessModal } from 'src/component/modal/modal-import-extract-success'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { ColumnsCommon, Table } from 'src/component/table/table'
import { AccountCSVImportTransactionUseCase } from 'src/usecase/bank-account/usecase-account-import-csv-transactions'
import { AccountPreviewCSVImportTransactionUseCase } from 'src/usecase/bank-account/usecase-account-preview-csv-import-transaction'

const defaultColumns = [
  'externalId',
  'amount',
  'date',
  'partieDocument',
  'partieName',
  'partieDescription',
]

const defaultColumnsTranslation = [
  'ID',
  'Valor',
  'Data',
  'CNPJ',
  'Fornecedor',
  'Descrição',
]

interface CreateAccountPageProps {
  previewDataUseCase: AccountPreviewCSVImportTransactionUseCase
  importTransactionsUseCase: AccountCSVImportTransactionUseCase
}

interface LocationState {
  state: {
    fileColumns: string[]
    file: File
  }
}

export function ImportCSVTransactionSystemicAccountPage(
  props: CreateAccountPageProps,
): any {
  const location = useLocation() as LocationState
  const { fileColumns, file } = location.state
  const isSmallScreen = useMediaQuery('(max-width: 1280px)')

  const { companyId, accountId } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const { ShowToast } = ToastFullContext()
  const { ShowModal } = ModalFullContext()
  const [selectedColumn, setSelectedColumn] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<unknown[]>([])
  const [preview, setPreview] = useState<{ [key: string]: string }[]>([])
  const [isAllRequiredRowsChecked, setIsAllrRequiredRowsChecked] =
    useState<boolean>(false)

  const handleCancel = () => {
    navigate(`/company/${companyId}/account/list`)
  }

  const validateIfRequiredColumn = (columnToValidate: string) => {
    if (
      [
        'amount',
        'valor',
        'date',
        'data',
        'partiedescription',
        'descrição',
      ].includes(columnToValidate.toLowerCase())
    ) {
      return true
    }

    return false
  }

  const columns: GridColDef[] = defaultColumns.map((column, index) => {
    let flex = 1
    // Definindo o flex com base no tipo de coluna
    if (column === 'externalId') {
      flex = 0.3
    } else if (column === 'date' || column === 'amount') {
      flex = 0.5
    }
    return {
      ...ColumnsCommon,
      flex,
      ...{
        field: column,
        headerName: defaultColumnsTranslation[index],
      },
      renderCell: (params) =>
        params.row[column as keyof typeof params.row]
          ? `${params.row[column as keyof typeof params.row]}`
          : 'Dado não relacionado',
    }
  })

  const handleColumnRelation = (openiColumn: string, csvColumn: string) => {
    let validOpeniColumn = ''
    if (openiColumn === 'ID') validOpeniColumn = 'externalId'
    if (openiColumn === 'Valor') validOpeniColumn = 'amount'
    if (openiColumn === 'Tipo') validOpeniColumn = 'type'
    if (openiColumn === 'Data') validOpeniColumn = 'date'
    if (openiColumn === 'CNPJ') validOpeniColumn = 'partieDocument'
    if (openiColumn === 'Fornecedor') validOpeniColumn = 'partieName'
    if (openiColumn === 'Descrição') validOpeniColumn = 'partieDescription'

    if (validOpeniColumn === '') return

    // Atualizando o estado com o novo item ou substituindo o existente
    setPreview((prevPreview) => {
      // Verifica se a coluna openiColumn já existe no estado
      const existingIndex = prevPreview.findIndex((item) =>
        Object.prototype.hasOwnProperty.call(item, validOpeniColumn),
      )

      // Se existir, substitui o valor
      if (existingIndex !== -1) {
        const newPreview = [...prevPreview]
        newPreview[existingIndex][validOpeniColumn] = csvColumn
        return newPreview
      }

      // Se não existir, adiciona um novo item
      return [...prevPreview, { [validOpeniColumn]: csvColumn }]
    })

    setSelectedColumn('')
  }

  const handleUpdatePreview = async () => {
    try {
      setIsLoading(true)
      const previewResult = await props.previewDataUseCase.handle({
        file,
        companyId: companyId as string,
        accountId: accountId as string,
        matchingColumns: preview,
      })
      setIsLoading(false)

      if (previewResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar o preview da importação. Tente novamente mais tarde.',
        )
      }

      const previewData = previewResult.getValue()
      if (previewData) {
        const preViewData = previewData.preview.map((item, index) => {
          return {
            ...item,
            id: index,
          }
        })
        setRows(preViewData)

        const requiredKeys = ['amount', 'date', 'partieDescription']
        let response = true
        await Promise.all(
          requiredKeys.map((key) => {
            const existingIndex = preViewData.findIndex((item) =>
              Object.prototype.hasOwnProperty.call(item, key),
            )

            if (existingIndex === -1) {
              response = false
            }

            return key
          }),
        )

        if (preViewData.length === 0) {
          response = false
        }

        setIsAllrRequiredRowsChecked(response)
      }
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o preview da importação. Tente novamente mais tarde.',
      )
    }
  }

  useEffect(() => {
    if (!preview || preview.length === 0) return

    handleUpdatePreview()
  }, [preview])

  const handleCreateTransactions = async () => {
    setIsLoading(true)
    const transactionsResult = await props.importTransactionsUseCase.handle({
      file,
      companyId: companyId as string,
      accountId: accountId as string,
      matchingColumns: preview,
    })

    setIsLoading(false)

    if (transactionsResult.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a importação das transações. Tente novamente mais tarde.',
      )
    }

    const transactions = transactionsResult.getValue()

    console.log(transactions)

    if (transactions) {
      let amount = 0
      transactions.successfulTransactions.map((item) => {
        const convertAmount = parseFloat(item.amount.replace(/[^\d.]/g, ''))

        if (item.method === 'DEBITO') {
          return (amount -= convertAmount)
        }

        return (amount += convertAmount)
      })

      ShowModal({
        content: (
          <ImportExtractSuccessModal
            finalBalance={amount.toString()}
            totalFailed={transactions.failedTransactions.length}
            totalImported={transactions.successfulTransactions.length}
            totalRegister={0}
          />
        ),
        title: '',
      })

      navigate(`/company/${companyId}/account/list`)
    }
  }

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        breadcumbItems={[
          { title: 'Home', navigateTo: `/company/${companyId}` },
          {
            title: 'Contas sistêmicas',
            navigateTo: `/company/${companyId}/account/list`,
          },
          { title: 'Importar extrato' },
          { title: 'Formato Excel' },
        ]}
      />

      <Stack
        width="calc(100% - 48px)"
        maxWidth="1144px"
        height="calc(100% - 130px)"
        direction="column"
        gap="32px"
        overflow="auto"
        justifyContent="space-between"
        sx={{ overflow: 'hidden' }}
      >
        {/* Content */}
        <Stack
          width="calc(100% - 24px)"
          height="100%"
          justifyContent="flex-start"
          alignItems="flex-start"
          direction="column"
          gap="24px"
          padding="24px"
          paddingBottom={0}
          sx={{ overflow: 'hidden' }}
        >
          <BasePageTitle
            text="Importar Planilha Excel"
            subTitle="Relacione as informações da planilha que você importou com a do Sistema Openi"
            icon={<FileUploadOutlinedIcon />}
          />

          {/* Columns relation */}
          <Stack
            width="100%"
            height="100%"
            direction="row"
            justifyContent="space-between"
            gap="32px"
            sx={{ overflowY: 'hidden' }}
          >
            <Stack
              borderRadius="16px"
              height="100%"
              maxHeight="480px"
              width="50%"
              sx={{ backgroundColor: 'white', overflowY: 'hidden' }}
            >
              <Typography
                fontSize="16px"
                fontWeight={700}
                color={theme.palette.primary.main}
                padding="24px"
              >
                Planilha importada
              </Typography>

              <Stack width="100%" sx={{ overflow: 'auto' }}>
                {fileColumns
                  .filter((f) => f?.toString()?.trim() !== '')
                  .map((column, index) => {
                    return (
                      <Stack
                        sx={{
                          cursor: 'pointer',
                          backgroundColor:
                            selectedColumn === column
                              ? '#F4F8FA'
                              : 'transparent',
                          '&:hover': {
                            backgroundColor: '#F4F8FA',
                          },
                          borderTop:
                            index > 0
                              ? '1px solid #FCD24C'
                              : '1px solid transparent',
                        }}
                        key={`${column}-${index}`}
                        width="calc(100% - 36px)"
                        height="56px"
                        minHeight="56px"
                        direction="row"
                        paddingLeft="18px"
                        paddingRight="18px"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => setSelectedColumn(column)}
                        color=""
                      >
                        <Typography fontSize="14px" fontWeight={400}>
                          {column}
                        </Typography>
                        <ChevronRightOutlinedIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                      </Stack>
                    )
                  })}
              </Stack>
            </Stack>

            <Stack
              borderRadius="16px"
              height="100%"
              maxHeight="480px"
              width="50%"
              sx={{ backgroundColor: 'white', overflowY: 'hidden' }}
            >
              <Typography
                fontSize="16px"
                fontWeight={700}
                color={theme.palette.primary.main}
                padding="24px"
              >
                Sistema Openi
              </Typography>

              <Stack width="100%" sx={{ overflow: 'auto' }}>
                {defaultColumnsTranslation.map((defaultColumn, index) => {
                  return (
                    <Stack
                      sx={{
                        cursor:
                          !selectedColumn || selectedColumn === ''
                            ? 'no-drop'
                            : 'pointer',
                        '&:hover': {
                          backgroundColor: '#F4F8FA',
                        },
                        borderTop:
                          index > 0
                            ? '1px solid #FCD24C'
                            : '1px solid transparent',
                      }}
                      key={`${defaultColumn}-${index}`}
                      width="calc(100% - 36px)"
                      height="56px"
                      minHeight="56px"
                      paddingLeft="18px"
                      paddingRight="18px"
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() =>
                        !selectedColumn || selectedColumn === ''
                          ? null
                          : handleColumnRelation(defaultColumn, selectedColumn)
                      }
                      color=""
                    >
                      <ChevronRightOutlinedIcon
                        sx={{ color: theme.palette.primary.main }}
                      />
                      <Stack
                        direction="row"
                        gap="2px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography fontSize="14px" fontWeight={400}>
                          {defaultColumn}
                        </Typography>
                        {validateIfRequiredColumn(defaultColumn) && (
                          <span
                            style={{
                              color: theme.palette.common.black,
                              fontWeight: '600',
                            }}
                          >
                            *
                          </span>
                        )}
                      </Stack>
                    </Stack>
                  )
                })}
              </Stack>
            </Stack>
          </Stack>

          <Stack width="100%" height={'100%'} maxHeight="280px">
            {rows && rows.length > 0 && !isSmallScreen && (
              <Typography fontSize="24px" fontWeight="700" color="#1E3466">
                Confirmar dados
              </Typography>
            )}

            {isLoading && <CircularProgress />}
            {!isLoading && rows && rows.length > 0 && (
              <Table
                loadMore={() => console.log('press me')}
                totalPages={1}
                currentPage={1}
                columns={columns}
                rows={rows}
                totalRegisters={rows?.length}
                allowSelectAll={false}
                loading={false}
                showFilterButton={false}
                showSearchButton={false}
                checkboxSelection={false}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      {/* Footer */}
      <Stack
        width="calc(100% - 48px)"
        maxWidth="1144px"
        justifyContent="flex-end"
        alignItems="center"
        direction="row"
        gap="24px"
        padding="24px"
      >
        <Button
          fullWidth={false}
          variant="text"
          onClick={handleCancel}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '110px',
            height: '40px',
          }}
        >
          Cancelar
        </Button>
        <Button
          fullWidth={false}
          variant="contained"
          onClick={handleCreateTransactions}
          disabled={
            !preview || preview.length === 0 || !isAllRequiredRowsChecked
          }
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
            '&:disabled': {
              borderColor: 'transparent',
            },
          }}
        >
          Continuar
        </Button>
      </Stack>
    </Stack>
  )
}
