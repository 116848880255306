import React from 'react'
import { Tooltip, IconButton, useTheme } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface CustomTooltipProps {
  message: string
  backgroundColor?: string
}

export const BaseTooltip: React.FC<CustomTooltipProps> = ({
  message,
  backgroundColor = '#777C84',
}) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={message}
      arrow
      sx={{
        '& .MuiTooltip-tooltip': {
          backgroundColor,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '19.07px',
          color: 'white',
        },
      }}
    >
      <IconButton>
        <InfoOutlinedIcon sx={{ color: theme.palette.primary.main }} />
      </IconButton>
    </Tooltip>
  )
}
