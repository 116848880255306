import {
  Box,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { ExtractIcon } from 'src/component/icons/extract'
import { MovimentationInIcon } from 'src/component/icons/movimentation-in'
import { MovimentationOutIcon } from 'src/component/icons/movimentation-out'
import { BigNumbersWidget } from 'src/component/widgets/big-numbers'
import {
  BankAccountTransferMethodEnum,
  IBankAccountTransfer,
} from 'src/service/service-bank'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export interface IBankAccountTransferNormalized extends IBankAccountTransfer {
  amountFormatted: string
}
export interface ExtractProps {
  all: IBankAccountTransferNormalized[]
  income: IBankAccountTransferNormalized[]
  outcome: IBankAccountTransferNormalized[]
}

interface Props {
  data: ExtractProps
  balance: number
}

const Extract: FC<Props> = ({ data, balance }) => {
  const [value, setValue] = useState(0)
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <Stack
      gap="24px"
      bgcolor="#fff"
      borderRadius="16px"
      padding="24px"
      height="604px"
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="flex-start">
          <ExtractIcon />

          <Stack ml={2} mt={-1}>
            <Typography
              fontWeight={700}
              fontSize="24px"
              lineHeight="32.69px"
              color="#1E3466"
            >
              Extrato
            </Typography>
            <Typography
              fontWeight={400}
              fontSize="14px"
              lineHeight="19.07px"
              color="#777C84"
            >
              Das contas selecionadas
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="space-between" mt="24px">
        <BigNumbersWidget
          value={formatMoney(balance)}
          valueCustomStyle={{ fontSize: '32px' }}
          valueColor="#03B575"
        />
      </Stack>

      <Stack mt={3} sx={{ overflowY: 'scroll', maxHeight: '450px' }}>
        <Tabs value={value} onChange={handleChange} aria-label="extract tabs">
          <Tab
            label={
              <span
                style={{
                  color: value === 0 ? '#4865CC' : '#777C84', // Define a cor do texto dentro do span com base na Tab ativa
                }}
              >
                Todas
              </span>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <span
                style={{
                  color: value === 1 ? '#4865CC' : '#777C84', // Define a cor do texto dentro do span com base na Tab ativa
                }}
              >
                Entrada
              </span>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <span
                style={{
                  color: value === 2 ? '#4865CC' : '#777C84', // Define a cor do texto dentro do span com base na Tab ativa
                }}
              >
                Saída
              </span>
            }
            {...a11yProps(2)}
          />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={isSmallScreen ? 1 : 2}>
            {data?.all?.map((movimentation) => (
              <Grid
                item
                md={12}
                borderBottom="solid 1px #CDCDCD"
                pb={1}
                key={Math.random()}
              >
                <Grid container spacing={1}>
                  <Grid item md={isSmallScreen ? 6 : 2}>
                    <Stack width="100%">
                      <Typography
                        fontWeight={600}
                        color="#0B1116"
                        fontSize={isSmallScreen ? '10px' : '14px'}
                        lineHeight="19.07px"
                      >
                        {movimentation.date}
                      </Typography>
                      {isSmallScreen && (
                        <Grid item md={4}>
                          <Typography
                            fontWeight={400}
                            color="#0B1116"
                            fontSize={isSmallScreen ? '12px' : '14px'}
                            lineHeight="19.07px"
                          >
                            {movimentation.description ??
                              movimentation.transferCompleteDescription}
                          </Typography>
                        </Grid>
                      )}
                    </Stack>
                  </Grid>
                  {!isSmallScreen && (
                    <Grid item md={4}>
                      <Typography
                        fontWeight={400}
                        color="#0B1116"
                        fontSize={isSmallScreen ? '12px' : '14px'}
                        lineHeight="19.07px"
                      >
                        {movimentation.description ??
                          movimentation.transferCompleteDescription}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item md={1}>
                    {movimentation.method ===
                    BankAccountTransferMethodEnum.CREDITO ? (
                      <MovimentationInIcon />
                    ) : (
                      <MovimentationOutIcon />
                    )}
                  </Grid>
                  <Grid item md={5}>
                    <Stack alignItems="flex-end">
                      <Typography fontSize={isSmallScreen ? '12px' : '14px'}>
                        {movimentation.amountFormatted}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid container spacing={isSmallScreen ? 1 : 2}>
            {data?.income?.map((movimentation) => (
              <Grid
                item
                md={12}
                borderBottom="solid 1px #CDCDCD"
                pb={1}
                key={Math.random()}
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item md={isSmallScreen ? 6 : 2}>
                    <Stack width="100%">
                      <Typography
                        fontWeight={600}
                        color="#0B1116"
                        fontSize={isSmallScreen ? '10px' : '14px'}
                        lineHeight="19.07px"
                      >
                        {movimentation.date}
                      </Typography>
                      {isSmallScreen && (
                        <Grid item md={4}>
                          <Typography
                            fontWeight={400}
                            color="#0B1116"
                            fontSize={isSmallScreen ? '12px' : '14px'}
                            lineHeight="19.07px"
                          >
                            {movimentation.description ??
                              movimentation.transferCompleteDescription}
                          </Typography>
                        </Grid>
                      )}
                    </Stack>
                  </Grid>
                  {!isSmallScreen && (
                    <Grid item md={4}>
                      <Typography
                        fontWeight={400}
                        color="#0B1116"
                        fontSize={isSmallScreen ? '12px' : '14px'}
                        lineHeight="19.07px"
                      >
                        {movimentation.description ??
                          movimentation.transferCompleteDescription}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item md={1}>
                    {movimentation.method ===
                    BankAccountTransferMethodEnum.CREDITO ? (
                      <MovimentationInIcon />
                    ) : (
                      <MovimentationOutIcon />
                    )}
                  </Grid>
                  <Grid item md={5}>
                    <Stack alignItems="flex-end">
                      <Typography>{movimentation.amountFormatted}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Grid container spacing={2}>
            {data?.outcome?.map((movimentation) => (
              <Grid
                item
                md={12}
                borderBottom="solid 1px #CDCDCD"
                pb={1}
                key={Math.random()}
              >
                <Grid container spacing={1}>
                  <Grid item md={isSmallScreen ? 6 : 2}>
                    <Stack width="100%">
                      <Typography
                        fontWeight={600}
                        color="#0B1116"
                        fontSize={isSmallScreen ? '10px' : '14px'}
                        lineHeight="19.07px"
                      >
                        {movimentation.date}
                      </Typography>
                      {isSmallScreen && (
                        <Grid item md={4}>
                          <Typography
                            fontWeight={400}
                            color="#0B1116"
                            fontSize={isSmallScreen ? '12px' : '14px'}
                            lineHeight="19.07px"
                          >
                            {movimentation.description ??
                              movimentation.transferCompleteDescription}
                          </Typography>
                        </Grid>
                      )}
                    </Stack>
                  </Grid>
                  {!isSmallScreen && (
                    <Grid item md={4}>
                      <Typography
                        fontWeight={400}
                        color="#0B1116"
                        fontSize={isSmallScreen ? '12px' : '14px'}
                        lineHeight="19.07px"
                      >
                        {movimentation.description ??
                          movimentation.transferCompleteDescription}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item md={1}>
                    {movimentation.method ===
                    BankAccountTransferMethodEnum.CREDITO ? (
                      <MovimentationInIcon />
                    ) : (
                      <MovimentationOutIcon />
                    )}
                  </Grid>
                  <Grid item md={5}>
                    <Stack alignItems="flex-end">
                      <Typography>{movimentation.amountFormatted}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>
      </Stack>
    </Stack>
  )
}

export { Extract }
