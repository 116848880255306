import { NumericFormat } from 'react-number-format'
import React, { useEffect, useState } from 'react'
import {
  BaseInput,
  BaseInputProps,
} from 'src/component/base-component/base-input'

export const BaseInputMoney = React.forwardRef(
  (props: BaseInputProps & { prefix?: string }) => {
    const [_value, _setValue] = useState<string | number>(props.value ?? NaN)
    const [updateCount, setUpdateCount] = useState(0) // Limita o update do value injetado

    useEffect(() => {
      if (
        props.value !== _value &&
        Number.isNaN(_value) &&
        props.value &&
        typeof props.value === 'string'
      ) {
        _setValue(props.value)
      } else if (props.value === '' && _value === '') {
        _setValue(NaN)
      }
    }, [props.value])

    useEffect(() => {
      if (
        props.value !== undefined &&
        props.value !== _value &&
        updateCount < 1
      ) {
        _setValue(props.value ?? '')
        setUpdateCount(updateCount + 1)
      }
    }, [props.value, updateCount])

    return (
      <NumericFormat
        customInput={BaseInput}
        {...props}
        type="text"
        decimalScale={2}
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={true}
        prefix={props.prefix ?? 'R$ '}
        value={_value}
        setState={(value: string) => {
          let newValue = value.replace(/[^0-9-]/g, '')

          const isNegative = newValue.includes('-')

          newValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number.parseInt(newValue, 10) / 100)

          if (isNegative && !newValue.startsWith('-')) {
            newValue = '-' + newValue
          }

          if (Number(newValue.replace(/[^0-9]/g, '')) === 0) {
            newValue = newValue.replace('-', '')
          }

          _setValue(newValue.replace(props.prefix ?? 'R$ ', '')?.trim())

          props?.setState?.(newValue.replace(props.prefix ?? 'R$ ', '')?.trim())
        }}
      />
    )
  },
)
