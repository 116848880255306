export function formatCnpj(cnpj: string): string {
  let value = (cnpj || '').replace(/\D/gi, '')
  value = value.replace(/(\d{2})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1/$2')
  value = value.replace(/(\d{4})(\d)/, '$1-$2')
  return value
}

export function formatCpf(cpf: string): string {
  let value = (cpf || '').replace(/\D/gi, '')
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1-$2')
  return value
}

export function formatCpfOrCnpj(value: string): string {
  // Remove quaisquer caracteres que não sejam números
  const numericValue = value.replace(/\D/g, '')

  if (numericValue.length === 11) {
    // Formatar como CPF (###.###.###-##)
    return numericValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  } else if (numericValue.length === 14) {
    // Formatar como CNPJ (##.###.###/####-##)
    return numericValue.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    )
  }

  // Retorna o valor original se não for um CPF ou CNPJ válido
  return value
}

export function formatMoney(amount: number) {
  if (isNaN(amount)) return 'R$ 0,00'

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)
}

export function getMonthName(monthNumber: number, year?: number, day?: number) {
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  if (monthNumber < 1 || monthNumber > 12) {
    return 'Número do mês inválido'
  }

  if (day) {
    return `${day} de ${monthNames[monthNumber - 1]}`
  }

  if (year) {
    return `${monthNames[monthNumber - 1]}/${year}`
  }

  return monthNames[monthNumber - 1]
}

export function parseToNumber(value: string): number {
  if (!value) return 0

  // Remove o separador de milhar e substitui a vírgula por ponto
  const cleanedValue = value.replace(/\./g, '').replace(',', '.')

  // Converte para número
  return parseFloat(cleanedValue) || 0
}

export function fixSpecialCharacters(text: string): string {
  if (!text) return ''

  // Normaliza o texto para remover caracteres diacríticos (acentos, cedilhas, etc.)
  const normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  // Substitui caracteres comuns mal codificados
  const correctedText = normalizedText
    .replace(/Ã§/g, 'ç')
    .replace(/Ã¡/g, 'á')
    .replace(/Ã©/g, 'é')
    .replace(/Ã³/g, 'ó')
    .replace(/Ãª/g, 'ê')
    .replace(/Ã£/g, 'ã')
    .replace(/Ã¢/g, 'â')
    .replace(/Ãº/g, 'ú')
    .replace(/Ã/g, 'Ç')

  return correctedText
}
